import React from 'react';
import './context_popup.scss';

const SIDE_MARGIN = 4;

class ContextPopup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};

    this.container = null;
    this.arrowElement = null;

    this.mainInterfaceFrame = null

    this.containerRef = (element) => {
      if (element == null) {
        this.container = null;
        this.arrowElement = null;
      }
      else {
        this.container = element;

        this.arrowElement = element.getElementsByClassName('context-popup__arrow')[0];
      }
    };
  }

  async componentDidMount() {
    this.mayUpdatePosition = true;
    requestAnimationFrame(this.updateContainerPosition.bind(this));

    this.mainInterfaceFrame = document.getElementsByClassName('admin-frame__main')[0]
  }

  componentWillUnmount() {
    this.mayUpdatePosition = false;
  }

  updateContainerPosition() {
    if (this.container !== null && this.props.targetElement !== null && this.mainInterfaceFrame !== null) {
      const containerRect = this.container.getBoundingClientRect();
      const rect = this.props.targetElement.getBoundingClientRect();
      const mainInterfaceFrame = this.mainInterfaceFrame.getBoundingClientRect();
      const arrowRect = this.arrowElement.getBoundingClientRect();

      let arrowBoxShadow;

      let xPosition = rect.x + (rect.width / 2);
      let arrowXPosition = xPosition;

      const relativeXPosition = xPosition - mainInterfaceFrame.x;

      let maxWidth;

      let yPosition = rect.y;
      let arrowYPosition;

      let directionXMultiplier; // 1 -> to right | -1 -> to left
      // let directionYMultiplier; // 1 -> to bottom | -1 -> to top

      if (relativeXPosition >= mainInterfaceFrame.width / 2) {
        directionXMultiplier = -1;
        maxWidth = (mainInterfaceFrame.width - relativeXPosition - SIDE_MARGIN) * 2;
      }
      else {
        directionXMultiplier = 1;
        maxWidth = (relativeXPosition - SIDE_MARGIN) * 2;
      }

      if (rect.y >= (containerRect.height + 60 + SIDE_MARGIN)) { // 60 aproximates header size
        yPosition -= containerRect.height + (0.6 * arrowRect.height);
        arrowYPosition = yPosition + containerRect.height;
        arrowBoxShadow = '1px 1px #6a6a6a';
      }
      else {
        yPosition += rect.height + (0.6 * arrowRect.height);
        arrowYPosition = yPosition;
        arrowBoxShadow = '-1px -1px #6a6a6a';
      }

      if (maxWidth < 280 && containerRect.width > maxWidth) {
        const newMaxWidth = Math.min(mainInterfaceFrame.width - (2 * SIDE_MARGIN), 280);

        // relativeXPosition = relativeXPosition + (directionXMultiplier * (newMaxWidth - maxWidth) / 2);
        xPosition = xPosition + (directionXMultiplier * (containerRect.width - maxWidth) / 2);
        maxWidth = newMaxWidth;

        if (directionXMultiplier > 0) {
          arrowXPosition = Math.max(arrowXPosition, mainInterfaceFrame.x + SIDE_MARGIN + (1.1 * arrowRect.width / 2));
        }
        else {
          arrowXPosition = Math.min(arrowXPosition, mainInterfaceFrame.width - SIDE_MARGIN - (1.1 * arrowRect.width / 2));
        }
      }

      this.container.style.maxWidth = `${maxWidth}px`;
      this.container.style.left = `${xPosition - (containerRect.width / 2)}px`;
      this.container.style.top = `${yPosition}px`;

      this.arrowElement.style.top = `${arrowYPosition}px`;
      this.arrowElement.style.boxShadow = arrowBoxShadow;
      this.arrowElement.style.left = `${arrowXPosition}px`;
    }

    if (this.mayUpdatePosition) {
      requestAnimationFrame(this.updateContainerPosition.bind(this));
    }
  }

  render() {
    if (this.props.targetElement === null || this.props.content === null) {
      return null;
    }

    return (
      <div
        className={`context-popup ${this.props.className || ''}`}
        ref={this.containerRef}
      >
        <div className="context-popup__arrow"></div>

        {this.props.content}
      </div>
    );
  }
}

export default ContextPopup;
