import React from 'react';
import { Link } from 'react-router-dom';
import DefaultSection, {HorizontalRule} from '../../utils/default_section';
import DefaultInput, {HalfWrapper, SelectOption} from '../../utils/default_input';
import {patchModel, deleteModel, getAsLocalDatetime} from '../../utils/functions';
import WarningMessage from '../warning_message';
import ConfirmationWindow from '../confirmation_window';
import OverlayWindow from '../../components/overlay_window';
import DefaultMenuButton from '../../components/default_menu_button';
import {VerticalAccordionContainer} from '../../utils/pose_containers';
import {EXPENSE_TYPE_NAME,
        INCOME_TYPE_NAME,
        BOTH_TYPE_NAME,
        DEFAULT_UNKNOWN_ERROR_MESSAGE} from '../../constants';
import * as routes from '../../constants';
import * as paths from '../student/constants';
import * as permissions from '../../permissions';
import './financial_movement_data.scss';

class FinancialMovementData extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      searchTagFilter: "",
      searchTagsSectionVisible: false,
      overlayIsLoading: false,
      deleteId: null,
      onCancelPaymentDeviceRequest: false,
      confirmInProgress: false,
      confirmFailed: false,
      confirmFailDescription: "",
    };
  }

  handleKeyDown(event) {
    if(!this.props.userPermissionIds.includes(permissions.ADD_FINANCIAL_ENTRIES_PERMISSION_ID)) {
      return;
    }

    if(event.keyCode === 13 && this.props.enableSave && event.target.name !== 'description') {
      this.props.onSave();
    }
  }

  handleInputChange(event) {
    const target = event.target;
    let value = target.type === 'checkbox' ? target.checked : target.value;
    let name = target.name;

    const update = {[name]: value};

    this.setState(update);
  }

  isHighlighted(propertyName) {
    return this.props.highlights.includes(propertyName);
  }

  getNodeOptions(node, filterCallback=null) {
    const options = [];

    if(!filterCallback || filterCallback(node)) {
      options.push(SelectOption(node.id, `${'•'.repeat(node.depth)} ${node.name}`));
    }

    node.children.sort((a, b) => a.name.localeCompare(b.name));

    for(let child of node.children) {
      if(!filterCallback || filterCallback(child)) {
        options.push(...this.getNodeOptions(child));
      }
    }

    return options;
  }

  getCostCenterOptions() {
    const roots = this.props.costCenters.filter((entry) => entry.depth === 0);

    roots.sort((a, b) => a.name.localeCompare(b.name));

    if(this.isEditMode()) {
      return [
        ...roots.flatMap((node) => this.getNodeOptions(node))
      ];
    }

    return [
      SelectOption('', 'Selecione um centro de custo'),
      ...roots.flatMap((node) => this.getNodeOptions(node))
    ];
  }

  getFinancialCategoryOptions() {
    let categories = this.props.financialCategories.filter((entry) => entry.depth === 0);

    categories.sort((a, b) => a.name.localeCompare(b.name));

    if(this.props.financial_movement.cost_center_id) {
      categories = categories.flatMap((node) => this.getNodeOptions(node, (category) => (
        (category.cost_center_id === null || parseInt(category.cost_center_id) === parseInt(this.props.financial_movement.cost_center_id)) &&
        (category.type === BOTH_TYPE_NAME || (this.props.isExpense ? category.type === EXPENSE_TYPE_NAME : category.type === INCOME_TYPE_NAME))
      )))
    }
    else {
      categories = categories.flatMap((node) => this.getNodeOptions(node))
    }

    if(this.isEditMode() && this.props.financial_movement.financial_category_id) {
      return [
        ...categories
      ];
    }

    return [
      SelectOption('', 'Selecione um centro de custo'),
      ...categories
    ];
  }

  getPaymentMethodOptions() {
    return [
      SelectOption('', 'Selecione uma forma de pagamento'),
      ...this.props.paymentMethods.map((method) => SelectOption(method.id, method.name))
    ];
  }

  isEditMode() {
    return this.props.financial_movement.id && this.props.financial_movement.id > 0;
  }

  lockEditions() {
    return this.isEditMode() && (this.props.financial_movement.contract_id || !this.props.userPermissionIds.includes(permissions.EDIT_FINANCIAL_ENTRIES_PERMISSION_ID));
  }

  getTransactions() {
    if(this.props.financial_movement.transactions.length > 0 &&
        this.props.totalValue &&
        this.props.totalValue > 0 &&
        this.props.firstPaymentDate.length > 0 &&
        this.props.transactionCount &&
        parseInt(this.props.transactionCount) > 0) {
      const transactions = this.props.financial_movement.transactions.map((transaction, position) => (
        <div
          className="financial-movement-data__transaction"
          key={`transactions:${position}`}
        >

          <p className="financial-movement-data__transaction__text"> {position+1}ª </p>

          <div className="financial-movement-data__transaction__input-wrapper">

            <DefaultInput
              className="financial-movement-data__transaction__value"
              name={`transactions:${position}:value`}
              isHighlighted={this.isHighlighted(`transactions:${position}:value`)}
              label="Valor"
              type="number"
              placeholder="Valor da entrada"
              min="0.00"
              step="0.01"
              handleInputChange={this.props.handleInputChange}
              value={transaction.value}
              autoComplete="off"
              prefix="R$"
              onKeyDown={(event) => this.handleKeyDown(event)}
              onFocus={(event) => event.target.select()}
              disabled={this.lockEditions() || this.props.nf_coupon_generated}
            />

            <DefaultInput
              className="financial-movement-data__transaction__date"
              name={`transactions:${position}:effective_date`}
              isHighlighted={this.isHighlighted(`transactions:${position}:effective_date`)}
              label="Data de pagamento"
              type="date"
              placeholder="Data de início do contrato"
              handleInputChange={this.props.handleInputChange}
              value={transaction.effective_date}
              onKeyDown={(event) => this.handleKeyDown(event)}
              disabled={this.lockEditions()}
            />

            {(this.props.userPermissionIds.includes(permissions.ADD_FINANCIAL_ENTRIES_PERMISSION_ID) &&
              (!this.isEditMode() || this.props.userPermissionIds.includes(permissions.EDIT_FINANCIAL_ENTRIES_PERMISSION_ID))) &&
              <DefaultInput
                className="financial-movement-data__transaction__completed"
                name={`transactions:${position}:completed`}
                isHighlighted={this.isHighlighted(`transactions:${position}:completed`)}
                label="Pago"
                type="toggle"
                isHorizontal={window.innerWidth <= 510}
                activeText="Sim"
                inactiveText="Não"
                handleInputChange={this.props.handleInputChange}
                value={transaction.completed}
              />
            }

          </div>

        </div>
      ));

      return (
        <div className="financial-movement-data__sub-section">

          {transactions}

        </div>
      );
    }
  }

  getActionButtons() {
    if(this.props.financial_movement.contract_id) {
      return (
        <div className="default-section__links-wrapper">

          <Link
            className="default-section__default-link-button"
            to={`${routes.STUDENT_EDIT_PATH}${this.props.financial_movement.user_id}${paths.CONTRACT_EDIT_PATH}${this.props.financial_movement.contract_id}`}
            replace={true}
          >

              <i className="fas fa-link default-section__default-link-button__icon"></i> Contrato

          </Link>

        </div>
      );
    }
    else if(this.props.financial_movement.user_id) {
      return (
        <div className="default-section__links-wrapper">

          {!this.isEditMode() &&
            <button
              className="model-table__default-link-button financial-movement-data__action-button--purple"
              onClick={() => this.props.fillAsRegistrationFee()}
            >

              <i className="fa-solid fa-file-lines financial-movement-data__action-button__icon"></i>
              Matrícula

            </button>
          }

          <Link
            className="default-section__default-link-button financial-movement-data__action-button"
            to={`${routes.STUDENT_MANAGE_PATH}${this.props.financial_movement.user_id}`}
          >

              <i className="fas fa-link default-section__default-link-button__icon"></i> Aluno

          </Link>

          {this.isEditMode() &&
            <button
              className="model-table__default-delete-button financial-movement-data__action-button"
              onClick={() => this.onDeleteEntry(this.props.financial_movement.id)}
            >

              <i className="far fa-trash-alt financial-movement-data__action-button__icon"></i>
              Deletar

            </button>
          }

        </div>
      );
    }

    if (!this.isEditMode()) {
      return null;
    }

    return (
      <div className="default-section__links-wrapper">

        <button
          className="model-table__default-delete-button financial-movement-data__action-button"
          onClick={() => this.onDeleteEntry(this.props.financial_movement.id)}
        >

          <i className="far fa-trash-alt financial-movement-data__action-button__icon"></i>
          Deletar

        </button>

      </div>
    );
  }

  getCostCenterDescription() {
    if(!this.props.financial_movement.cost_center_id) {
      return null;
    }

    const costCenter = this.props.costCenters.find((entry) => entry.id === parseInt(this.props.financial_movement.cost_center_id));

    if(!costCenter) {
      return null;
    }

    return costCenter.description;
  }

  getFinancialCategoryDescription() {
    if(!this.props.financial_movement.financial_category_id) {
      return null;
    }

    const category = this.props.financialCategories.find((entry) => entry.id === parseInt(this.props.financial_movement.financial_category_id));

    if(!category) {
      return null;
    }

    return category.description;
  }

  getConfirmationWindowTitle() {
    if(this.state.confirmInProgress) {
      if(this.state.onCancelPaymentDeviceRequest) {
        return 'Cancelando cobrança';
      }

      return 'Deletando entrada';
    }
    else if(this.state.confirmFailed) {
      if(this.state.onCancelPaymentDeviceRequest) {
        return 'Falha ao cancelar cobrança';
      }

      return 'Falha ao deletar';
    }
    else if(this.state.onCancelPaymentDeviceRequest) {
      return 'Cancelar cobrança';
    }

    return 'Deletar entrada';
  }

  getConfirmationWindowDescription() {
    if(this.state.confirmFailed) {
      return this.state.confirmFailDescription;
    }
    else if(this.state.onCancelPaymentDeviceRequest) {
      return 'Após confirmar o cancelamento, a cobrança deve desaparecer da ' +
             'maquininha de cartão dentro de até 1 minuto. Para agilizar este ' +
             'processo, basta pressionar o botão "X" vermelho da maquininha.';
    }

    return 'Todos os dados relacionados à entrada serão removidos';
  }

  getConfirmationWindowConfirmText() {
    if(this.state.onCancelPaymentDeviceRequest) {
      return 'Cancelar cobrança';
    }

    return 'Deletar entrada';
  }

  onDeleteEntry(entryId) {
    this.setState({
      deleteId: entryId,
      onCancelPaymentDeviceRequest: false,
      confirmInProgress: false,
      confirmFailed: false
    });
  }

  onCancelPaymentDeviceRequest() {
    this.setState({
      deleteId: null,
      onCancelPaymentDeviceRequest: true,
      confirmInProgress: false,
      confirmFailed: false
    });
  }

  onCancelConfirmationWindow() {
    this.setState({
      deleteId: null,
      onCancelPaymentDeviceRequest: false,
      confirmInProgress: false,
      confirmFailed: false
    });
  }

  async onConfirmConfirmationWindow() {
    if (this.state.deleteId !== null) {
      if (this.lockEditions()) {
        return
      }

      this.setState({
        confirmInProgress: true
      });

      try{
        if(await deleteModel(`${routes.FINANCIAL_MOVEMENT_DELETE_API}${this.state.deleteId}`)) {
          this.props.history.replace(this.props.isExpense ? routes.EXPENSE_LIST_PATH : routes.INCOME_LIST_PATH);
          return;
        }
      }
      catch(errors) {
        let errorDescription = DEFAULT_UNKNOWN_ERROR_MESSAGE;

        if(errors instanceof Array) {
          for(let error of errors) {
            switch (error.code) {
              // case 104:
              //   for(let parameter of error.parameters) {
              //     switch (parameter.name) {
              //       case 'contracts':
              //         errorDescription = 'Serviço vinculado à um contrato de aluno. Estes contratos devem ser excluídos antes de excluir este serviço.';
              //
              //         break;
              //       default:
              //     }
              //   }
              //
              //   break;
              case 209:
                errorDescription = 'Sessão do usuário expirada';

                break;
              default:
            }
          }
        }

        this.setState({
          confirmFailDescription: errorDescription,
          confirmFailed: true,
          confirmInProgress: false
        });

        return;
      }
    }
    else if (this.state.onCancelPaymentDeviceRequest) {
      const payment_device_requests = this.props.financial_movement.payment_device_requests.filter((entry) => !entry.closed);

      if (payment_device_requests.length <= 0) {
        return;
      }

      this.setState({
        confirmInProgress: true
      });

      try{
        if(await patchModel(routes.CANCEL_PAYMENT_DEVICE_REQUEST_PATCH_API.replace('{id}', payment_device_requests[0].id), {})) {
          await this.props.updateFinancialMovement();

          this.onCancelConfirmationWindow();

          return;
        }
      }
      catch(errors) {
        let errorDescription = DEFAULT_UNKNOWN_ERROR_MESSAGE;

        if(errors instanceof Array) {
          for(let error of errors) {
            switch (error.code) {
              // case 104:
              //   for(let parameter of error.parameters) {
              //     switch (parameter.name) {
              //       case 'contracts':
              //         errorDescription = 'Serviço vinculado à um contrato de aluno. Estes contratos devem ser excluídos antes de excluir este serviço.';
              //
              //         break;
              //       default:
              //     }
              //   }
              //
              //   break;
              case 209:
                errorDescription = 'Sessão do usuário expirada';

                break;
              default:
            }
          }
        }

        this.setState({
          confirmFailDescription: errorDescription,
          confirmFailed: true,
          confirmInProgress: false
        });

        return;
      }
    }
  }

  async onSendPaymentToDevice() {
    this.setState({overlayIsLoading: true});

    await this.props.onSendPaymentToDevice();

    this.setState({overlayIsLoading: false});
  }

  async onCheckPaymentStatus() {
    this.setState({overlayIsLoading: true});

    await this.props.updateFinancialMovement();

    this.setState({overlayIsLoading: false});
  }

  getPaymentDeviceOptions() {
    if(this.props.selectedPaymentDevice !== null) {
      return this.props.availablePaymentDevices.map((entry) => SelectOption(entry.id, entry.name));
    }

    return [
      SelectOption('', 'Selecione a maquininha'),
      ...this.props.availablePaymentDevices.map((entry) => SelectOption(entry.id, entry.name))
    ];
  }

  isAwatingPaymentCompletion() {
    return this.props.financial_movement.payment_device_requests.some((entry) => !entry.closed);
  }

  getOverlayActions() {
    if (!this.props.showPaymentDeviceOverlay) {
      return null;
    }

    if (!this.isAwatingPaymentCompletion()) {
      return (
        <React.Fragment>

          <DefaultMenuButton
            className="financial-movement-data__overlay__action-button"
            onClick={() => this.props.onCancelPaymentProcess()}
            text="Cancelar"
          />

          <DefaultMenuButton
            className="financial-movement-data__overlay__action-button"
            onClick={() => this.onSendPaymentToDevice()}
            color="green"
            text="Confirmar"
            disabled={this.props.selectedPaymentDevice === null ||
                      this.state.overlayIsLoading ||
                      (this.props.financial_movement.user_id === null &&
                       (this.props.customerName.length <= 0 ||
                        this.props.customerEmail.length <= 0))}
          />

        </React.Fragment>
      );
    }

    return (
      <React.Fragment>

        <DefaultMenuButton
          className="financial-movement-data__overlay__action-button"
          onClick={() => this.onCancelPaymentDeviceRequest()}
          color="red"
          text="Cancelar"
          disabled={this.state.overlayIsLoading}
        />

        <DefaultMenuButton
          className="financial-movement-data__overlay__action-button"
          onClick={() => this.onCheckPaymentStatus()}
          text="Atualizar"
          disabled={this.state.overlayIsLoading}
        />

      </React.Fragment>
    );
  }

  getOverlayHeader() {
    if (!this.props.showPaymentDeviceOverlay) {
      return null;
    }

    if (!this.isAwatingPaymentCompletion()) {
      return 'Efetuar pagamento';
    }

    return 'Aguardando pagamento';
  }

  getOverlayContent() {
    if (!this.props.showPaymentDeviceOverlay) {
      return null;
    }

    if (!this.isAwatingPaymentCompletion()) {
      return (
        <React.Fragment>

          <p className='financial-movement-data__payment-device-process__message'>

            Cadatro salvo com sucesso! Para prosseguir com a coleta do pagamento, por favor, selecione a maquininha de cartão desejada.

          </p>

          <DefaultInput
            name="selectedPaymentDevice"
            isHighlighted={false}
            label="Máquina de cartão para operação"
            type="select"
            handleInputChange={this.props.handleInputChange}
            value={this.props.selectedPaymentDevice || ''}
            options={this.getPaymentDeviceOptions()}
          />

          <DefaultInput
            name="savePaymentDeviceSelected"
            label="Lembrar maquina selecionada"
            type="checkbox"
            isHorizontal={true}
            invertLabel={true}
            handleInputChange={this.props.handleInputChange}
            value={this.props.savePaymentDeviceSelected}
          />

          {this.props.financial_movement.user_id === null &&
            <HalfWrapper>

              <DefaultInput
                name="customerName"
                isHighlighted={this.props.customerName.length <= 0}
                label="Nome para cobrança"
                type="text"
                placeholder="Nome completo"
                maxLength="128"
                handleInputChange={this.props.handleInputChange}
                value={this.props.customerName}
                autoComplete="off"
              />

              <DefaultInput
                name="customerEmail"
                isHighlighted={this.props.customerEmail.length <= 0}
                label="E-mail para cobrança"
                type="email"
                placeholder="E-mail"
                maxLength="128"
                handleInputChange={this.props.handleInputChange}
                value={this.props.customerEmail}
                autoComplete="off"
              />

            </HalfWrapper>
          }

        </React.Fragment>
      );
    }

    return (
      <p className='financial-movement-data__payment-device-process__message'>

        Aguardando pagamento na maquininha. Assim que o pagamento for efetuado, pressione "Atualizar"
        para verifiar se o pagamento foi concluído com sucesso. Para cancelar a cobrança, pressione "Cancelar".

      </p>
    );
  }

  getActiveSearchTags() {
    const filteredTags = this.props.financial_search_tags.filter((tag) => this.props.financial_movement.search_tag_ids.includes(tag.id));

    return filteredTags.map((tag) => (
      <div
        key={`financial_movement_data:active_search_tag:${tag.id}`}
        className={`financial-movement-data__active-search-tag${this.lockEditions() ? '--disabled' : ''}`}
      >
        {tag.name}

        {!this.lockEditions() &&
          <button
            className="financial-movement-data__active-search-tag__button"
            onClick={(event) => {
              this.props.onToggleSearchTag(tag.id);
              event.stopPropagation();
            }}
          >

            <i className="fa-solid fa-xmark"></i>

          </button>
        }
      </div>
    ));
  }

  getSearchTagOptions() {
    if(!this.props.financial_search_tags) {
      return null;
    }

    const filteredTags = this.props.financial_search_tags.filter((tag) => {
      let hasValidType = true;

      if (tag.type !== BOTH_TYPE_NAME && !this.props.financial_movement.search_tag_ids.includes(tag.id)) {
        hasValidType = (this.props.isExpense && tag.type === EXPENSE_TYPE_NAME) || (!this.props.isExpense && tag.type === INCOME_TYPE_NAME);
      }

      return hasValidType && tag.name.toLocaleLowerCase().includes(this.state.searchTagFilter.toLocaleLowerCase());
    });

    if(filteredTags.length <= 0) {
      return null;
    }

    return filteredTags.map((tag) => {
      const selected = this.props.financial_movement.search_tag_ids.includes(tag.id);

      return (
        <div
          key={`financial_movement_data:search_tag:${tag.id}`}
          className={`financial-movement-data__search-tag-option${!selected ? '--disabled': ''}`}
        >

          <button
            className="financial-movement-data__search-tag-option__check-button"
            onClick={() => this.props.onToggleSearchTag(tag.id)}
            disabled={this.lockEditions()}
          >

            {selected &&
              <i className="fas fa-check"></i>
            }

          </button>

          <p className="financial-movement-data__search-tag-option__text">

            {tag.name}

          </p>

        </div>
      );
    });
  }

  render() {
    return (
      <React.Fragment>

        <OverlayWindow
          className="financial-movement-data__overlay"
          visible={this.props.showPaymentDeviceOverlay}
          loading={this.state.overlayIsLoading}
          actions={(
            <div className="financial-movement-data__overlay__action-container">

              {this.getOverlayActions()}

            </div>
          )}
        >

          <header className="financial-movement-data__overlay__header">

            <h3 className="financial-movement-data__overlay__header__title">
              {this.getOverlayHeader()}
            </h3>

          </header>

          <hr className="financial-movement-data__horizontal-rule" />

          <div className="financial-movement-data__overlay__content">

            {this.getOverlayContent()}

          </div>

        </OverlayWindow>

        <ConfirmationWindow
          title={this.getConfirmationWindowTitle()}
          description={this.getConfirmationWindowDescription()}
          confirmText={this.getConfirmationWindowConfirmText()}
          cancelText={this.state.confirmFailed ? 'Ok' : 'Cancelar'}
          visible={this.state.deleteId !== null || this.state.onCancelPaymentDeviceRequest}
          onCancel={() => this.onCancelConfirmationWindow()}
          onConfirm={() => this.onConfirmConfirmationWindow()}
          loading={this.state.confirmInProgress}
          useErrorIcon={this.state.confirmFailed}
          hideConfirmButton={this.state.confirmFailed}
        />

        <DefaultSection
          className="financial-movement-data"
          title={this.props.isExpense ? 'Dados da despesa' : 'Dados da receita'}
        >

          {this.getActionButtons()}


          {this.props.paymentReceived !== null &&
            <p className="financial-movement-data__alert-text">

              <i className="fas fa-exclamation financial-movement-data__alert-text__icon"></i>
              <span className="financial-movement-data__alert-text__text-wrapper">

                <span className="financial-movement-data__alert-text__text">
                  Pagamento efetuado
                </span>

                {(this.props.financial_movement.credit_card_brand !== null || this.props.financial_movement.last_credit_card_digits !== null) &&
                  <span className="financial-movement-data__alert-text__text">
                    {this.props.financial_movement.credit_card_brand !== null && this.props.financial_movement.credit_card_brand}{this.props.financial_movement.last_credit_card_digits !== null ? `(Terminado em ${this.props.financial_movement.last_credit_card_digits})` : null}
                  </span>
                }

                {this.props.paymentReceived.acquirer_transaction_key !== null &&
                  <span className="financial-movement-data__alert-text__text">
                    ID: {this.props.paymentReceived.acquirer_transaction_key}
                  </span>
                }

                <span className="financial-movement-data__alert-text__text">
                  {getAsLocalDatetime(this.props.paymentReceived.updated_at, false).toLocaleString()}
                </span>

              </span>

            </p>
          }

          <div className="financial-movement-data__warning-container">

            <WarningMessage
              message={this.props.warningMessage}
              onClose={this.props.onCloseWarning}
              visible={this.props.showWarningMessage}
            />

          </div>

          <div className="financial-movement-data__input-container">

            <HalfWrapper>

              <DefaultInput
                className="financial-movement-data__default-input"
                name="cost_center_id"
                isHighlighted={this.isHighlighted("cost_center_id")}
                label="Centro de custo"
                labelMessage={this.getCostCenterDescription()}
                type="select"
                handleInputChange={this.props.handleInputChange}
                value={this.props.financial_movement.cost_center_id || ''}
                options={this.getCostCenterOptions()}
                disabled={this.lockEditions()}
              />

              {this.props.financial_movement.cost_center_id > 0 &&
                <DefaultInput
                  className="financial-movement-data__default-input"
                  name="financial_category_id"
                  isHighlighted={this.isHighlighted("financial_category_id")}
                  label="Categoria"
                  labelMessage={this.getFinancialCategoryDescription()}
                  type="select"
                  handleInputChange={this.props.handleInputChange}
                  value={this.props.financial_movement.financial_category_id || ''}
                  options={this.getFinancialCategoryOptions()}
                  disabled={this.lockEditions()}
                />
              }

            </HalfWrapper>

            <DefaultInput
              name="month_reference"
              isHighlighted={this.isHighlighted("month_reference")}
              label="Mês de referência"
              labelMessage={`Utilizado para indicar o mês que originou a ${this.props.isExpense ? "despesa" : "receita"}`}
              type="month"
              placeholder="Mês de origem"
              handleInputChange={this.props.handleInputChange}
              value={this.props.financial_movement.month_reference}
              onKeyDown={(event) => this.handleKeyDown(event)}
              disabled={this.lockEditions()}
            />

            <DefaultInput
              name="description"
              isHighlighted={this.isHighlighted("description")}
              label="Descrição"
              type="textarea"
              placeholder={this.props.isExpense ? "Descrição da despesa" : "Descrição da receita"}
              rows="3"
              handleInputChange={this.props.handleInputChange}
              value={this.props.financial_movement.description || ''}
              onKeyDown={(event) => this.handleKeyDown(event)}
              disabled={this.lockEditions()}
            />

            <section className="financial-movement-data__default-section">

              <header
                className={`financial-movement-data__default-section__header${this.lockEditions() ? '--disabled' : ''}`}
                onClick={() => {
                  if (!this.lockEditions()) {
                    this.setState({searchTagsSectionVisible: !this.state.searchTagsSectionVisible});
                  }
                }}
              >

                <h3 className="financial-movement-data__default-section__header__text">

                  <div className="financial-movement-data__default-section__header__text-wrapper">
                    <i className="fa-solid fa-tags financial-movement-data__default-section__header__text-icon"></i>
                    Palavras-chave:
                  </div>
                  <div className="financial-movement-data__active-search-tag__wrapper">
                    {this.getActiveSearchTags()}
                  </div>

                </h3>

                {this.state.searchTagsSectionVisible ?
                  <i className="fas fa-chevron-down financial-movement-data__default-section__header__visible-icon"></i>:
                  <i className="fas fa-chevron-up financial-movement-data__default-section__header__visible-icon"></i>
                }

              </header>

              <VerticalAccordionContainer
                className="vertical-accordion-container financial-movement-data__default-section__content"
                pose={this.state.searchTagsSectionVisible ? 'verticalOpen' : 'verticalClosed'}
              >

                <div className="vertical-accordion-container financial-movement-data__default-section__content-wrapper">

                  <div className="financial-movement-data__default-section-container">

                    <DefaultInput
                      name="searchTagFilter"
                      label="Busca rápida:"
                      type="text"
                      handleInputChange={(event) => this.handleInputChange(event)}
                      value={this.state.searchTagFilter}
                      autoComplete="off"
                      disabled={this.state.confirmInProgress}
                    />

                    <div className="financial-movement-data__search-tag-options-container">

                      {this.getSearchTagOptions()}

                    </div>

                  </div>

                </div>

              </VerticalAccordionContainer>

            </section>

            <HalfWrapper>

              <DefaultInput
                name={'totalValue'}
                isHighlighted={this.isHighlighted('totalValue')}
                label="Valor total"
                type="number"
                placeholder="Valor total"
                min="0.00"
                step="0.01"
                handleInputChange={this.props.handleInputChange}
                value={this.props.totalValue}
                autoComplete="off"
                prefix="R$"
                onKeyDown={(event) => this.handleKeyDown(event)}
                onFocus={(event) => event.target.select()}
                disabled={this.lockEditions() || this.props.nf_coupon_generated}
              />

              <DefaultInput
                name={'payment_method_id'}
                isHighlighted={this.isHighlighted('payment_method_id')}
                label="Forma de pagamento"
                labelMessage={this.props.getPaymentMethodAlertMessage()}
                placeLabelMessageBellow={true}
                type="select"
                handleInputChange={this.props.handleInputChange}
                value={this.props.financial_movement.payment_method_id || ''}
                options={this.getPaymentMethodOptions()}
                disabled={this.lockEditions()}
              />

            </HalfWrapper>

            <HalfWrapper>

              {this.props.isExpense ? (
                <DefaultInput
                  name={'transactionCount'}
                  isHighlighted={this.isHighlighted('transactionCount')}
                  label="Número de parcelas"
                  labelMessage="Limite máximo de 72 parcelas"
                  type="number"
                  placeholder="Número de parcelas"
                  min="1"
                  max="72"
                  step="1"
                  handleInputChange={this.props.handleInputChange}
                  value={this.props.transactionCount}
                  autoComplete="off"
                  onKeyDown={(event) => this.handleKeyDown(event)}
                  onFocus={(event) => event.target.select()}
                  disabled={this.lockEditions()}
                />
              ) : (
                <DefaultInput
                  name="transactionCount"
                  isHighlighted={false}
                  label="Número de parcelas"
                  labelMessage={this.props.nf_coupon_generated ? 'Campo bloqueado: nota fiscal emitida para um ou mais pagamentos' : null}
                  type="select"
                  handleInputChange={this.props.handleInputChange}
                  value={this.props.transactionCount || ''}
                  options={[
                    SelectOption('', 'Selecione a quantidade'),
                    SelectOption('1', 'À vista'),
                    SelectOption('2', '2'),
                    SelectOption('3', '3'),
                    SelectOption('4', '4'),
                    SelectOption('5', '5'),
                    SelectOption('6', '6'),
                    SelectOption('7', '7'),
                    SelectOption('8', '8'),
                    SelectOption('9', '9'),
                    SelectOption('10', '10'),
                    SelectOption('11', '11'),
                    SelectOption('12', '12'),
                  ]}
                  disabled={this.lockEditions() || this.props.nf_coupon_generated}
                />
              )}

              <DefaultInput
                name="firstPaymentDate"
                isHighlighted={this.isHighlighted("firstPaymentDate")}
                label="Data do primeiro pagamento"
                type="date"
                placeholder="Data da primeira parcela"
                handleInputChange={this.props.handleInputChange}
                value={this.props.firstPaymentDate}
                onKeyDown={(event) => this.handleKeyDown(event)}
                disabled={this.lockEditions()}
              />

            </HalfWrapper>

            {this.getTransactions()}

          </div>

          {((!this.isEditMode() || this.props.userPermissionIds.includes(permissions.EDIT_FINANCIAL_ENTRIES_PERMISSION_ID)) &&
            this.props.maySendToPaymentDevice()) &&
            <React.Fragment>

              <HorizontalRule />

              <DefaultInput
                name="sendToPaymentDevice"
                isHighlighted={false}
                label="Enviar para maquininha de cartão: "
                type="toggle"
                isHorizontal={true}
                activeText="Sim"
                inactiveText="Não"
                handleInputChange={this.props.handleInputChange}
                value={this.props.sendToPaymentDevice}
              />

            </React.Fragment>
          }

          <HorizontalRule />

          <div className="financial-movement-data__buttons-container">

            {(!this.isEditMode() || this.props.userPermissionIds.includes(permissions.EDIT_FINANCIAL_ENTRIES_PERMISSION_ID)) &&
              <button
                className="financial-movement-data__save-button"
                disabled={!this.props.enableSave}
                onClick={this.props.onSave}
              >

                Salvar

              </button>
            }

            <button
              className="financial-movement-data__cancel-button"
              onClick={() => this.props.history.goBack()}
            >

              {(!this.isEditMode() || this.props.userPermissionIds.includes(permissions.EDIT_FINANCIAL_ENTRIES_PERMISSION_ID)) ? 'Cancelar' : 'Voltar'}

            </button>

            {/* <Link
              className="financial-movement-data__cancel-button"
              to={this.props.onCancelPath}
            >

              Cancelar

            </Link> */}

          </div>

        </DefaultSection>

      </React.Fragment>
    );
  }
}

export default FinancialMovementData;
