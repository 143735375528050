import React from 'react';
import { Link } from 'react-router-dom';
import './student_list.scss';
import ContentFrame from '../content_frame';
import * as routes from '../../constants';
import ModelTable, {Property} from '../../utils/model_table';
import DefaultSection, {HorizontalRule} from '../../utils/default_section';
import ConfirmationWindow from '../confirmation_window';
import DefaultInput, {HalfWrapper, SelectOption} from '../../utils/default_input';
import {getModels, deleteModel, getPhoneText, getAsLocalDate, setUrlParameters} from '../../utils/functions';
import {DEFAULT_UNKNOWN_ERROR_MESSAGE, CONTRACT_EXPIRATION_ALERT_RANGE} from '../../constants';
import * as permissions from '../../permissions';


class StudentList extends React.Component {
  constructor(props) {
    super(props);

    let queryParameters = (new URLSearchParams(props.location.search));

    let expirationInitialDate = queryParameters.get('initial_expiration_date');
    let expirationFinalDate = queryParameters.get('final_expiration_date');

    let studentStatus = queryParameters.get('status');

    let lastServicePlanId = queryParameters.get('last_service_plan_id');

    let nameInitialfilter = queryParameters.get('name_starts_with_filter');

    if(!expirationInitialDate) {
      expirationInitialDate = '';
    }
    if(!expirationFinalDate) {
      expirationFinalDate = '';
    }

    if(!studentStatus) {
      studentStatus = 'all';
    }

    if(!lastServicePlanId) {
      lastServicePlanId = '';
    }

    if(!nameInitialfilter) {
      nameInitialfilter = '';
    }

    this.state = {
      expirationInitialDateInput: expirationInitialDate,
      expirationInitialDateFilter: expirationInitialDate,
      expirationFinalDateInput: expirationFinalDate,
      expirationFinalDateFilter: expirationFinalDate,
      studentStatusInput: studentStatus,
      studentStatusFilter: studentStatus,
      lastServicePlanIdInput: lastServicePlanId,
      lastServicePlanIdFilter: lastServicePlanId,
      nameInitialfilter,
      loadingData: false,
      students: [],
      services: [],
      deleteId: null,
      confirmInProgress: false,
      confirmFailed: false,
      confirmFailDescription: "",
      screenWidth: window.innerWidth
    };
  }

  async getStudents() {
    const parameters = {};
    // const parameters = {
    //   initial_expiration_date: this.state.expirationInitialDateFilter,
    //   final_expiration_date: this.state.expirationFinalDateFilter,
    //   active_only: this.state.studentStatusFilter,
    // };
    //
    // if(parameters.active_only !== 'active_only') {
    //   parameters.active_only = null;
    // }

    if (this.state.nameInitialfilter.length === 1) {
      parameters.name_starts_with_filter = this.state.nameInitialfilter;
    }

    return await getModels(setUrlParameters(routes.STUDENTS_GET_API, parameters));
  }

  async getServices() {
    const parameters = {};

    return await getModels(setUrlParameters(routes.SERVICES_GET_API, parameters));
  }

  async componentDidMount() {
    if (this.state.nameInitialfilter.length > 0) {
      this.reloadList();
    }

    this.resizeListener = () => this.updateSize();

    window.addEventListener("resize", this.resizeListener);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resizeListener);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.nameInitialfilter !== this.state.nameInitialfilter) {
      this.reloadList();
    }
  }

  updateSize() {
    this.setState({
      screenWidth: window.innerWidth
    });
  }

  async reloadList() {
    this.setState({
      loadingData: true
    });

    let students = this.getStudents();
    let services = this.getServices();

    students = await students
    services = await services

    this.setState({
      loadingData: false,
      students,
      services
    });
  }

  onDeleteEntry(entryId) {
    this.setState({
      deleteId: entryId,
      confirmInProgress: false,
      confirmFailed: false
    });
  }

  onCancelDelete() {
    this.setState({
      deleteId: null
    });
  }

  async onConfirmDelete() {
    this.setState({
      confirmInProgress: true
    });

    try{
      if(await deleteModel(`${routes.STUDENT_DELETE_API}${this.state.deleteId}`)) {
        this.reloadList();
      }
    }
    catch(errors) {
      let errorDescription = null;

      if(errors instanceof Array) {
        for(let error of errors) {
          if(error.code === 203) {
            if(error.message.includes('Requires higher access level')) {
              errorDescription = 'Você não possui permissão para remover este aluno. Por favor, entre em contato com o responsável pela unidade FYD para realizar a operação.';
            }
          }
          else if(error.code === 209) {
            errorDescription = 'Sessão do usuário expirada.';
          }
        }
      }

      if(!errorDescription) {
        errorDescription = DEFAULT_UNKNOWN_ERROR_MESSAGE;
      }

      this.setState({
        confirmFailDescription: errorDescription,
        confirmFailed: true,
        confirmInProgress: false
      });

      return;
    }

    this.setState({
      deleteId: null,
    });
  }

  getLastContractExpirationText(entry) {
    if (entry.last_contract_expiration_date === null) {
      return '';
    }

    const todayIsoDate = (new Date()).toISOString().slice(0, 10);
    const today = getAsLocalDate(todayIsoDate);
    const date = getAsLocalDate(entry.last_contract_expiration_date);

    const timeDiff = Math.abs(date.getTime() - today.getTime());
    let daysDiff = (date > today ? 1 : -1)*Math.ceil(timeDiff / (1000 * 3600 * 24));

    // const dateFormat = {day: '2-digit', month: '2-digit', year: 'numeric'};
    //
    // const dateText = new Intl.DateTimeFormat('pt-BR', dateFormat).format(date);
    const dateText = date.toLocaleDateString();

    let expirationStatus = '--past';
    let daysDiffText = '';

    if (daysDiff >= 0) {
      if (daysDiff === 0) {
        daysDiffText = ' (HOJE)';
      }
      else if (daysDiff === 1) {
        daysDiffText = ` (${daysDiff} dia)`;
      }
      else {
        daysDiffText = ` (${daysDiff} dias)`;
      }

      if (daysDiff < CONTRACT_EXPIRATION_ALERT_RANGE) {
        expirationStatus = '--warning';
      }
      else {
        expirationStatus = '--future';
      }
    }

    return (
      <div className="student-list__cell-wrapper--centered">

        <p className={`student-list__date-text${expirationStatus}`}>

          {`${dateText}${daysDiffText}`}

        </p>

      </div>
    );
  }

  getLastContractExpirationFilter(entry) {
    if (entry.last_contract_expiration_date === null) {
      return '';
    }

    return (getAsLocalDate(entry.last_contract_expiration_date).toLocaleDateString());
  }

  getStatusText(entry) {
    return (
      <p className={`student-list__status-text__${entry.status.toLowerCase()}`}>{entry.status}</p>
    );
  }

  getLastServicePlanText(entry) {
    if (entry.last_contract_service_plan_id === null) {
      return '';
    }

    const servicePlan = this.state.services.find((service_plan) => service_plan.id === entry.last_contract_service_plan_id);

    if (!servicePlan) {
      return 'ERRO';
    }

    return servicePlan.name;
  }

  getActions(entry) {
    return (
      <div className="model-table__model-actions-container student-list__action-container">

        <Link
          className="model-table__default-edit-button student-list__manage-button"
          to={`${routes.STUDENT_MANAGE_PATH}${entry.id}`}
        >

            <i className="fas fa-user-cog student-list__manage-button__icon"></i>
            Gerenciar

        </Link>

        {this.props.userPermissionIds.includes(permissions.DELETE_STUDENT_PERMISSION_ID) &&
          <button
            className="model-table__default-delete-button"
            onClick={() => this.onDeleteEntry(entry.id)}
          >

            <i className="far fa-trash-alt"></i>

          </button>
        }

      </div>
    );
  }

  getProperties() {
    const properties = [
      Property('name', 'Nome', <i className="fas fa-id-card"></i>),
    ];

    if(this.state.screenWidth > 660) {
      properties.push(
        Property('email', 'E-mail', <i className="fas fa-envelope"></i>)
      );
    }

    if(this.state.screenWidth > 10) {
      properties.push(
        Property('phone', 'Telefone', <i className="fas fa-phone"></i>, {getDataText: (entry) => getPhoneText(entry.phone)})
      );
    }

    if(this.state.screenWidth > 740) {
      properties.push(
        Property(
          'last_contract_expiration_date',
          this.state.screenWidth > 1230 ? 'Expiração de contrato' : 'Data',
          <i className="fas fa-calendar-alt"></i>,
          {getDataText: this.getLastContractExpirationText, getFilterText: this.getLastContractExpirationFilter})
      );
      properties.push(
        Property(
          'last_contract_service_plan_id',
          'Último plano',
          <i className="fas fa-concierge-bell"></i>,
          {getDataText: (entry) => this.getLastServicePlanText(entry), getFilterText: (entry) => this.getLastServicePlanText(entry)})
      );
    }

    if(this.state.screenWidth > 10) {
      properties.push(
        Property('status', 'Situação', <i className="fas fa-thermometer-half"></i>, {getDataText: this.getStatusText})
      );
    }

    return properties;
  }

  getConfirmationWindowTitle() {
    if(this.state.confirmInProgress) {
      return 'Deletando aluno';
    }
    else if(this.state.confirmFailed) {
      return 'Falha ao deletar aluno';
    }

    return 'Deletar aluno PERMANENTEMENTE';
  }

  handleInputChange(event) {
    const target = event.target;
    let value = target.value;
    let name = target.name;

    const update = {[name]: value};

    this.setState(update);
  }

  mayUpdateDateInputs() {
    if(this.state.expirationInitialDateInput !== this.state.expirationInitialDateFilter ||
       this.state.expirationFinalDateInput !== this.state.expirationFinalDateFilter ||
       this.state.lastServicePlanIdInput !== this.state.lastServicePlanIdFilter ||
       this.state.studentStatusInput !== this.state.studentStatusFilter) {
      return true;
    }

    return false;
  }

  mayResetFilterInputs() {
    if(this.state.expirationInitialDateInput.length > 0 ||
       this.state.expirationFinalDateInput.length > 0 ||
       this.state.lastServicePlanIdInput.length > 0 ||
       this.state.studentStatusInput !== 'all') {
      return true;
    }

    return false;
  }

  resetFilterInputs() {
    this.setState({
      expirationInitialDateInput: '',
      expirationFinalDateInput: '',
      lastServicePlanIdInput: '',
      studentStatusInput: 'all',
    });
  }

  applyDateInputChanges() {
    if(this.mayUpdateDateInputs()) {
      this.props.history.replace(setUrlParameters(routes.STUDENT_LIST_PATH, {
        initial_expiration_date: this.state.expirationInitialDateInput || null,
        final_expiration_date: this.state.expirationFinalDateInput || null,
        status: this.state.studentStatusInput,
        last_service_plan_id: this.state.lastServicePlanIdInput || null,
        name_starts_with_filter: this.state.nameInitialfilter,
      }));

      this.setState({
        expirationInitialDateFilter: this.state.expirationInitialDateInput,
        expirationFinalDateFilter: this.state.expirationFinalDateInput,
        studentStatusFilter: this.state.studentStatusInput,
        lastServicePlanIdFilter: this.state.lastServicePlanIdInput,
      });
    }
  }

  onSelectNameInitial(nameInitialfilter) {
    this.props.history.replace(setUrlParameters(routes.STUDENT_LIST_PATH, {
      initial_expiration_date: this.state.expirationInitialDateFilter || null,
      final_expiration_date: this.state.expirationFinalDateFilter || null,
      status: this.state.studentStatusFilter,
      last_service_plan_id: this.state.lastServicePlanIdFilter || null,
      name_starts_with_filter: nameInitialfilter,
    }));

    this.setState({nameInitialfilter});
  }

  getStudentStatusOptions() {
    return [
      SelectOption('active_only', 'Ativo'),
      SelectOption('inactive_only', 'Inativo'),
      SelectOption('all', 'Todos'),
    ];
  }

  getServicePlanOptions() {
    return [
      SelectOption('', 'Todos'),
      ...this.state.services.map((service_plan) => SelectOption(service_plan.id, service_plan.name)),
    ];
  }

  getFilteredStudents() {
    return this.state.students.filter((student) => {
      let statusCheck = true;

      if (this.state.studentStatusFilter === 'active_only') {
        statusCheck = student.status === 'Ativo';
      }
      else if (this.state.studentStatusFilter === 'inactive_only') {
        statusCheck = student.status === 'Inativo';
      }

      let contractExpirationCheck = true;

      if (this.state.expirationInitialDateFilter.length > 0) {
        contractExpirationCheck = student.last_contract_expiration_date >= this.state.expirationInitialDateFilter;
      }
      if (this.state.expirationFinalDateFilter.length > 0) {
        contractExpirationCheck = contractExpirationCheck && student.last_contract_expiration_date <= this.state.expirationFinalDateFilter;
      }

      if (this.state.lastServicePlanIdFilter.length > 0) {
        contractExpirationCheck = contractExpirationCheck && student.last_contract_service_plan_id === parseInt(this.state.lastServicePlanIdFilter);
      }

      return statusCheck && contractExpirationCheck;
    });
  }

  getInitialNameFilter() {
    const optionTexts = ['A','B','C','D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S','T','U','V','W','X','Y','Z'];

    const options = optionTexts.map((entry) => (
      <button
        key={`name_initial_selector:${entry}`}
        className="student-list__filters__name-initial-selector__options__button"
        onClick={() => this.onSelectNameInitial(entry)}
        disabled={this.state.nameInitialfilter === entry}
      >

        {entry}

      </button>
    ))

    return(
      <div className={`student-list__filters__name-initial-selector${this.state.nameInitialfilter.length <= 0 ? '--highlight' : ''}`}>

        <h4 className="student-list__filters__name-initial-selector__label">Selecione a primeira letra do nome:</h4>

        <div className="student-list__filters__name-initial-selector__options">

          <button
            className="student-list__filters__name-initial-selector__options__button"
            onClick={() => this.onSelectNameInitial('all')}
            disabled={this.state.nameInitialfilter === 'all'}
          >

            Todas

          </button>

          {options}

        </div>

      </div>
    );
  }

  render() {
    return (
      <React.Fragment>

        <ConfirmationWindow
          title={this.getConfirmationWindowTitle()}
          description={this.state.confirmFailed ? this.state.confirmFailDescription : 'Todos os dados relacionados ao aluno serão removidos PERMANENTEMENTE'}
          confirmText="Deletar aluno"
          cancelText={this.state.confirmFailed ? 'Ok' : 'Cancelar'}
          visible={this.state.deleteId !== null}
          onCancel={() => this.onCancelDelete()}
          onConfirm={() => this.onConfirmDelete()}
          loading={this.state.confirmInProgress}
          useErrorIcon={this.state.confirmFailed}
          hideConfirmButton={this.state.confirmFailed}
        />

        <ContentFrame
          location={this.props.location}
          headerHistory={[
            {
              path: routes.DESKTOP_PATH,
              text: "Área de trabalho"
            },
            {
              path: routes.STUDENT_LIST_PATH,
              text: "Listar alunos"
            },
          ]}
          titleIcon={<i className="fas fa-clipboard-list"></i>}
          title="Listar alunos"
          loading={this.state.loadingData}
        >

          <DefaultSection
            className="student-list"
            title="Lista de alunos"
          >

            <div className="student-list__filters">

              <header className="student-list__filters__header">

                <h4 className="student-list__filters__header__text">Filtros</h4>

              </header>

              <div className="student-list__filters__inputs">

                <div className="student-list__filters__inputs-wrapper">

                  <DefaultInput
                    name="studentStatusInput"
                    label="Situação do cadastro"
                    type="select"
                    handleInputChange={(event) => this.handleInputChange(event)}
                    value={this.state.studentStatusInput}
                    options={this.getStudentStatusOptions()}
                  />

                  <HalfWrapper>

                    <DefaultInput
                      name="expirationInitialDateInput"
                      label="Expiração de contrato inicial"
                      type="date"
                      placeholder="Data inicial"
                      max={this.state.expirationFinalDateInput}
                      handleInputChange={(event) => this.handleInputChange(event)}
                      value={this.state.expirationInitialDateInput}
                    />

                    <DefaultInput
                      name="expirationFinalDateInput"
                      label="Expiração de contrato final"
                      type="date"
                      placeholder="Data final"
                      min={this.state.expirationInitialDateInput}
                      handleInputChange={(event) => this.handleInputChange(event)}
                      value={this.state.expirationFinalDateInput}
                    />

                  </HalfWrapper>

                  <DefaultInput
                    name="lastServicePlanIdInput"
                    label="Último plano"
                    type="select"
                    handleInputChange={(event) => this.handleInputChange(event)}
                    value={this.state.lastServicePlanIdInput}
                    options={this.getServicePlanOptions()}
                  />

                </div>

                {this.mayResetFilterInputs() &&
                  <button
                    className="student-list__filters__reset-button"
                    onClick={() => this.resetFilterInputs()}
                  >

                    <i className="fas fa-times"></i>

                  </button>
                }

                {this.mayUpdateDateInputs() &&
                  <button
                    className="student-list__filters__refresh-button"
                    onClick={() => this.applyDateInputChanges()}
                  >

                    <i className="fas fa-sync"></i>

                  </button>
                }

              </div>

              {this.getInitialNameFilter()}

            </div>

            <HorizontalRule />

            <ModelTable
              properties={this.getProperties()}
              getActions={(entry) => this.getActions(entry)}
              data={this.getFilteredStudents()}
              initialOrderBy="last_contract_expiration_date"
              initialOrderIsDecrescent={true}
            >

              {this.props.userPermissionIds.includes(permissions.ADD_STUDENT_PERMISSION_ID) &&
                <Link
                  className="model-table__default-button"
                  to={{
                    pathname: routes.STUDENT_ADD_PATH,
                    state: { lastPath: this.props.location.pathname }
                  }}
                >

                  <i className="fas fa-plus"></i> Adicionar novo aluno

                </Link>
              }

            </ModelTable>

          </DefaultSection>

        </ContentFrame>

      </React.Fragment>
    );
  }
}

export default StudentList;
