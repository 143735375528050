import React from 'react';
import ContentFrame from '../content_frame';
import * as routes from '../../constants';
import {DEFAULT_UNKNOWN_ERROR_MESSAGE,
        PAYMENT_METHOD_CREDIT_CARD_ID,
        PAYMENT_METHOD_CREDIT_CARD_DEBT_ID,
        PAYMENT_METHOD_RECURRENT_ID,
        COST_CENTER_SERVICES_AND_PRODUCTS_ID,
        FINANCIAL_CATEGORY_REGISTRATION_FEE_ID
} from '../../constants';
import {postModel, getModels, getAsLocalDate, getLocalDateIsoString, setUrlParameters} from '../../utils/functions';
import FinancialMovementData from './financial_movement_data';

class FinancialMovementAdd extends React.Component {
  constructor(props) {
    super(props);

    let user_id = (new URLSearchParams(props.location.search)).get('student_id');

    if(user_id) {
      user_id = parseInt(user_id);
    }

    this.state = {
      financial_movement: {
        cost_center_id: '',
        financial_category_id: '',
        month_reference: '',
        description: '',
        payment_method_id: '',
        user_id: user_id || null,
        transactions: [],
        search_tag_ids: []
      },
      totalValue: '',
      costCenters: [],
      financialCategories: [],
      paymentMethods: [],
      financial_search_tags: [],
      availablePaymentDevices: [],
      sendToPaymentDevice: false,
      transactionCount: "",
      firstPaymentDate: "",
      highlights: [],
      warningMessage: "",
      showWarningMessage: false,
      loading: false,
    };
  }

  createTransaction(effective_date='', value='') {
    return {
      effective_date: effective_date,
      value: value,
      completed: false,
      nf_coupon_id: null
    };
  }

  async getFinancialSearchTags() {
    return await getModels(routes.FINANCIAL_SEARCH_TAGS_GET_API);
  }

  async componentDidMount() {
    let costCenters = getModels(routes.COST_CENTERS_GET_API);
    let financialCategories = getModels(routes.FINANCIAL_CATEGORIES_GET_API);
    let paymentMethods = getModels(routes.PAYMENT_METHODS_GET_API);
    let availablePaymentDevices = getModels(routes.PAYMENT_DEVICES_AVAILABLE_GET_API);
    let financial_search_tags = this.getFinancialSearchTags();

    const update = {
      loading: false
    }

    costCenters = await costCenters;

    if(costCenters) {
      update.costCenters = costCenters;
    }

    financialCategories = await financialCategories;

    if(financialCategories) {
      update.financialCategories = financialCategories;
    }

    paymentMethods = await paymentMethods;

    if(paymentMethods) {
      update.paymentMethods = paymentMethods;
      update.paymentMethods.sort((a, b) => a.name.localeCompare(b.name));
    }

    financial_search_tags = await financial_search_tags;

    if(financial_search_tags) {
      update.financial_search_tags = financial_search_tags;
      update.financial_search_tags.sort((a, b) => a.name.localeCompare(b.name));
    }

    availablePaymentDevices = await availablePaymentDevices;

    if(availablePaymentDevices) {
      update.availablePaymentDevices = availablePaymentDevices;
      update.availablePaymentDevices.sort((a, b) => a.name.localeCompare(b.name));
    }

    this.setState(update);
  }

  getTransactionsCopy(convertValues = false) {
    return this.state.financial_movement.transactions.map((transaction) => ({
      effective_date: transaction.effective_date,
      completed: transaction.completed,
      value: convertValues ? parseFloat(transaction.value) : transaction.value,
      nf_coupon_id: transaction.nf_coupon_id || null,
    }));
  }

  handleInputChange(event, updateState=true) {
    const target = event.target;
    let name = target.name;
    let value = target.type === 'checkbox' ? target.checked : target.value;

    if (name === 'sendToPaymentDevice') {
      this.setState({
        sendToPaymentDevice: value
      });

      return;
    }

    let ignoreFinancialMovementUpdate = false;

    const stateUpdate = {};
    const additionalUpdate = {};

    let transactionCount = this.state.transactionCount;
    let firstPaymentDate = this.state.firstPaymentDate;
    let totalValue = this.state.totalValue;

    if(name === 'cost_center_id') {
      additionalUpdate.financial_category_id = '';
    }

    if(name === 'payment_method_id' && value.length >= 0) { //this.state.firstPaymentDate.length <= 0
      additionalUpdate.payment_method_id = value;

      name = 'firstPaymentDate';

      let firstPaymentDate = new Date();

      if (parseInt(value) === PAYMENT_METHOD_CREDIT_CARD_ID || parseInt(value) === PAYMENT_METHOD_RECURRENT_ID) {
        firstPaymentDate.setDate(firstPaymentDate.getDate() + 30);
      }
      else if (parseInt(value) === PAYMENT_METHOD_CREDIT_CARD_DEBT_ID) {
        firstPaymentDate.setDate(firstPaymentDate.getDate() + 1);
      }

      value = getLocalDateIsoString(firstPaymentDate);
    }

    if(name.startsWith('transactions:')) {
      const selection = name.split(':');
      const transactionPosition = parseInt(selection[1]);

      name = 'transactions';

      const transactionsCopy = this.getTransactionsCopy();

      transactionsCopy[transactionPosition][selection[2]] = value;

      if(selection[2] === 'value') {
        stateUpdate.totalValue = transactionsCopy.reduce((sum, transaction) => sum + parseFloat(transaction.value), 0).toFixed(2);
      }

      value = transactionsCopy;
    }

    if(name === 'transactionCount' || name === 'firstPaymentDate' || name === 'totalValue') {
      ignoreFinancialMovementUpdate = true;
      stateUpdate[name] = value;

      if(value) {
        if(name === 'transactionCount' && this.state.transactionCount !== value) {
          transactionCount = value;
        }
        else if(name === 'firstPaymentDate' && this.state.firstPaymentDate !== value) {
          firstPaymentDate = value;
        }
        else if(name === 'totalValue' && this.state.totalValue !== value) {
          totalValue = value;
        }
      }

      if(transactionCount && firstPaymentDate && totalValue && totalValue > 0) {
        ignoreFinancialMovementUpdate = false;
        name = 'transactions';

        totalValue = parseFloat(totalValue);
        transactionCount = parseFloat(transactionCount);

        const transactions = [];

        const initialDate = getAsLocalDate(firstPaymentDate);

        let transactionValue = (Math.floor(totalValue/transactionCount * 100) / 100).toFixed(2);

        for(let i=0; i<transactionCount; i++) {
          transactions.push(this.createTransaction(getLocalDateIsoString(initialDate), transactionValue));
          initialDate.setMonth(initialDate.getMonth()+1);
        }

        value = transactions;
      }
    }

    let newState;

    if(ignoreFinancialMovementUpdate) {
      newState = {
        ...stateUpdate,
        financial_movement: {...this.state.financial_movement, ...additionalUpdate}
      };

      if (updateState) {
        this.setState(newState);
      }
    }
    else {
      const newData = {...this.state.financial_movement, ...additionalUpdate, [name]: value};

      newState = {
        ...stateUpdate,
        financial_movement: newData
      };

      if (updateState) {
        this.setState(newState);
      }
    }

    return newState;
  }

  inputsAreValid() {
    return (this.state.financial_movement.payment_method_id && this.state.financial_movement.payment_method_id > 0) &&
           (this.state.financial_movement.cost_center_id && this.state.financial_movement.cost_center_id > 0) &&
           (this.state.financial_movement.financial_category_id && this.state.financial_movement.financial_category_id > 0) &&
           this.state.financial_movement.transactions.length > 0 &&
           this.state.financial_movement.transactions.every((transaction) => (
             transaction.effective_date.length > 0 &&
             transaction.value > 0
           ));
  }

  maySendToPaymentDevice() {
    if (this.props.isExpense || this.state.availablePaymentDevices.length <= 0 || this.state.financial_movement.payment_method_id.length <= 0) {
      return false;
    }

    const paymentMethod = this.state.paymentMethods.find((entry) => entry.id === parseInt(this.state.financial_movement.payment_method_id));

    if (typeof paymentMethod === 'undefined') {
      return false;
    }

    return paymentMethod.has_payment_device_integration &&
          (paymentMethod.installments_may_apply ||
            (!paymentMethod.installments_may_apply && this.state.financial_movement.transactions.length === 1));
  }

  getPaymentMethodAlertMessage() {
    if (this.props.isExpense ||
        this.state.financial_movement.payment_method_id.length <= 0) {
      return null;
    }

    const paymentMethod = this.state.paymentMethods.find((entry) => entry.id === parseInt(this.state.financial_movement.payment_method_id));

    if (typeof paymentMethod === 'undefined') {
      return null;
    }

    if (paymentMethod.has_payment_device_integration && !paymentMethod.installments_may_apply) {
      return 'Envio para maquininha: somente para pagamentos à vista!';
    }

    return null;
  }

  async saveData() {
    if(parseInt(this.state.transactionCount) > 72) {
      this.setState({
        highlights: ['transactionCount'],
        showWarningMessage: true,
        warningMessage: "Número de parcelas maior que 72."
      });

      window.scrollTo(0, 0);

      return;
    }

    if (this.maySendToPaymentDevice() && this.state.sendToPaymentDevice) {
      let lastValue = null;

      let valueHighlights = []

      for (const transaction of this.state.financial_movement.transactions) {
        valueHighlights.push(`transactions:${valueHighlights.length}:value`);

        if (lastValue !== null && Math.abs(lastValue - transaction.value) > 0.01) {
          this.setState({
            highlights: valueHighlights,
            showWarningMessage: true,
            warningMessage: "Valor de parcelas devem ser iguais para envio de cobrança na maquininha."
          });

          window.scrollTo(0, 0);

          return;
        }

        lastValue = transaction.value;
      }
    }

    this.setState({
      highlights: [],
      showWarningMessage: false,
      loading: true
    });

    const data = {
      ...this.state.financial_movement,
      transactions: this.getTransactionsCopy(true)
    }
    data.cost_center_id = parseInt(data.cost_center_id);
    data.financial_category_id = parseInt(data.financial_category_id);
    data.payment_method_id = parseInt(data.payment_method_id);

    let response;

    try {
      response = await postModel(this.props.isExpense ? routes.EXPENSE_ADD_PATH : routes.INCOME_ADD_PATH, data, true);
    }
    catch(errors) {
      let warningMessages = [];
      let highlights = [];

      if(errors instanceof Array) {
        for(let error of errors) {
          switch (error.code) {
            // case 102:
            //   for(let parameter of error.parameters) {
            //     switch (parameter.name) {
            //       case 'period':
            //         warningMessages.push('Período deve ser maior que 0');
            //         highlights.push('period');
            //
            //         break;
            //       case 'value':
            //         warningMessages.push('Valor deve ser maior que 0');
            //         highlights.push('value');
            //
            //         break;
            //       default:
            //     }
            //   }
            //
            //   break;
            case 102:
              for(let parameter of error.parameters) {
                switch (parameter.name) {
                  case 'search_tag.type':
                    warningMessages.push(`Palavra-chave "${parameter.value}" não pode ser vinculada com um cadastro de ${this.props.isExpense ? 'despesa' : 'receita'}`);

                    break;
                  default:
                }
              }

              break;
            case 209:
              warningMessages = ['Sessão do usuário expirada'];

              break;
            default:
          }
        }
      }

      if (warningMessages.length <= 0) {
        warningMessages.push(DEFAULT_UNKNOWN_ERROR_MESSAGE);
      }

      this.setState({
        highlights: highlights,
        showWarningMessage: true,
        warningMessage: `${warningMessages.join('; ')}.`,
        loading: false
      });

      return false;
    }

    // this.props.history.replace(this.props.isExpense ? routes.EXPENSE_LIST_PATH : routes.INCOME_LIST_PATH);

    if (this.maySendToPaymentDevice() && this.state.sendToPaymentDevice) {
      this.props.history.replace(setUrlParameters(`${routes.INCOME_EDIT_PATH}${response.model_saved.id}`, {
        initiate_payment_process: true
      }));
    }
    else {
      this.props.history.goBack();
    }
  }

  onToggleSearchTag(tag_id) {
    const tag_ids = [...this.state.financial_movement.search_tag_ids];

    if(tag_ids.includes(tag_id)) {
      tag_ids.splice(tag_ids.indexOf(tag_id), 1);
    }
    else {
      tag_ids.push(tag_id);
    }

    const financial_movement = {...this.state.financial_movement, search_tag_ids: tag_ids};

    this.setState({financial_movement});
  }

  fillAsRegistrationFee() {
    const newState = this.handleInputChange({
      target: {
        name: 'totalValue',
        value: 69.9
      }
    }, false);

    const today = new Date();

    newState.financial_movement.cost_center_id = COST_CENTER_SERVICES_AND_PRODUCTS_ID;
    newState.financial_movement.financial_category_id = FINANCIAL_CATEGORY_REGISTRATION_FEE_ID;
    newState.financial_movement.month_reference = getLocalDateIsoString(today).slice(0, 7);
    newState.financial_movement.description = `${today.toLocaleDateString()}`;

    this.setState(newState);
  }

  render() {
    return (
      <ContentFrame
        location={this.props.location}
        headerHistory={[
          {
            path: routes.DESKTOP_PATH,
            text: "Área de trabalho"
          },
          {
            path: this.props.isExpense ? routes.EXPENSE_LIST_PATH : routes.INCOME_LIST_PATH,
            text: this.props.isExpense ? 'Listar despesas' : 'Listar receitas'
          },
          {
            path: this.props.isExpense ? routes.EXPENSE_ADD_PATH : routes.INCOME_ADD_PATH,
            text: this.props.isExpense ? 'Adicionar despesa' : 'Adicionar receita'
          },
        ]}
        titleIcon={<i className="fas fa-plus"></i>}
        title={this.props.isExpense ? 'Adicionar despesa' : 'Adicionar receita'}
        loading={this.state.loading}
      >

        <FinancialMovementData
          warningMessage={this.state.warningMessage}
          showWarningMessage={this.state.showWarningMessage}
          financial_movement={this.state.financial_movement}
          totalValue={this.state.totalValue}
          costCenters={this.state.costCenters}
          financialCategories={this.state.financialCategories}
          paymentMethods={this.state.paymentMethods}
          transactionCount={this.state.transactionCount}
          firstPaymentDate={this.state.firstPaymentDate}
          isExpense={this.props.isExpense}
          onSave={() => this.saveData()}
          onCloseWarning={() => {this.setState({highlights: [], showWarningMessage: false})}}
          enableSave={this.inputsAreValid()}
          handleInputChange={(event) => this.handleInputChange(event)}
          highlights={this.state.highlights}
          onCancelPath={this.props.isExpense ? routes.EXPENSE_LIST_PATH : routes.INCOME_LIST_PATH}
          history={this.props.history}
          userPermissionIds={this.props.userPermissionIds}
          userAccessLevel={this.props.userAccessLevel}
          maySendToPaymentDevice={() => this.maySendToPaymentDevice()}
          getPaymentMethodAlertMessage={() => this.getPaymentMethodAlertMessage()}
          sendToPaymentDevice={this.state.sendToPaymentDevice}
          financial_search_tags={this.state.financial_search_tags}
          availablePaymentDevices={this.state.availablePaymentDevices}
          showPaymentDeviceOverlay={false}
          onToggleSearchTag={(tag_id) => this.onToggleSearchTag(tag_id)}
          paymentReceived={null}
          fillAsRegistrationFee={() => this.fillAsRegistrationFee()}
        />

      </ContentFrame>
    );
  }
}

export default FinancialMovementAdd;
