import React from 'react';
import ContextPopup from '../components/context_popup';
import './default_input.scss';

function HalfWrapper(props) {
  return (
    <div className={`half-wrapper ${props.className || ''}`}>

      {props.children}

    </div>
  );
}

export {HalfWrapper};

function SelectOption(value, text=null) {
  return {
    value: value,
    text: text || value,
  };
}

export {SelectOption};

function DummyInput(props) {
  return (
    <div className={`dummy-input ${props.className}`}> </div>
  );
}

export {DummyInput};

function FiledSet(props) {
  return (
    <fieldset className={`default-fieldset ${props.className || ''}`}>

      <legend className={`default-fieldset__legend ${props.legendClassName || ''}`}>{props.legend}</legend>

      {props.children}

    </fieldset>
  );
}

export {FiledSet};

function getSelectOptions(options) {
  return options.map((option) => {
    return (
      <option key={`default_input_select_option_${option.value}`} value={option.value}>{option.text}</option>
    );
  });
}

class  DefaultInput extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      popupTarget: null
    };
  }

  render() {
    let inputElement;

    if(this.props.type === 'toggle') {
      let horizontalAlignClass = '';

      if(this.props.horizontalAlign === 'right') {
        horizontalAlignClass = 'align-right'
      }

      inputElement = (
        <div className={`default-input${this.props.isHorizontal ? '--horizontal': ''} ${this.props.className || ''} ${horizontalAlignClass}`}>

          {/* {this.props.label ?
            <h4
              className={`default-input__label${this.props.invertLabel ? '--inverted' : ''} ${this.props.labelClassName || ''}`}
              htmlFor={this.props.id || `default_input_${this.props.name}`}
            >
              {this.props.label}
            </h4> :
            null
          } */}
          {(this.props.label || this.props.labelMessage || this.props.explanationPopupContent) ?
            <label
              className={`default-input__label${this.props.invertLabel ? '--inverted' : ''} ${this.props.placeLabelMessageBellow ? 'default-input__label--bellow' : ''} ${this.props.labelClassName || ''}`}
              htmlFor={this.props.id || `default_input_${this.props.name}`}
            >

              <span className="default-input__label__text-wrapper">

                {this.props.label}

                {this.props.explanationPopupContent ?
                  <i
                    className="fa-solid fa-question default-input__question-icon"
                    onMouseEnter={(event) => this.setState({popupTarget: event.target})}
                    onMouseLeave={(event) => this.setState({popupTarget: null})}
                  >
                  </i> :
                  null
                }

              </span>


              {this.props.labelMessage ?
                <span
                  className={`default-input__label__warning-message${this.props.placeLabelMessageBellow ? '--bellow' : ''}`}
                  style={(this.props.labelColor || this.props.labelMessageWidth) ? {color: this.props.labelColor, width: this.props.labelMessageWidth} : {}}
                >
                  {this.props.labelMessage}
                </span>:
                null
              }

            </label> :
            null
          }

          {!this.props.hideInput &&
            <div className={`default-input__input-wrapper${this.props.invertLabel ? '--inverted' : ''}`}>

              <label>

                <input
                  className="default-input__toggle-input"
                  id={this.props.id || `default_input_${this.props.name}`}
                  name={this.props.name}
                  aria-hidden="false"
                  type="checkbox"
                  checked={this.props.value}
                  onChange={this.props.handleInputChange}
                  onBlur={this.props.handleOnBlur}
                  disabled={this.props.disabled}
                />
                <span
                  className={`default-input__toggle-visual${this.props.neutralInactiveColor ? '--inactive-neutral' : ''}`}
                  aria-hidden="true"
                  inactive-text={this.props.inactiveText}
                  active-text={this.props.activeText}
                  toggle-width="20em"
                  >

                    <span></span>

                  </span>

                </label>

              </div>
          }

        </div>
      );
    }
    else {
      inputElement = (
        <div className={`default-input${this.props.isHorizontal ? '--horizontal': ''} ${this.props.className || ''}`}>

          {(this.props.label || this.props.labelMessage || this.props.explanationPopupContent) ?
            <label
              className={`default-input__label${this.props.invertLabel ? '--inverted' : ''} ${this.props.placeLabelMessageBellow ? 'default-input__label--bellow' : ''} ${this.props.labelClassName || ''}`}
              htmlFor={this.props.id || `default_input_${this.props.name}`}
            >
              <span className="default-input__label__text-wrapper">

                {this.props.label}

                {this.props.explanationPopupContent ?
                  <i
                    className="fa-solid fa-question default-input__question-icon"
                    onMouseEnter={(event) => this.setState({popupTarget: event.target})}
                    onMouseLeave={(event) => this.setState({popupTarget: null})}
                  >
                  </i> :
                  null
                }

              </span>

              {this.props.labelMessage ?
                <span
                  className={`default-input__label__warning-message${this.props.placeLabelMessageBellow ? '--bellow' : ''}`}
                  style={(this.props.labelColor || this.props.labelMessageWidth) ? {color: this.props.labelColor, width: this.props.labelMessageWidth} : {}}
                >
                  {this.props.labelMessage}
                </span>:
                null
              }

            </label> :
            null
          }

          {!this.props.hideInput &&
            <div className={`default-input__input-wrapper${this.props.invertLabel ? '--inverted' : ''}`}>

              {this.props.type === 'textarea' ?
                <textarea
                  className={`default-input__text-area-input${this.props.isHighlighted ? '--highlighted' : ''}`}
                  wrap="soft"
                  id={this.props.id || `default_input_${this.props.name}`}
                  name={this.props.name}
                  placeholder={this.props.placeholder}
                  maxLength={this.props.maxLength}
                  onChange={this.props.handleInputChange}
                  onBlur={this.props.handleOnBlur}
                  value={this.props.value}
                  rows={this.props.rows}
                  onKeyDown={this.props.onKeyDown}
                  onFocus={this.props.onFocus}
                  disabled={this.props.disabled}
                /> :
                this.props.type === 'select' ?
                <select
                  className={`default-input__select-input${this.props.isHighlighted ? '--highlighted' : ''}`}
                  id={this.props.id || `default_input_${this.props.name}`}
                  name={this.props.name}
                  onChange={this.props.handleInputChange}
                  onBlur={this.props.handleOnBlur}
                  value={this.props.value}
                  disabled={this.props.disabled}
                >
                    {getSelectOptions(this.props.options)}
                </select> :
                <React.Fragment>

                  <input
                    className={`default-input__input${this.props.isHighlighted ? '--highlighted' : ''}`}
                    id={this.props.id || `default_input_${this.props.name}`}
                    name={this.props.name}
                    type={this.props.type}
                    placeholder={this.props.placeholder}
                    maxLength={this.props.maxLength}
                    min={this.props.min}
                    max={this.props.max}
                    step={this.props.step}
                    onChange={this.props.handleInputChange}
                    onBlur={this.props.handleOnBlur}
                    value={this.props.value}
                    autoComplete={this.props.autoComplete}
                    onKeyDown={this.props.onKeyDown}
                    onFocus={this.props.onFocus}
                    disabled={this.props.disabled}
                    checked={this.props.type === 'checkbox' && this.props.value}
                  />

                  {this.props.type === 'range' &&
                  <input
                    className={`default-input__input${this.props.isHighlighted ? '--highlighted' : ''}`}
                    id={this.props.id || `default_input_${this.props.name}`}
                    name={this.props.name}
                    type="number"
                    placeholder={this.props.placeholder}
                    maxLength={this.props.maxLength}
                    min={this.props.min}
                    max={this.props.max}
                    step={this.props.step}
                    onChange={this.props.handleInputChange}
                    onBlur={this.props.handleOnBlur}
                    value={this.props.value}
                    autoComplete={this.props.autoComplete}
                    onKeyDown={this.props.onKeyDown}
                    onFocus={this.props.onFocus}
                    disabled={this.props.disabled}
                  />
                }

              </React.Fragment>
            }

            {this.props.prefix ?
              <span className="default-input__input-prefix">{this.props.prefix}</span> :
              null
            }

            {this.props.suffix ?
              <span className="default-input__input-suffix">{this.props.suffix}</span> :
              null
            }

          </div>
          }

        </div>
      );
    }

    return (
      <React.Fragment>

        {inputElement}

        {this.props.explanationPopupContent &&
          <ContextPopup
            targetElement={this.state.popupTarget}
            content={this.props.explanationPopupContent}
          />
        }

      </React.Fragment>
    );
  }
}

export default DefaultInput;
