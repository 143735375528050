import React from 'react';
import { Link } from 'react-router-dom';
import * as routes from '../../constants';
import {EXPENSE_TYPE_NAME,
        INCOME_TYPE_NAME,
        BOTH_TYPE_NAME,
        DEFAULT_UNKNOWN_ERROR_MESSAGE} from '../../constants';
import ContentFrame from '../content_frame';
import {VerticalAccordionContainer} from '../../utils/pose_containers';
import {HorizontalRule} from '../../utils/default_section';
import {getModels, postModel, setUrlParameters, getAsLocalDate, getCurrencyText, getLocalDateIsoString} from '../../utils/functions';
import DefaultInput, {HalfWrapper, SelectOption} from '../../utils/default_input';
import ModelTable, {Property} from '../../utils/model_table';
import ConfirmationWindow from '../confirmation_window';
import OverlayWindow from '../../components/overlay_window';
import DefaultMenuButton from '../../components/default_menu_button';
import ContextPopup from '../../components/context_popup';
import './payment_report.scss';


class PaymentReport extends React.Component {
  constructor(props) {
    super(props);

    let queryParameters = (new URLSearchParams(props.location.search));

    let initialDueDate = queryParameters.get('initial_due_date');
    let finalDueDate = queryParameters.get('final_due_date');

    let createdAtInitialDate = queryParameters.get('created_at_initial_date');
    let createdAtFinalDate = queryParameters.get('created_at_final_date');

    let isPending = queryParameters.get('is_pending');
    let isExpense = queryParameters.get('is_expense');

    let searchTagIds = queryParameters.get('search_tag_ids');

    let paymentMethod = queryParameters.get('payment_method_id');
    let paymentDevice = queryParameters.get('payment_device_id');

    if(!initialDueDate) {
      initialDueDate = '';
    }
    if(!finalDueDate) {
      finalDueDate = '';
    }

    if(!createdAtInitialDate) {
      createdAtInitialDate = '';
    }
    if(!createdAtFinalDate) {
      createdAtFinalDate = '';
    }

    if(!isPending) {
      isPending = 'true';
    }

    if(!isExpense) {
      isExpense = '';
    }

    if(!paymentMethod) {
      paymentMethod = '';
    }

    if(!paymentDevice) {
      paymentDevice = '';
    }

    if(!searchTagIds) {
      searchTagIds = [];
    }
    else {
      searchTagIds = JSON.parse(searchTagIds);
    }

    this.state = {
      initialDueDateInput: initialDueDate,
      finalDueDateInput: finalDueDate,
      initialDueDate: initialDueDate,
      finalDueDate: finalDueDate,
      createdAtInitialDate,
      createdAtInitialDateInput: createdAtInitialDate,
      createdAtFinalDate,
      createdAtFinalDateInput: createdAtFinalDate,
      isPending,
      isPendingInput: isPending,
      isExpense,
      isExpenseInput: isExpense,
      paymentMethod,
      paymentMethodInput: paymentMethod,
      paymentDevice,
      paymentDeviceInput: paymentDevice,
      transactionsLoaded: false,
      financial_transactions: [],
      financial_search_tags: [],
      payment_methods: [],
      payment_devices: [],
      transactionSelectionSet: new Set(),
      applyNewEffective_date: false,
      effective_date: getLocalDateIsoString(new Date()),
      overlayIsLoading: false,
      search_tag_ids_input: searchTagIds,
      active_search_tag_ids: searchTagIds,
      mayConfirmPayment: false,
      searchTagFilter: "",
      searchTagsSectionVisible: false,
      loadingData: true,
      popupContent: null,
      popupTarget: null,
      confirmInProgress: false,
      confirmFailed: false,
      confirmFailDescription: "",
      screenWidth: window.innerWidth,
    };
  }

  async getFinancialSearchTags() {
    return await getModels(routes.FINANCIAL_SEARCH_TAGS_GET_API);
  }

  async getPaymentMethods() {
    return await getModels(routes.PAYMENT_METHODS_GET_API);
  }

  async getPaymentDevices() {
    return await getModels(routes.PAYMENT_DEVICES_GET_API);
  }

  async getFinancialTransactions() {
    const parameters = {
      initial_date: this.state.initialDueDate,
      final_date: this.state.finalDueDate,
      created_at_initial_date: this.state.createdAtInitialDate,
      created_at_final_date: this.state.createdAtFinalDate,
      pending: this.state.isPending,
      is_expense: this.state.isExpense,
      payment_method_id: this.state.paymentMethod,
      search_tag_ids: JSON.stringify(this.state.active_search_tag_ids),
      load_additional_info: true,
      load_description: true,
      load_search_tags: true,
      load_payment_method: true,
      load_installments_paid_count: true,
      load_payment_device_request: true
    };

    if(parameters.pending === 'all') {
      parameters.pending = null;
    }

    if (this.state.isExpense === 'false') {
      parameters.payment_device_id = this.state.paymentDevice;
    }

    return await getModels(setUrlParameters(routes.FINANCIAL_TRANSACTIONS_GET_API, parameters));
  }

  async refreshData(setLoading=true) {
    this.setState({loadingData: true});

    let financial_transactions = this.getFinancialTransactions();

    const update = {
      transactionsLoaded: true,
      transactionSelectionSet: new Set()
    }

    if(setLoading) {
      update.loadingData = false;
    }

    financial_transactions = await financial_transactions;

    if(financial_transactions) {
      update.financial_transactions = financial_transactions;
    }

    this.setState(update);
  }

  async componentDidMount() {
    let financial_search_tags = this.getFinancialSearchTags();
    let payment_methods = this.getPaymentMethods();
    let payment_devices = this.getPaymentDevices();

    if (this.state.isExpenseInput.length > 0) {
      await this.refreshData(false);
    }

    const update = {
      loadingData: false
    }

    financial_search_tags = await financial_search_tags;

    if(financial_search_tags) {
      update.financial_search_tags = financial_search_tags;
      update.financial_search_tags.sort((a, b) => a.name.localeCompare(b.name));
    }

    payment_methods = await payment_methods;

    if(payment_methods) {
      update.payment_methods = payment_methods;
      update.payment_methods.sort((a, b) => a.name.localeCompare(b.name));
    }

    payment_devices = await payment_devices;

    if(payment_devices) {
      update.payment_devices = payment_devices;
      update.payment_devices.sort((a, b) => a.name.localeCompare(b.name));
    }

    this.setState(update);

    this.resizeListener = () => this.updateSize();

    window.addEventListener("resize", this.resizeListener);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resizeListener);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.initialDueDate !== this.state.initialDueDate ||
        prevState.finalDueDate !== this.state.finalDueDate ||
        prevState.createdAtInitialDate !== this.state.createdAtInitialDate ||
        prevState.createdAtFinalDate !== this.state.createdAtFinalDate ||
        prevState.isPending !== this.state.isPending ||
        prevState.isExpense !== this.state.isExpense ||
        prevState.paymentMethod !== this.state.paymentMethod ||
        prevState.paymentDevice !== this.state.paymentDevice ||
        prevState.active_search_tag_ids !== this.state.active_search_tag_ids) {
      this.refreshData();
    }
  }

  updateSize() {
    this.setState({
      screenWidth: window.innerWidth
    });
  }

  handleInputChange(event) {
    const target = event.target;
    let value = target.type === 'checkbox' ? target.checked : target.value;
    let name = target.name;

    const update = {[name]: value};

    if (name === 'isExpenseInput') {
      const filteredTags = this.state.financial_search_tags.filter((tag) => {
        if (!this.state.search_tag_ids_input.includes(tag.id)) {
          return false;
        }

        let hasValidType = true;

        if (tag.type !== BOTH_TYPE_NAME) {
          hasValidType = (value === 'true' && tag.type === EXPENSE_TYPE_NAME) ||
                         (value !== 'true' && tag.type === INCOME_TYPE_NAME);
        }

        return hasValidType;
      });

      update.search_tag_ids_input = [...filteredTags.map((tag) => tag.id)];
    }

    this.setState(update);
  }

  searchTagInputHasChanged() {
    let difference = this.state.search_tag_ids_input.filter(x => !this.state.active_search_tag_ids.includes(x))

    difference = difference.concat(this.state.active_search_tag_ids.filter(x => !this.state.search_tag_ids_input.includes(x)));

    return difference.length > 0;
  }

  mayResetFilterInputs() {
    if(this.state.initialDueDateInput.length > 0 ||
       this.state.finalDueDateInput.length > 0 ||
       this.state.createdAtInitialDateInput.length > 0 ||
       this.state.createdAtFinalDateInput.length > 0 ||
       this.state.search_tag_ids_input.length > 0 ||
       this.state.paymentMethodInput.length > 0 ||
       this.state.paymentDeviceInput.length > 0) {
      return true;
    }

    return false;
  }

  resetFilterInputs() {
    this.setState({
      initialDueDateInput: '',
      finalDueDateInput: '',
      createdAtInitialDateInput: '',
      createdAtFinalDateInput: '',
      search_tag_ids_input: [],
      paymentMethodInput: '',
      paymentDeviceInput: '',
    });
  }

  mayUpdateDateInputs() {
    if(this.state.isExpenseInput.length > 0 &&
      (this.state.initialDueDateInput !== this.state.initialDueDate ||
       this.state.finalDueDateInput !== this.state.finalDueDate ||
       this.state.createdAtInitialDateInput !== this.state.createdAtInitialDate ||
       this.state.createdAtFinalDateInput !== this.state.createdAtFinalDate ||
       this.state.isPendingInput !== this.state.isPending ||
       this.state.isExpenseInput !== this.state.isExpense ||
       this.state.paymentMethodInput !== this.state.paymentMethod ||
       this.state.paymentDeviceInput !== this.state.paymentDevice ||
       this.searchTagInputHasChanged())) {
      return true;
    }

    return false;
  }

  applyDateInputChanges() {
    if(this.mayUpdateDateInputs()) {
      this.props.history.replace(setUrlParameters(routes.PAYMENT_REPORT_PAGE, {
        initial_due_date: this.state.initialDueDateInput,
        final_due_date: this.state.finalDueDateInput,
        created_at_initial_date: this.state.createdAtInitialDateInput,
        created_at_final_date: this.state.createdAtFinalDateInput,
        is_pending: this.state.isPendingInput,
        is_expense: this.state.isExpenseInput,
        payment_method_id: this.state.paymentMethodInput,
        payment_device_id: this.state.paymentDeviceInput,
        search_tag_ids: JSON.stringify(this.state.search_tag_ids_input)
      }));

      this.setState({
        initialDueDate: this.state.initialDueDateInput,
        finalDueDate: this.state.finalDueDateInput,
        createdAtInitialDate: this.state.createdAtInitialDateInput,
        createdAtFinalDate: this.state.createdAtFinalDateInput,
        isPending: this.state.isPendingInput,
        isExpense: this.state.isExpenseInput,
        paymentMethod: this.state.paymentMethodInput,
        paymentDevice: this.state.paymentDeviceInput,
        active_search_tag_ids: [...this.state.search_tag_ids_input]
      });
    }
  }

  getIsPendingOptions() {
    return [
      SelectOption('true', 'Não finalizado'),
      SelectOption('false', 'Finalizado'),
      SelectOption('all', 'Todos'),
    ];
  }

  getIsExpenseOptions() {
    const options = [];

    if (this.state.isExpenseInput.length <= 0) {
      options.push(SelectOption('', 'Selecione o tipo da conta'));
    }

    options.push(SelectOption('true', 'Despesa'));
    options.push(SelectOption('false', 'Receita'));

    return options;
  }

  getPaymentMethodOptions() {
    return [
      SelectOption('', 'Todos'),
      ...this.state.payment_methods.map((method) => SelectOption(method.id, method.name))
    ];
  }

  getPaymentDeviceOptions() {
    return [
      SelectOption('', 'Todas'),
      ...this.state.payment_devices.map((device) => SelectOption(device.id, device.name))
    ];
  }

  handleKeyDown(event) {
    if(event.keyCode === 13) {
      this.applyDateInputChanges();
    }
  }

  onConfirmPayment() {
    this.setState({mayConfirmPayment: true});
  }

  onToggleSearchTag(tag_id) {
    const tag_ids = [...this.state.search_tag_ids_input];

    if(tag_ids.includes(tag_id)) {
      tag_ids.splice(tag_ids.indexOf(tag_id), 1);
    }
    else {
      tag_ids.push(tag_id);
    }

    this.setState({search_tag_ids_input: tag_ids});
  }

  getSelectedSearchTags() {
    const filteredTags = this.state.financial_search_tags.filter((tag) => this.state.search_tag_ids_input.includes(tag.id));

    return filteredTags.map((tag) => (
      <div
        key={`payment_report:active_search_tag:${tag.id}`}
        className="payment-report__active-search-tag"
      >
        {tag.name}

        <button
          className="payment-report__active-search-tag__button"
          onClick={(event) => {
            this.onToggleSearchTag(tag.id);
            event.stopPropagation();
          }}
        >

          <i className="fa-solid fa-xmark"></i>

        </button>
      </div>
    ));
  }

  getSearchTagOptions() {
    if(!this.state.financial_search_tags || this.state.isExpenseInput.length <= 0) {
      return null;
    }

    const filteredTags = this.state.financial_search_tags.filter((tag) => {
      let hasValidType = true;

      if (tag.type !== BOTH_TYPE_NAME) {
        hasValidType = (this.state.isExpenseInput === 'true' && tag.type === EXPENSE_TYPE_NAME) ||
                       (this.state.isExpenseInput !== 'true' && tag.type === INCOME_TYPE_NAME);
      }

      return hasValidType && tag.name.toLocaleLowerCase().includes(this.state.searchTagFilter.toLocaleLowerCase());
    });

    if(filteredTags.length <= 0) {
      return null;
    }

    return filteredTags.map((tag) => {
      const selected = this.state.search_tag_ids_input.includes(tag.id);

      return (
        <div
          key={`payment_report:search_tag:${tag.id}`}
          className={`payment-report__search-tag-option${!selected ? '--disabled': ''}`}
        >

          <button
            className="payment-report__search-tag-option__check-button"
            onClick={() => this.onToggleSearchTag(tag.id)}
          >

            {selected &&
              <i className="fas fa-check"></i>
            }

          </button>

          <p className="payment-report__search-tag-option__text">

            {tag.name}

          </p>

        </div>
      );
    });
  }

  getEffectiveDateFilter(entry) {
    const today = getAsLocalDate((new Date()).toISOString().slice(0, 10));

    const date = getAsLocalDate(entry.effective_date);
    const timeDiff = Math.abs(today.getTime() - date.getTime());
    let daysPassed = (today > date ? -1 : 1)*Math.ceil(timeDiff / (1000 * 3600 * 24));

    let daysPassedText;

    if (!entry.is_canceled) {
      if (daysPassed !== 0) {
        daysPassedText = `${daysPassed} ${Math.abs(daysPassed) > 1 ? 'dias' : 'dia'}`;
      }
      else {
        daysPassedText = 'Hoje';
      }
    }
    else {
      daysPassedText = 'Cancelado';
    }

    return `${date.toLocaleDateString()} (${daysPassedText})`;
  }

  getEffectiveDateText(entry) {
    const today = getAsLocalDate((new Date()).toISOString().slice(0, 10));

    const date = getAsLocalDate(entry.effective_date);
    const timeDiff = Math.abs(today.getTime() - date.getTime());
    let daysPassed = (today > date ? -1 : 1)*Math.ceil(timeDiff / (1000 * 3600 * 24));

    let cellStyle ='';

    let additionalInfo;

    if (!entry.is_canceled) {
      if (entry.completed) {
        cellStyle = '--completed';
      }
      else if (daysPassed < 0) {
        cellStyle = '--past';
      }
      else if (daysPassed >= 0 && daysPassed < 5) {
        cellStyle = '--alert';
      }

      if (daysPassed !== 0) {
        additionalInfo = `${daysPassed} ${Math.abs(daysPassed) > 1 ? 'dias' : 'dia'}`;
      }
      else {
        additionalInfo = 'Hoje';
      }
    }
    else {
      additionalInfo = 'Cancelado';
    }

    return (
      <div className="payment-report__cell-wrapper">

        <p className={`payment-report__date-text${cellStyle}`}>

          {`${date.toLocaleDateString()}`}
          <br/>
          {`(${additionalInfo})`}

        </p>

      </div>
    );
  }

  getValueText(entry) {
    return (
      <p className="payment-report__property-content">

        {getCurrencyText(entry.value)}

        {(entry.entry_description || entry.search_tags) && (
          <i
            className={`fa-solid fa-circle-info payment-report__property-content__info-icon${entry.acquirer_transaction_key !== null ? ' payment-report__payment-request-info' : ''}`}
            onMouseEnter={(event) => this.onShowHoverdata(event.target, this.getDescriptoinText(entry))}
            onMouseLeave={(event) => this.onHideHoverdata()}
          >
          </i>
        )}

      </p>
    );
  }

  getValueFilter(entry) {
    return `${getCurrencyText(entry.value)} ${this.getDescriptoinFilter(entry)} ${entry.credit_card_brand} ${entry.last_credit_card_digits} ${entry.acquirer_transaction_key}`;
  }

  getInstallmentsPaidText(entry) {
    return `${entry.installments_paid} / ${entry.total_installments}`;
  }

  getDescriptoinText(entry) {
    return (
      <div className="payment-report__cell-container">

        {entry.acquirer_transaction_key !== null &&
          <React.Fragment>

            <p className="payment-report__payment-request-info__text">

              {entry.credit_card_brand !== null && entry.credit_card_brand}{entry.last_credit_card_digits !== null ? `(Terminado em ${entry.last_credit_card_digits})` : null}
              ID: {entry.acquirer_transaction_key}

            </p>

            <HorizontalRule />

          </React.Fragment>
        }

        <p className="payment-report__description">

          {entry.entry_description}

        </p>

        <p className="payment-report__search-tags">
          {entry.search_tags.map((tag) => (
            <span
              className="payment-report__search-tag"
              key={`financial_transaction:search_tag:${tag.id}`}
            >
              {tag.name}
            </span>
          ))}
        </p>

      </div>
    );
  }

  getDescriptoinFilter(entry) {
    return entry.entry_description + ` ${entry.search_tags.map((tag) => tag.name).join(' ')}`;
  }

  getClassificationText(entry) {
    return (
      <div className="payment-report__cell-container">

        <p><i className="fas fa-box"></i>{' '}{entry.cost_center_name}</p>
        <p><i className="fas fa-tags"></i>{' '}{entry.financial_category_name}</p>

      </div>
    );
  }

  getClassificationFilter(entry) {
    return `${entry.cost_center_name} ${entry.financial_category_name}`;
  }

  onSelectTransaction(entry) {
    const transactionSelectionSet = new Set(this.state.transactionSelectionSet);

    if (this.state.transactionSelectionSet.has(entry.id)) {
      transactionSelectionSet.delete(entry.id);
    }
    else {
      transactionSelectionSet.add(entry.id);
    }

    this.setState({transactionSelectionSet});
  }

  onToggleAllTransactionSelection() {
    let transactionSelectionSet;

    if (this.state.transactionSelectionSet.size > 0) {
      transactionSelectionSet = new Set();
    }
    else {
      transactionSelectionSet = new Set([...this.state.financial_transactions.filter((entry) => !entry.completed).map((entry) => entry.id)]);
    }

    this.setState({transactionSelectionSet});
  }

  getSelectedInput(entry) {
    if (entry.completed) {
      return null;
    }

    const isSelected = this.state.transactionSelectionSet.has(entry.id);

    return (
      <div className='payment-report__selected-input-container'>

        <button
          className="payment-report__select-checkbox"
          onClick={() => this.onSelectTransaction(entry)}
        >

          {isSelected &&
            <i className="fas fa-check"></i>
          }

        </button>

      </div>
    );
  }

  getSelectedFilter(entry) {
    return this.state.transactionSelectionSet.has(entry.id) ? 'Selecionado' : 'Não selecionado';
  }

  getTransactionProperties() {
    let properties = [
      Property('selected',
      (
        <div className='payment-report__selected-input-container'>

          <button
            className="payment-report__select-checkbox"
            onClick={() => this.onToggleAllTransactionSelection()}
          >

            {this.state.transactionSelectionSet.size > 0 &&
              <i className="fas fa-check"></i>
            }

          </button>

        </div>
      ),
      null,
      {
        getDataText: (entry) => this.getSelectedInput(entry),
        getFilterText: (entry) => this.getSelectedFilter(entry),
        sortable: false,
        headerClassName: 'payment-report__selected-input-header'
      }),
      Property('effective_date', 'Data do pagamento', <i className="fas fa-calendar-day"></i>, {
        getDataText: (entry) => this.getEffectiveDateText(entry),
        getFilterText: (entry) => this.getEffectiveDateFilter(entry),
      }),
      Property('value', 'Valor', <i className="fa-solid fa-dollar-sign"></i>, {
        getDataText: (entry) => this.getValueText(entry),
        getFilterText: (entry) => this.getValueFilter(entry),
        getSortCallback: (a, b) => {
          if (a.value === b.value) {
            return a.id - b.id;
          }

          return a.value - b.value;
        }
      }),
      Property('installments_paid', 'Parcelas pagas', <i className="fa-solid fa-dollar-sign"></i>, {
        getDataText: (entry) => this.getInstallmentsPaidText(entry),
        getFilterText: (entry) => this.getInstallmentsPaidText(entry),
        getSortCallback: (a, b) => {
          const aInstallmentsRemaining = a.total_installments - a.installments_paid;
          const bInstallmentsRemaining = b.total_installments - b.installments_paid;

          if (aInstallmentsRemaining === bInstallmentsRemaining) {
            if (a.effective_date === b.effective_date) {
              return a.id - b.id;
            }

            return a.effective_date.localeCompare(b.effective_date);
          }

          return aInstallmentsRemaining - bInstallmentsRemaining;
        }
      }),
      // Property('entry_description', 'Descrição / Tags', <i className="fas fa-info-circle"></i>, {
      //   getDataText: (entry) => this.getDescriptoinText(entry),
      //   getFilterText: (entry) => this.getDescriptoinFilter(entry),
      // }),
      Property('payment_method_name', 'Método de pagamento', <i className="fa-regular fa-credit-card"></i>),
      Property('tags', 'Centro / Categoria', <i className="fas fa-tag"></i>, {
        getDataText: (entry) => this.getClassificationText(entry),
        getFilterText: (entry) => this.getClassificationFilter(entry),
        sortable: false
      })
    ];

    return properties;
  }

  getTransactionActions(entry) {
    return (
      <div className="model-table__model-actions-container">

        <Link
          className="model-table__default-edit-button"
          to={`${this.state.isExpense === 'true' ? routes.EXPENSE_EDIT_PATH : routes.INCOME_EDIT_PATH}${entry.financial_entry_id}`}
        >

            <i className="fas fa-edit"></i>

        </Link>

      </div>
    );
  }

  getTransactionsContent() {
    if (!this.state.transactionsLoaded) {
      return null;
    }

    const totalValue = this.state.financial_transactions.reduce((sum, transaction) => sum + transaction.value, 0);
    const totalSelectedValue = this.state.financial_transactions.filter((entry) => this.state.transactionSelectionSet.has(entry.id)).reduce((sum, transaction) => sum + transaction.value, 0);
    const totalValuePaid = this.state.financial_transactions.filter((entry) => entry.completed).reduce((sum, transaction) => sum + transaction.value, 0);

    return (
      <React.Fragment>

      {this.state.financial_transactions.length > 0 &&
        <div className="payment-report__indicator-container--spaced">

          <div className="payment-report__indicator">

            <h2 className="payment-report__indicator__label">Valor total:</h2>
            <p className="payment-report__indicator__value">{getCurrencyText(totalValue)}</p>

          </div>

          <div className="payment-report__indicator">

            <h2 className="payment-report__indicator__label">Valor total selecionado:</h2>
            <p className="payment-report__indicator__value">{getCurrencyText(totalSelectedValue)} ({Math.round(10000*totalSelectedValue/totalValue)/100}%)</p>

          </div>

          <div className="payment-report__indicator">

            <h2 className="payment-report__indicator__label">Valor pago:</h2>
            <p className="payment-report__indicator__value">{getCurrencyText(totalValuePaid)} ({Math.round(10000*totalValuePaid/totalValue)/100}%)</p>

          </div>

        </div>
      }

        <ModelTable
          storageKey="manage_financial_transactions"
          properties={this.getTransactionProperties()}
          getActions={(entry) => this.getTransactionActions(entry)}
          // getRowClassName={(entry) => (entry.acquirer_transaction_key !== null) ? 'payment-report__transaction-entry__from_payment_device_request' : ''}
          data={this.state.financial_transactions}
          initialOrderBy="effective_date"
        >

          <button
            className="payment-report__action-button"
            disabled={this.state.transactionSelectionSet.size <= 0}
            onClick={(event) => this.onConfirmPayment()}
          >

            <i className="fa-solid fa-check"></i> Confirmar pagamento

          </button>
        </ModelTable>

      </React.Fragment>
    );
  }

  getConfirmationWindowTitle() {
    if(this.state.confirmInProgress) {
      return 'Confirmando pagamento';
    }
    else if(this.state.confirmFailed) {
      return 'Falha ao confirmando pagamento';
    }

    return 'Confirmar pagamento';
  }

  getConfirmationWindowDescription() {
    if(this.state.confirmFailed) {
      return this.state.confirmFailDescription;
    }

    return 'Todas as contas selecionadas serão marcadas como finalizadas.';
  }

  getConfirmartionWindowConfirmText() {
    return 'Confirmar';
  }

  onCancelConfirmation() {
    this.setState({
      mayConfirmPayment: false,
      confirmFailed: false
    });
  }

  async onAcceptConfirmation() {
    this.setState({
      confirmInProgress: true,
      overlayIsLoading: true
    });

    if(this.state.mayConfirmPayment) {
      const data = {
        financial_transaction_ids: [...this.state.transactionSelectionSet]
      };

      if (this.state.applyNewEffective_date && this.state.effective_date.length > 0) {
        data.effective_date = this.state.effective_date;
      }

      try{
        if (await postModel(routes.FINANCIAL_TRANSACTIONS_CONFIRM_POST_API, data)) {
          this.refreshData();
        }
      }
      catch(errors) {
        let errorDescription = DEFAULT_UNKNOWN_ERROR_MESSAGE;

        if(errors instanceof Array) {
          for(let error of errors) {
            switch (error.code) {
              case 209:
                errorDescription = 'Sessão do usuário expirada.';

                break;
              default:
            }
          }
        }

        this.setState({
          confirmFailDescription: errorDescription,
          confirmFailed: true,
          confirmInProgress: false,
          overlayIsLoading: false
        });

        return;
      }
    }

    this.setState({
      mayConfirmPayment: false,
      confirmFailed: false,
      confirmInProgress: false,
      overlayIsLoading: false
    });
  }

  getOverlayActions() {
    if (!this.state.mayConfirmPayment) {
      return null;
    }

    return (
      <React.Fragment>

        {!this.state.overlayIsLoading &&
          <DefaultMenuButton
            className="payment-report__overlay__action-button"
            onClick={() => this.setState({mayConfirmPayment: false})}
            text="Cancelar"
          />
        }

        <DefaultMenuButton
          className="payment-report__overlay__action-button"
          onClick={() => this.onAcceptConfirmation()}
          color="purple"
          text={this.state.overlayIsLoading ? 'Confirmando pagamento...' : 'Confirmar'}
          disabled={this.state.overlayIsLoading || (this.state.applyNewEffective_date && this.state.effective_date.length <= 0)}
        />

      </React.Fragment>
    );
  }

  getOverlayHeader() {
    if (!this.state.mayConfirmPayment) {
      return null;
    }

    return 'Concluir pagamento';
  }

  getOverlayContent() {
    if (!this.state.mayConfirmPayment) {
      return null;
    }

    return (
      <React.Fragment>

        <DefaultInput
          name="applyNewEffective_date"
          label="Alterar data efetiva para todos os pagamentos selecionados"
          type="checkbox"
          isHorizontal={true}
          invertLabel={true}
          handleInputChange={(event) => this.handleInputChange(event)}
          value={this.state.applyNewEffective_date}
        />

        {this.state.applyNewEffective_date &&
          <DefaultInput
            name="effective_date"
            isHighlighted={this.state.effective_date.length <= 0}
            label="Data do pagamento"
            type="date"
            placeholder="Data do pagamento"
            handleInputChange={(event) => this.handleInputChange(event)}
            value={this.state.effective_date}
          />
        }

      </React.Fragment>
    );
  }

  onShowHoverdata(target, text) {
    this.setState({
      popupContent: text,
      popupTarget: target,
    });
  }

  onHideHoverdata() {
    this.setState({
      popupContent: null,
      popupTarget: null,
    });
  }

  render() {
    return (
      <React.Fragment>

        <ContextPopup
          targetElement={this.state.popupTarget}
          content={this.state.popupContent}
        />

        <OverlayWindow
          className="payment-report__overlay"
          visible={this.state.mayConfirmPayment}
          loading={this.state.overlayIsLoading}
          actions={(
            <div className="payment-report__overlay__action-container">

              {this.getOverlayActions()}

            </div>
          )}
        >

          <header className="payment-report__overlay__header">

            <h3 className="payment-report__overlay__header__title">
              {this.getOverlayHeader()}
            </h3>

          </header>

          <hr className="payment-report__horizontal-rule" />

          <div className="payment-report__overlay__content">

            {this.getOverlayContent()}

          </div>

        </OverlayWindow>

        <ConfirmationWindow
          title={this.getConfirmationWindowTitle()}
          description={this.getConfirmationWindowDescription()}
          confirmText={this.getConfirmartionWindowConfirmText()}
          cancelText={this.state.confirmFailed ? 'Ok' : 'Cancelar'}
          visible={this.state.confirmFailed}
          onCancel={() => this.onCancelConfirmation()}
          onConfirm={() => this.onAcceptConfirmation()}
          loading={this.state.confirmInProgress}
          useErrorIcon={this.state.confirmFailed}
          hideConfirmButton={this.state.confirmFailed}
        />

        <ContentFrame
          location={this.props.location}
          headerHistory={[
            {
              path: routes.DESKTOP_PATH,
              text: "Área de trabalho"
            },
            {
              path: routes.PAYMENT_REPORT_PAGE,
              text: "Relatório de pagamentos"
            },
          ]}
          titleIcon={<i className="fa-solid fa-list-ul"></i>}
          title="Relatório de pagamentos"
          loading={this.state.loadingData}
        >

          <div className="payment-report__wrapper">

            <div className="payment-report__filters">

              <h3 className="payment-report__filters__title">Filtros</h3>

              <div className="payment-report__filters__inputs-container">

                <div className="payment-report__filters__inputs-wrapper">

                  <HalfWrapper>

                    <DefaultInput
                      name="isExpenseInput"
                      isHighlighted={this.state.isExpenseInput.length <= 0}
                      label="Tipo da conta"
                      type="select"
                      handleInputChange={(event) => this.handleInputChange(event)}
                      value={this.state.isExpenseInput || ''}
                      options={this.getIsExpenseOptions()}
                    />

                    <DefaultInput
                      name="isPendingInput"
                      label="Situação"
                      type="select"
                      handleInputChange={(event) => this.handleInputChange(event)}
                      value={this.state.isPendingInput || ''}
                      options={this.getIsPendingOptions()}
                    />

                  </HalfWrapper>

                  <HalfWrapper>

                    <DefaultInput
                      name="paymentMethodInput"
                      label="Método de pagamento"
                      type="select"
                      handleInputChange={(event) => this.handleInputChange(event)}
                      value={this.state.paymentMethodInput || ''}
                      options={this.getPaymentMethodOptions()}
                    />

                    <DefaultInput
                      name="paymentDeviceInput"
                      label="Máquina de cartão"
                      type="select"
                      handleInputChange={(event) => this.handleInputChange(event)}
                      value={this.state.paymentDeviceInput || ''}
                      options={this.getPaymentDeviceOptions()}
                    />

                  </HalfWrapper>

                  <HalfWrapper>

                    <DefaultInput
                      name="initialDueDateInput"
                      // isHighlighted={this.state.initialDueDateInput > this.state.finalDueDateInput}
                      label="Data inicial do pagamento"
                      type="date"
                      placeholder="Data inicial"
                      max={this.state.finalDueDateInput}
                      handleInputChange={(event) => this.handleInputChange(event)}
                      value={this.state.initialDueDateInput}
                      onKeyDown={(event) => this.handleKeyDown(event)}
                    />

                    <DefaultInput
                      name="finalDueDateInput"
                      // isHighlighted={this.state.initialDueDateInput > this.state.finalDueDateInput}
                      label="Data final do pagamento"
                      type="date"
                      placeholder="Data final"
                      min={this.state.initialDueDateInput}
                      handleInputChange={(event) => this.handleInputChange(event)}
                      value={this.state.finalDueDateInput}
                      onKeyDown={(event) => this.handleKeyDown(event)}
                    />

                  </HalfWrapper>

                  <HalfWrapper>

                    <DefaultInput
                      name="createdAtInitialDateInput"
                      // isHighlighted={this.state.createdAtInitialDateInput > this.state.createdAtFinalDateInput}
                      label="Data inicial do cadastro"
                      type="date"
                      placeholder="Data inicial"
                      max={this.state.createdAtFinalDateInput}
                      handleInputChange={(event) => this.handleInputChange(event)}
                      value={this.state.createdAtInitialDateInput}
                    />

                    <DefaultInput
                      name="createdAtFinalDateInput"
                      // isHighlighted={this.state.createdAtInitialDateInput > this.state.createdAtFinalDateInput}
                      label="Data final do cadastro"
                      type="date"
                      placeholder="Data final"
                      min={this.state.createdAtInitialDateInput}
                      handleInputChange={(event) => this.handleInputChange(event)}
                      value={this.state.createdAtFinalDateInput}
                    />

                  </HalfWrapper>

                  <section className="payment-report__default-section">

                    <header
                      className="payment-report__default-section__header"
                      onClick={() => this.setState({searchTagsSectionVisible: !this.state.searchTagsSectionVisible})}
                    >

                      <h3 className="payment-report__default-section__header__text">

                        <div className="payment-report__default-section__header__text-wrapper">
                          <i className="fa-solid fa-tags payment-report__default-section__header__text-icon"></i>
                          Palavras-chave:
                        </div>
                        <div className="payment-report__active-search-tag__wrapper">
                          {this.getSelectedSearchTags()}
                        </div>

                      </h3>

                      {this.state.searchTagsSectionVisible ?
                        <i className="fas fa-chevron-down payment-report__default-section__header__visible-icon"></i>:
                        <i className="fas fa-chevron-up payment-report__default-section__header__visible-icon"></i>
                      }

                    </header>

                    <VerticalAccordionContainer
                      className="vertical-accordion-container payment-report__default-section__content"
                      pose={this.state.searchTagsSectionVisible ? 'verticalOpen' : 'verticalClosed'}
                    >

                      <div className="vertical-accordion-container payment-report__default-section__content-wrapper">

                        <div className="payment-report__default-section-container">

                          <DefaultInput
                            name="searchTagFilter"
                            label="Busca rápida:"
                            type="text"
                            handleInputChange={(event) => this.handleInputChange(event)}
                            value={this.state.searchTagFilter}
                            autoComplete="off"
                            disabled={this.state.confirmInProgress}
                          />

                          <div className="payment-report__search-tag-options-container">

                            {this.getSearchTagOptions()}

                          </div>

                        </div>

                      </div>

                    </VerticalAccordionContainer>

                  </section>

                </div>

                {this.mayResetFilterInputs() &&
                  <button
                    className="payment-report__filters__reset-button"
                    onClick={() => this.resetFilterInputs()}
                  >

                    <i className="fas fa-times"></i>

                  </button>
                }

                <button
                  className="payment-report__filters__refresh-button"
                  onClick={() => this.applyDateInputChanges()}
                  disabled={!this.mayUpdateDateInputs()}
                >

                  <i className="fas fa-sync"></i>

                </button>
              </div>

            </div>

            <HorizontalRule />

            {this.getTransactionsContent()}

          </div>

        </ContentFrame>

      </React.Fragment>
    );
  }
}

export default PaymentReport;
