import React from 'react';
import { PoseGroup } from 'react-pose';
import { Link } from 'react-router-dom';
import './service_data.scss';
import DefaultSection, {HorizontalRule, DefaultSubSectionTitle} from '../../utils/default_section';
import DefaultInput, {SelectOption, HalfWrapper} from '../../utils/default_input';
import WarningMessage from '../warning_message';
import {HorizontalContainer} from '../../utils/pose_containers';
import {DEFAULT_UNIT_TYPE, SERVICE_COLOR_MAP} from '../../constants';

class ServiceData extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedDay: 0,
    };
  }

  handleKeyDown(event) {
    if(event.keyCode === 13 && this.props.enableSave && event.target.name !== 'description') {
      this.props.onSave();
    }
  }

  isHighlighted(propertyName) {
    return this.props.highlights.includes(propertyName);
  }

  getServiceSelectors() {
    return this.props.services.map((service) => {
      const serviceSelected = service === this.props.activeService;

      return (
        <button
          key={`service_plan:service_selector:${service.toLowerCase().replace(/ /g, '_')}`}
          className={`service-data__service-button`}
          disabled={serviceSelected}
          onClick={() => this.props.onSelectService(service)}
        >

          {service}

        </button>
      );
    });
  }

  getDays() {
    if(!this.props.activeService) {
      return;
    }

    const weekdayMap = [
      'segunda-feira',
      'terça-feira',
      'quarta-feira',
      'quinta-feira',
      'sexta-feira',
      'sábado',
      'domingo',
    ];

    return weekdayMap.map((day, dayId) => {
      return (
        <React.Fragment key={`service_data:day:${dayId}`}>

          <input
            type="radio"
            id={`service_data:day:${dayId}`}
            name="day"
            value={dayId}
            onChange={() => this.setState({selectedDay: dayId})}
            className="service-data__days__input"
            checked={dayId === this.state.selectedDay}
          />

          <label
            htmlFor={`service_data:day:${dayId}`}
            className="service-data__days__label"
            dayid={dayId}
          >

            <span>{day}</span>

          </label>

        </React.Fragment>
      );
    });
  }

  getHours() {
    if(!this.props.activeService) {
      return;
    }

    let selectedDayIds;
    const serviceMap = this.props.restriction_map[this.props.activeService];

    if(serviceMap) {
      selectedDayIds = serviceMap.time_ids;
    }
    else {
      selectedDayIds = [];
    }

    const hours = this.props.training_times.filter((entry) => entry.day_id === this.state.selectedDay && entry.target_service === this.props.activeService);

    if(hours.length <= 0) {
      return null;
    }

    const disabled = this.enableAllTimes();

    return hours.map((entry) => {
      return (
        <HorizontalContainer
          key={`service_data:hour:${entry.id}`}
          className={`service-data__hour${disabled ? '--disabled': ''}`}
        >

          <p className="service-data__hour__text">

            {entry.time}

          </p>

          <button
            className="service-data__hour__select-time-button"
            onClick={() => this.props.onClickTime(entry.id)}
            disabled={this.enableAllTimes()}
          >

            {(disabled || selectedDayIds.includes(entry.id)) &&
              <i className="fas fa-check"></i>
            }

          </button>

        </HorizontalContainer>
      );
    });
  }

  enableAllTimes() {
    return this.props.restriction_map[this.props.activeService] ? this.props.restriction_map[this.props.activeService].enable_all : false;
  }

  isDefaultUnit() {
    return this.props.unit_type_id === DEFAULT_UNIT_TYPE;
  }

  getWebsiteColorOptions() {
    const options = [
      ...Object.entries(SERVICE_COLOR_MAP).map(([key, value]) => SelectOption(value, key))
    ];

    options.sort((a, b) => a.text.localeCompare(b.text));

    return options;
  }

  getServiceGroupOptions() {
    return [
      SelectOption('', 'Não vinculado'),
      ...this.props.service_groups.map((serviceGroup) => SelectOption(serviceGroup.id, serviceGroup.name))
    ];
  }

  getServiceGroupLabelMessage() {
    if (this.props.service.service_group_id) {
      const service_group = this.props.service_groups.find((entry) => entry.id === parseInt(this.props.service.service_group_id));

      if (service_group && !service_group.is_active) {
        return 'Agrupamento inativo: o serviço não será exibido no site!';
      }
    }

    return null;
  }

  render() {
    return (
      <DefaultSection
        className="service-data"
        title="Dados do serviço"
      >

        <div className="service-data__warning-container">

          <WarningMessage
            message={this.props.warningMessage}
            onClose={this.props.onCloseWarning}
            visible={this.props.showWarningMessage}
          />

        </div>

        <div className="service-data__input-container">

          <DefaultInput
            name="is_active"
            label="Ativo:"
            type="toggle"
            isHorizontal={true}
            activeText="Sim"
            inactiveText="Não"
            handleInputChange={this.props.handleInputChange}
            value={this.props.service.is_active}
            horizontalAlign="right"
          />

          <DefaultInput
            name="name"
            isHighlighted={this.isHighlighted("name")}
            label="Nome"
            type="text"
            placeholder="Nome do serviço"
            maxLength="128"
            handleInputChange={this.props.handleInputChange}
            value={this.props.service.name}
            autoComplete="off"
            onKeyDown={(event) => this.handleKeyDown(event)}
          />

          <HalfWrapper>

            <DefaultInput
              name="code"
              isHighlighted={this.isHighlighted("code")}
              label="Código do serviço"
              type="text"
              placeholder="Código do serviço"
              maxLength="32"
              handleInputChange={this.props.handleInputChange}
              value={this.props.service.code || ''}
              autoComplete="off"
              onKeyDown={(event) => this.handleKeyDown(event)}
            />

            <DefaultInput
              name="period"
              isHighlighted={this.isHighlighted("period")}
              label="Período"
              type="number"
              placeholder="Duração do serviço em dias"
              min="0"
              step="1"
              handleInputChange={this.props.handleInputChange}
              value={this.props.service.period}
              autoComplete="off"
              suffix="dias"
              onKeyDown={(event) => this.handleKeyDown(event)}
              onFocus={(event) => event.target.select()}
              labelMessage={this.isDefaultUnit() ? 'Utilizar múltiplo de 30 caso seja exibido no site.' : null}
            />

          </HalfWrapper>

          <HalfWrapper>

            <DefaultInput
              name="value"
              isHighlighted={this.isHighlighted("value")}
              label="Valor"
              type="number"
              placeholder="Preço de contratação"
              min="0.00"
              step="0.01"
              handleInputChange={this.props.handleInputChange}
              value={this.props.service.value}
              autoComplete="off"
              prefix="R$"
              onKeyDown={(event) => this.handleKeyDown(event)}
              onFocus={(event) => event.target.select()}
            />

            {/* <DefaultInput
              name="penalty_value"
              isHighlighted={this.isHighlighted("penalty_value")}
              label="Valor de referência da multa"
              type="number"
              placeholder="Referência da multa"
              min="0.00"
              step="0.01"
              handleInputChange={this.props.handleInputChange}
              value={this.props.service.penalty_value}
              autoComplete="off"
              prefix="R$"
              onKeyDown={(event) => this.handleKeyDown(event)}
              onFocus={(event) => event.target.select()}
            /> */}

          </HalfWrapper>

          {this.isDefaultUnit() &&
            <DefaultInput
              name="allowed_discount_percentage"
              isHighlighted={this.isHighlighted("allowed_discount_percentage")}
              label="Desconto máximo possível"
              type="range"
              placeholder="Porcentagem"
              min="0.0"
              max="100.0"
              step="1"
              handleInputChange={this.props.handleInputChange}
              value={this.props.service.allowed_discount_percentage ? Math.round(this.props.service.allowed_discount_percentage * 100) : 0}
              autoComplete="off"
              suffix="%"
              onKeyDown={(event) => this.handleKeyDown(event)}
              onFocus={(event) => event.target.select()}
            />
          }

          <HalfWrapper>

            {this.isDefaultUnit() &&
              <DefaultInput
                name="allows_food_prescription"
                label="Habilitar acompanhamento nutricional:"
                type="toggle"
                // isHorizontal={true}
                activeText="Sim"
                inactiveText="Não"
                handleInputChange={this.props.handleInputChange}
                value={this.props.service.allows_food_prescription}
              />
            }

            <DefaultInput
              name="requires_personal_period"
              label="Requer periodização de personal:"
              type="toggle"
              // isHorizontal={true}
              activeText="Sim"
              inactiveText="Não"
              handleInputChange={this.props.handleInputChange}
              value={this.props.service.requires_personal_period}
            />

            {this.isDefaultUnit() &&
              <DefaultInput
                name="has_challenge_access"
                label="Habilitar desafio FYD:"
                type="toggle"
                // isHorizontal={true}
                activeText="Sim"
                inactiveText="Não"
                handleInputChange={this.props.handleInputChange}
                value={this.props.service.has_challenge_access}
              />
            }

          </HalfWrapper>

          <DefaultInput
            name="description"
            isHighlighted={this.isHighlighted("description")}
            label="Descrição"
            type="textarea"
            placeholder="Descrição do serviço"
            rows="6"
            handleInputChange={this.props.handleInputChange}
            value={this.props.service.description || ''}
            onKeyDown={(event) => this.handleKeyDown(event)}
          />

          {this.isDefaultUnit() &&
            <React.Fragment>

            <HalfWrapper>

              <DefaultInput
                name="send_expiration_notification"
                label="Notificação sobre vencimento:"
                labelMessage="Um email será enviado ao aluno quando seu contrato estiver próximo do vencimento"
                type="toggle"
                // isHorizontal={true}
                activeText="Sim"
                inactiveText="Não"
                handleInputChange={this.props.handleInputChange}
                value={this.props.service.send_expiration_notification}
              />

              <DefaultInput
                name="show_in_website"
                label="Exibir no site:"
                type="toggle"
                // isHorizontal={true}
                activeText="Sim"
                inactiveText="Não"
                handleInputChange={this.props.handleInputChange}
                value={this.props.service.show_in_website}
              />

            </HalfWrapper>

              {this.props.service.show_in_website &&
                <React.Fragment>

                  <DefaultInput
                    name="service_group_id"
                    isHighlighted={this.isHighlighted("service_group_id")}
                    label="Agrupamento"
                    labelMessage={this.getServiceGroupLabelMessage()}
                    type="select"
                    handleInputChange={this.props.handleInputChange}
                    value={this.props.service.service_group_id || ''}
                    options={this.getServiceGroupOptions()}
                  />

                  {(!this.props.service.service_group_id) &&
                    <div className="service-data__website-color__color-input-container">

                      <div
                        className="service-data__website-color__color-preview"
                        style={{background: this.props.service.color || SERVICE_COLOR_MAP['Vermelho']}}
                      >
                      </div>

                      <DefaultInput
                        name="color"
                        isHighlighted={this.isHighlighted("color")}
                        label="Cor no site"
                        type="select"
                        handleInputChange={this.props.handleInputChange}
                        value={this.props.service.color || SERVICE_COLOR_MAP['Vermelho']}
                        options={this.getWebsiteColorOptions()}
                      />

                    </div>
                  }

                </React.Fragment>
              }

            </React.Fragment>
          }

        </div>

        {this.isDefaultUnit() &&
          <div>

            <DefaultSubSectionTitle
              icon={<i className="fas fa-clock"></i>}
              text="Configuração de horários"
            />

            {/* <DefaultSubSectionTitle
              className="service-data__service-selector-header"
              icon={<i className="fas fa-list"></i>}
              text="Selecione o serviço que deseja configurar"
            /> */}

            <div className="service-data__service-selector">

              {this.getServiceSelectors()}

            </div>

            <HorizontalRule />

            <h3 className="service-data__instructions">Marque os horários liberados para treino:</h3>

            <DefaultInput
              name="enable_all"
              label="Selecionar todos:"
              type="toggle"
              isHorizontal={true}
              activeText="Sim"
              inactiveText="Não"
              handleInputChange={this.props.handleInputChange}
              value={this.enableAllTimes()}
            />

            <HorizontalRule />

            <div className="service-data__content-wrapper">

              <div className="service-data__days">

                <h3 className="service-data__days-title">Dias</h3>

                <div className="service-data__days__wrapper">

                  {this.getDays()}

                </div>


              </div>

              <div className="service-data__hour-manager">

                <h3 className="service-data__hour-manager-title">Horários</h3>

                <div className="service-data__hours">

                  <PoseGroup flipMove={false}>

                    {this.getHours()}

                  </PoseGroup>

                </div>

              </div>

            </div>

          </div>
        }

        <HorizontalRule />

        <div className="service-data__buttons-container">

          <button
            className="service-data__save-button"
            disabled={!this.props.enableSave}
            onClick={this.props.onSave}
          >

            Salvar

          </button>

          <Link
            className="service-data__cancel-button"
            to={this.props.onCancelPath}
          >

            Cancelar

          </Link>

        </div>

      </DefaultSection>
    );
  }
}

export default ServiceData;
