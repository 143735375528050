import React from 'react';
import { PoseGroup } from 'react-pose';
// import { Link } from 'react-router-dom';
import {VerticalAccordionContainer} from '../../../utils/pose_containers';
import LoadingIcon from '../../../components/loading_icon'
import {DEFAULT_UNIT_TYPE,
        DEFAULT_UNKNOWN_ERROR_MESSAGE,
        BOTH_TYPE_NAME,
        INCOME_TYPE_NAME} from '../../../constants';
import * as routes from '../../../constants';
import {HorizontalRule} from '../../../utils/default_section';
import DefaultInput, {HalfWrapper, SelectOption} from '../../../utils/default_input';
import WarningMessage from '../../warning_message';
import {HorizontalContainer} from '../../../utils/pose_containers';
import OverlayWindow from '../../../components/overlay_window';
import DefaultMenuButton from '../../../components/default_menu_button';
import ConfirmationWindow from '../../confirmation_window';
import {patchModel, getContractPaymentIndex, getAsLocalDatetime} from '../../../utils/functions';
import * as permissions from '../../../permissions';
import './contract_data.scss';

class ContractData extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchTagFilter: "",
      searchTagsSectionVisible: false,
      loadingPromotionalCoupon: false,
      promotional_coupon_code: '',
      overlayIsLoading: false,
      onCancelPaymentDeviceRequest: false,
      confirmInProgress: false,
      confirmFailed: false,
      confirmFailDescription: "",
      screenWidth: window.innerWidth,
      selectedDay: 0,
      discountFieldMessage: null,
      discountFieldMessageVisible: false,
    };
  }

  componentDidMount() {
    this.resizeListener = () => this.updateSize();

    window.addEventListener("resize", this.resizeListener);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resizeListener);
  }

  updateSize() {
    this.setState({
      screenWidth: window.innerWidth
    });
  }

  handleKeyDown(event) {
    if(event.keyCode === 13 && this.props.enableSave) {
      this.props.onSave();
    }
  }

  handleInputChange(event) {
    const target = event.target;
    let value = target.type === 'checkbox' ? target.checked : target.value;
    let name = target.name;

    if(name === 'promotional_coupon_code') {
      value = value.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLocaleUpperCase().replace(' ', '_');
    }

    const update = {[name]: value};

    this.setState(update);
  }

  isHighlighted(propertyName) {
    return this.props.highlights.includes(propertyName);
  }

  isEditMode() {
    return this.props.contract.id && this.props.contract.id > 0;
  }

  isDefaultUnit() {
    return this.props.unit_type_id === DEFAULT_UNIT_TYPE;
  }

  contractAccepted() {
    return this.props.contract.accepted;
  }

  hasEditPermission() {
    return this.props.userPermissionIds.includes(permissions.EDIT_CONTRACT_PERMISSION_ID);
  }

  getServiceOptions() {
    if(this.isEditMode()) {
      return [
        ...this.props.services.map((service) => SelectOption(service.id, service.name))
      ];
    }

    return [
      SelectOption('', 'Selecione um serviço'),
      ...this.props.services.map((service) => SelectOption(service.id, service.name))
    ];
  }

  getPaymentMethodOptions() {
    return [
      SelectOption('', 'Selecione uma forma de pagamento'),
      ...this.props.paymentMethods.map((method) => SelectOption(method.id, method.name))
    ];
  }

  getAdditionalPayment() {
    if(this.props.contract.payments.length > 1) {
      const firstPaymentIndex = getContractPaymentIndex(this.props.contract.payments, false);

      return (
        <React.Fragment>

          <div className="contract-data__sub-section">

            <DefaultInput
              name={`payments:${firstPaymentIndex}:payment_method_id`}
              isHighlighted={this.isHighlighted(`payments:${firstPaymentIndex}:payment_method_id`)}
              label="Forma de pagamento"
              type="select"
              handleInputChange={this.props.handleInputChange}
              value={this.props.contract.payments[firstPaymentIndex].payment_method_id || ''}
              options={this.getPaymentMethodOptions()}
              disabled={(this.isEditMode() && !this.hasEditPermission()) || (this.contractAccepted() && this.props.blockTransactionStatus)}
            />

            <HalfWrapper>

              <DefaultInput
                name={`payments:${firstPaymentIndex}:transactions:0:value`}
                isHighlighted={this.isHighlighted(`payments:${firstPaymentIndex}:transactions:0:value`)}
                label="Valor"
                type="number"
                placeholder="Valor da entrada"
                min="0.00"
                step="0.01"
                handleInputChange={this.props.handleInputChange}
                value={this.props.contract.payments[firstPaymentIndex].transactions[0].value}
                autoComplete="off"
                prefix="R$"
                onKeyDown={(event) => this.handleKeyDown(event)}
                onFocus={(event) => event.target.select()}
                disabled={(this.isEditMode() && !this.hasEditPermission()) || (this.contractAccepted() && this.props.blockTransactionStatus) || this.props.nf_coupon_generated}
              />

              <DefaultInput
                name={`payments:${firstPaymentIndex}:transactions:0:effective_date`}
                isHighlighted={this.isHighlighted(`payments:${firstPaymentIndex}:transactions:0:effective_date`)}
                label="Data de pagamento"
                type="date"
                placeholder="Data de início do contrato"
                handleInputChange={this.props.handleInputChange}
                value={this.props.contract.payments[firstPaymentIndex].transactions[0].effective_date}
                onKeyDown={(event) => this.handleKeyDown(event)}
                disabled={(this.isEditMode() && !this.hasEditPermission()) || (this.contractAccepted() && this.props.blockTransactionStatus)}
              />

            </HalfWrapper>

            {!this.props.blockTransactionStatus &&
              <DefaultInput
                name={`payments:${firstPaymentIndex}:transactions:0:completed`}
                isHighlighted={this.isHighlighted(`payments:${firstPaymentIndex}:transactions:0:completed`)}
                label="Pago:"
                type="toggle"
                activeText="Sim"
                inactiveText="Não"
                isHorizontal={true}
                invertLabel={false}
                handleInputChange={this.props.handleInputChange}
                value={this.props.contract.payments[firstPaymentIndex].transactions[0].completed}
              />
            }

          </div>

        </React.Fragment>
      );
    }
  }

  getMainTransactions() {
    const paymentIndex = getContractPaymentIndex(this.props.contract.payments);
    const mainPayment = this.props.contract.payments[paymentIndex];

    if(mainPayment.transactions.length > 0) {
      const transactions = mainPayment.transactions.map((transaction, position) => (
        <div
          className="contract-data__transaction"
          key={`payments:${paymentIndex}:transactions:${position}`}
        >

          <p className="contract-data__transaction__text"> {position+1}ª </p>

          <div className="contract-data__transaction__input-wrapper">

            <DefaultInput
              className="contract-data__transaction__value"
              name={`payments:${paymentIndex}:transactions:${position}:value`}
              isHighlighted={this.isHighlighted(`payments:${paymentIndex}:transactions:${position}:value`)}
              label="Valor"
              type="number"
              placeholder="Valor da entrada"
              min="0.00"
              step="0.01"
              handleInputChange={this.props.handleInputChange}
              value={transaction.value}
              autoComplete="off"
              prefix="R$"
              onKeyDown={(event) => this.handleKeyDown(event)}
              onFocus={(event) => event.target.select()}
              disabled={(this.isEditMode() && !this.hasEditPermission()) || (this.contractAccepted() && this.props.blockTransactionStatus) || this.props.nf_coupon_generated}
            />

            <DefaultInput
              className="contract-data__transaction__date"
              name={`payments:${paymentIndex}:transactions:${position}:effective_date`}
              isHighlighted={this.isHighlighted(`payments:${paymentIndex}:transactions:${position}:effective_date`)}
              label="Data de pagamento"
              type="date"
              placeholder="Data de início do contrato"
              handleInputChange={this.props.handleInputChange}
              value={transaction.effective_date}
              onKeyDown={(event) => this.handleKeyDown(event)}
              disabled={(this.isEditMode() && !this.hasEditPermission()) || (this.contractAccepted() && this.props.blockTransactionStatus)}
            />

            {!this.props.blockTransactionStatus &&
              <DefaultInput
                className="contract-data__transaction__completed"
                name={`payments:${paymentIndex}:transactions:${position}:completed`}
                isHighlighted={this.isHighlighted(`payments:${paymentIndex}:transactions:${position}:completed`)}
                label="Pago"
                type="toggle"
                isHorizontal={window.innerWidth <= 510}
                activeText="Sim"
                inactiveText="Não"
                handleInputChange={this.props.handleInputChange}
                value={transaction.completed}
              />
            }

          </div>

        </div>
      ));

      return (
        <div className="contract-data__sub-section">

          {transactions}

        </div>
      );
    }
  }

  getPaymentInputs() {
    if(this.props.contract.total_value >= 0) {
      if(this.props.contract.payments.length > 1 && parseFloat(this.props.contract.payments[getContractPaymentIndex(this.props.contract.payments, false)].transactions[0].value) >= parseFloat(this.props.contract.total_value)) {
        return null;
      }

      const paymentIndex = getContractPaymentIndex(this.props.contract.payments);

      return (
        <React.Fragment>

          <DefaultInput
            name={`payments:${paymentIndex}:payment_method_id`}
            isHighlighted={this.isHighlighted(`payments:${paymentIndex}:payment_method_id`)}
            label="Forma de pagamento"
            labelMessage={this.props.getPaymentMethodAlertMessage()}
            placeLabelMessageBellow={true}
            type="select"
            handleInputChange={this.props.handleInputChange}
            value={this.props.contract.payments[paymentIndex].payment_method_id || ''}
            options={this.getPaymentMethodOptions()}
            disabled={(this.isEditMode() && !this.hasEditPermission()) || (this.contractAccepted() && this.props.blockTransactionStatus)}
          />

          <DefaultInput
            name="additionalDescription"
            isHighlighted={false}
            label="Descrição adicional"
            type="textarea"
            placeholder="Descrição adicional opcional"
            rows="3"
            handleInputChange={this.props.handleInputChange}
            value={this.props.additionalDescription || ''}
            disabled={this.isEditMode() && !this.hasEditPermission()}
          />

          <section className="contract-data__default-section">

            <header
              className={`contract-data__default-section__header${this.isEditMode() && !this.hasEditPermission() ? '--disabled' : ''}`}
              onClick={() => {
                if (!this.isEditMode() || this.hasEditPermission()) {
                  this.setState({searchTagsSectionVisible: !this.state.searchTagsSectionVisible});
                }
              }}
            >

              <h3 className="contract-data__default-section__header__text">

                <div className="contract-data__default-section__header__text-wrapper">
                  <i className="fa-solid fa-tags contract-data__default-section__header__text-icon"></i>
                  Palavras-chave:
                </div>
                <div className="contract-data__active-search-tag__wrapper">
                  {this.getActiveSearchTags()}
                </div>

              </h3>

              {this.state.searchTagsSectionVisible ?
                <i className="fas fa-chevron-down contract-data__default-section__header__visible-icon"></i>:
                <i className="fas fa-chevron-up contract-data__default-section__header__visible-icon"></i>
              }

            </header>

            <VerticalAccordionContainer
              className="vertical-accordion-container contract-data__default-section__content"
              pose={this.state.searchTagsSectionVisible ? 'verticalOpen' : 'verticalClosed'}
            >

              <div className="vertical-accordion-container contract-data__default-section__content-wrapper">

                <div className="contract-data__default-section-container">

                  <DefaultInput
                    name="searchTagFilter"
                    label="Busca rápida:"
                    type="text"
                    handleInputChange={(event) => this.handleInputChange(event)}
                    value={this.state.searchTagFilter}
                    autoComplete="off"
                    disabled={this.state.confirmInProgress}
                  />

                  <div className="contract-data__search-tag-options-container">

                    {this.getSearchTagOptions()}

                  </div>

                </div>

              </div>

            </VerticalAccordionContainer>

          </section>

          <HalfWrapper>

            <DefaultInput
              name="transactionCount"
              isHighlighted={false}
              label="Número de parcelas"
              labelMessage={this.props.nf_coupon_generated ? 'Campo bloqueado: nota fiscal emitida para um ou mais pagamentos' : null}
              type="select"
              handleInputChange={this.props.handleInputChange}
              value={this.props.transactionCount || ''}
              options={[
                SelectOption('', 'Selecione a quantidade'),
                SelectOption('1', 'À vista'),
                SelectOption('2', '2'),
                SelectOption('3', '3'),
                SelectOption('4', '4'),
                SelectOption('5', '5'),
                SelectOption('6', '6'),
                SelectOption('7', '7'),
                SelectOption('8', '8'),
                SelectOption('9', '9'),
                SelectOption('10', '10'),
                SelectOption('11', '11'),
                SelectOption('12', '12'),
              ]}
              disabled={(this.isEditMode() && !this.hasEditPermission()) || (this.contractAccepted() && this.props.blockTransactionStatus) || this.props.nf_coupon_generated}
            />

            <DefaultInput
              name="firstPaymentDate"
              isHighlighted={this.isHighlighted("firstPaymentDate")}
              label="Data do primeiro pagamento"
              type="date"
              placeholder="Data da primeira parcela"
              handleInputChange={this.props.handleInputChange}
              value={this.props.firstPaymentDate}
              onKeyDown={(event) => this.handleKeyDown(event)}
              disabled={(this.isEditMode() && !this.hasEditPermission()) || (this.contractAccepted() && this.props.blockTransactionStatus)}
            />

          </HalfWrapper>

          {this.getMainTransactions()}

        </React.Fragment>
      );
    }
  }

  async onApplyDiscountCoupon() {
    this.setState({loadingPromotionalCoupon: true});

    let promotional_coupon_code = this.state.promotional_coupon_code;

    const result = await this.props.applyDiscountCoupon(this.state.promotional_coupon_code);

    let discountFieldMessageVisible = true;

    if (result.isValid) {
      promotional_coupon_code = '';
      discountFieldMessageVisible = false;
    }

    this.setState({
      loadingPromotionalCoupon: false,
      discountFieldMessage: result.message,
      discountFieldMessageVisible,
      promotional_coupon_code
    });
  }

  getContractInputs() {
    if(this.props.contract.service_plan_id) {
      return (
        <React.Fragment>

          {((!this.isEditMode() || this.hasEditPermission()) && !this.contractAccepted() && this.isDefaultUnit()) &&
            <div className="contract-data__discount-field__container">

              {this.state.loadingPromotionalCoupon ? (
                <LoadingIcon />
              ) : (
                <React.Fragment>

                  <div className="contract-data__discount-field__wrapper">

                    <DefaultInput
                      className="contract-data__discount-field__input"
                      name="promotional_coupon_code"
                      isHighlighted={this.isHighlighted("promotional_coupon_code")}
                      label="Cupom de desconto"
                      type="text"
                      placeholder="Código do cupom"
                      maxLength="32"
                      handleInputChange={(event) => this.handleInputChange(event)}
                      value={this.state.promotional_coupon_code}
                      autoComplete="off"
                      onKeyDown={(event) => this.handleKeyDown(event)}
                    />

                    <button
                      className="contract-data__discount-field__apply-button"
                      disabled={this.state.promotional_coupon_code.length <= 0}
                      onClick={() => this.onApplyDiscountCoupon()}
                    >

                      Aplicar

                    </button>

                  </div>

                  <WarningMessage
                    className="contract-data__discount-field__result-message"
                    message={this.props.promotionalCouponApplied !== null ? `${100*this.props.contract.discount_percentage_applied}% de desconto aplicado!` : this.state.discountFieldMessage}
                    onClose={() => this.setState({discountFieldMessageVisible: false})}
                    visible={this.state.discountFieldMessageVisible || (this.props.promotionalCouponApplied !== null)}
                    background={this.props.promotionalCouponApplied !== null ? '#d6e5ce' : '#fbe1e3'}
                    color={this.props.promotionalCouponApplied !== null ? '#328643' : '#e73d4a'}
                    hideCloseButton={true}
                  />
                </React.Fragment>
              )}

            </div>
          }

          {((this.props.max_possible_discount !== null && this.props.max_possible_discount > 0 && (!this.props.contract.is_renew || this.props.contract.discount_percentage_applied === null || this.props.contract.discount_percentage_applied > 0)) || this.props.promotionalCouponApplied !== null) &&
            <DefaultInput
              name="discount_percentage_applied"
              isHighlighted={this.isHighlighted("discount_percentage_applied")}
              label="Desconto aplicado"
              labelMessage={this.props.promotionalCouponApplied !== null ? `Cupom aplicado: ${this.props.promotionalCouponApplied.code}${this.props.promotionalCouponApplied.discount_percentage !== this.props.contract.discount_percentage_applied ? ` (Atual: ${100*this.props.promotionalCouponApplied.discount_percentage}%)` : ''}` : null}
              type="range"
              placeholder="Porcentagem"
              min="0.0"
              max={this.props.promotionalCouponApplied !== null ? 100 : this.props.max_possible_discount}
              step="1"
              handleInputChange={this.props.handleInputChange}
              value={this.props.promotionalCouponApplied !== null ? (100 * this.props.contract.discount_percentage_applied) : ((this.props.contract.discount_percentage_applied !== null) ? Math.round(this.props.contract.discount_percentage_applied * 100) : 0)}
              autoComplete="off"
              suffix="%"
              onKeyDown={(event) => this.handleKeyDown(event)}
              onFocus={(event) => event.target.select()}
              disabled={(this.isEditMode() && !this.hasEditPermission()) || this.contractAccepted() || this.props.promotionalCouponApplied !== null}
            />
          }

          <HalfWrapper>

            <HalfWrapper>

              <DefaultInput
                name="period"
                isHighlighted={this.isHighlighted("period")}
                label="Período"
                type="number"
                placeholder="Duração"
                min="0"
                step="1"
                handleInputChange={this.props.handleInputChange}
                value={this.props.contract.period}
                autoComplete="off"
                suffix="dias"
                onKeyDown={(event) => this.handleKeyDown(event)}
                onFocus={(event) => event.target.select()}
                disabled={(this.isEditMode() && !this.hasEditPermission()) || this.props.disablePeriod}
              />

              <DefaultInput
                name="total_value"
                isHighlighted={this.isHighlighted("total_value")}
                label="Valor total"
                labelMessage={this.props.totalValueMessage}
                type="number"
                placeholder="Valor total"
                min="0.00"
                step="0.01"
                handleInputChange={this.props.handleInputChange}
                value={this.props.contract.total_value}
                autoComplete="off"
                prefix="R$"
                onKeyDown={(event) => this.handleKeyDown(event)}
                onFocus={(event) => event.target.select()}
                disabled={(this.isEditMode() && !this.hasEditPermission()) || this.contractAccepted() || this.props.disableTotalValue || this.props.nf_coupon_generated}
              />

            </HalfWrapper>

            <DefaultInput
              name="effective_date"
              isHighlighted={this.isHighlighted("effective_date")}
              label="Data de início"
              type="date"
              placeholder="Data de início do contrato"
              handleInputChange={this.props.handleInputChange}
              value={this.props.contract.effective_date}
              onKeyDown={(event) => this.handleKeyDown(event)}
              disabled={(this.isEditMode() && !this.hasEditPermission()) || this.contractAccepted()}
            />

          </HalfWrapper>

          <HorizontalRule />

            <DefaultInput
              name="additionalPayment"
              isHighlighted={false}
              label="Adicionar pagamento de entrada"
              labelMessage="Esta cobrança nunca é enviada para a maquininha de cartão"
              placeLabelMessageBellow={true}
              type="checkbox"
              isHorizontal={true}
              invertLabel={true}
              handleInputChange={this.props.handleInputChange}
              value={this.props.contract.payments.length > 1}
              disabled={(this.isEditMode() && !this.hasEditPermission()) || (this.contractAccepted() && this.props.blockTransactionStatus) || this.props.nf_coupon_generated}
            />

            {this.getAdditionalPayment()}

          <HorizontalRule />

          {this.getPaymentInputs()}

        </React.Fragment>
      );
    }
  }

  getTimeRestrictions(service_plan_id) {
    if(this.props.calendar_services.length <= 0) {
      return null;
    }

    let restriction_map = null;
    let original_restriction_map = null;

    if(this.props.useContractRestriction && this.props.contract.restriction_map) {
      original_restriction_map = this.props.contract.restriction_map;
    }
    else {
      const serviceSelected = this.props.services.find((service) => service.id === parseInt(this.props.contract.service_plan_id));

      if(serviceSelected && serviceSelected.restriction_map) {
        original_restriction_map = serviceSelected.restriction_map;
      }
    }

    if(this.props.mayEditRestrictions && this.props.restriction_map !== null) {
      restriction_map = this.props.restriction_map;
    }
    else {
      restriction_map = original_restriction_map;
    }

    let restrictions = null;

    if(restriction_map) {
      for(const entry of this.props.calendar_services) {
        if(original_restriction_map[entry] && !original_restriction_map[entry].enable_all && original_restriction_map[entry].time_ids.length > 0) {
          if(restrictions === null) {
            restrictions = {};
          }

          restrictions[entry] = {
            enable_all: restriction_map[entry].enable_all,
            time_ids: [...restriction_map[entry].time_ids]
          };
        }
      }
    }

    return restrictions;
  }

  hasTimeRestriction() {
    if(!this.props.contract.service_plan_id) {
      return false;
    }

    const restrictions = this.getTimeRestrictions(this.props.contract.service_plan_id);

    if(restrictions === null) {
      return false;
    }

    return true;
  }

  getServiceSelectors() {
    const restrictionMap = this.getTimeRestrictions(this.props.contract.service_plan_id);

    const servicesList = [];

    for(const entry of this.props.calendar_services) {
      if(restrictionMap[entry]) {
        servicesList.push(
          <button
            key={`service_plan:service_selector:${entry.toLowerCase().replace(/ /g, '_')}`}
            className={`service-data__service-button`}
            disabled={entry === this.props.activeService}
            onClick={() => this.props.onSelectService(entry)}
          >

            {entry}

          </button>
        );
      }
    }

    return servicesList;
  }

  getDays() {
    if(!this.props.activeService) {
      return;
    }

    const weekdayMap = [
      'segunda-feira',
      'terça-feira',
      'quarta-feira',
      'quinta-feira',
      'sexta-feira',
      'sábado',
      'domingo',
    ];

    return weekdayMap.map((day, dayId) => {
      return (
        <React.Fragment key={`contract_data:day:${dayId}`}>

          <input
            type="radio"
            id={`contract_data:day:${dayId}`}
            name="day"
            value={dayId}
            onChange={() => this.setState({selectedDay: dayId})}
            className="contract-data__days__input"
            checked={dayId === this.state.selectedDay}
          />

          <label
            htmlFor={`contract_data:day:${dayId}`}
            className="contract-data__days__label"
            dayid={dayId}
          >

            <span>{day}</span>

          </label>

        </React.Fragment>
      );
    });
  }

  getHours() {
    if(!this.props.activeService) {
      return;
    }

    let selectedDayIds;
    const serviceMap = this.getTimeRestrictions(this.props.contract.service_plan_id)[this.props.activeService];

    if(serviceMap) {
      selectedDayIds = serviceMap.time_ids;
    }
    else {
      selectedDayIds = [];
    }

    const hours = this.props.training_times.filter((entry) => entry.day_id === this.state.selectedDay && entry.target_service === this.props.activeService);

    if(hours.length <= 0) {
      return null;
    }

    return hours.map((entry) => {
      return (
        <HorizontalContainer
          key={`contract_data:hour:${entry.id}`}
          className={`contract-data__hour${this.props.mayEditRestrictions ? '' : '--disabled'}`}
        >

          <p className="contract-data__hour__text">

            {entry.time}

          </p>

          <button
            className="contract-data__hour__select-time-button"
            disabled={(this.isEditMode() && !this.hasEditPermission()) || !this.props.mayEditRestrictions}
            onClick={() => this.props.onChangeRestriction(entry.id)}
          >

            {selectedDayIds.includes(entry.id) &&
              <i className="fas fa-check"></i>
            }

          </button>

        </HorizontalContainer>
      );
    });
  }

  getActiveSearchTags() {
    const filteredTags = this.props.financial_search_tags.filter((tag) => this.props.active_search_tag_ids.includes(tag.id));

    return filteredTags.map((tag) => (
      <div
        key={`contract_data:active_search_tag:${tag.id}`}
        className={`contract-data__active-search-tag${this.isEditMode() && !this.hasEditPermission() ? '--disabled' : ''}`}
      >
        {tag.name}

        {(!this.isEditMode() || this.hasEditPermission()) &&
          <button
            className="contract-data__active-search-tag__button"
            onClick={(event) => {
              this.props.onToggleSearchTag(tag.id);
              event.stopPropagation();
            }}
          >

            <i className="fa-solid fa-xmark"></i>

          </button>
        }
      </div>
    ));
  }

  getSearchTagOptions() {
    if(!this.props.financial_search_tags) {
      return null;
    }

    const filteredTags = this.props.financial_search_tags.filter((tag) => {
      if (tag.type !== BOTH_TYPE_NAME && !this.props.active_search_tag_ids.includes(tag.id) && tag.type !== INCOME_TYPE_NAME) {
        return false;
      }

      return tag.name.toLocaleLowerCase().includes(this.state.searchTagFilter.toLocaleLowerCase());
    });

    if(filteredTags.length <= 0) {
      return null;
    }

    return filteredTags.map((tag) => {
      const selected = this.props.active_search_tag_ids.includes(tag.id);

      return (
        <div
          key={`contract_data:search_tag:${tag.id}`}
          className={`contract-data__search-tag-option${!selected ? '--disabled': ''}`}
          onClick={() => this.props.onToggleSearchTag(tag.id)}
        >

          <button
            className="contract-data__search-tag-option__check-button"
          >

            {selected &&
              <i className="fas fa-check"></i>
            }

          </button>

          <p className="contract-data__search-tag-option__text">

            {tag.name}

          </p>

        </div>
      );
    });
  }

  getPaymentDeviceOptions() {
    if(this.props.selectedPaymentDevice !== null) {
      return this.props.availablePaymentDevices.map((entry) => SelectOption(entry.id, entry.name));
    }

    return [
      SelectOption('', 'Selecione a maquininha'),
      ...this.props.availablePaymentDevices.map((entry) => SelectOption(entry.id, entry.name))
    ];
  }

  isAwatingPaymentCompletion() {
    const paymentIndex = getContractPaymentIndex(this.props.contract.payments);

    return this.props.contract.payments[paymentIndex].payment_device_requests.some((entry) => !entry.closed);
  }

  async onSendPaymentToDevice() {
    this.setState({overlayIsLoading: true});

    await this.props.onSendPaymentToDevice();

    this.setState({overlayIsLoading: false});
  }

  async onCheckPaymentStatus() {
    this.setState({overlayIsLoading: true});

    await this.props.updateContract();

    this.setState({overlayIsLoading: false});
  }

  getOverlayActions() {
    if (!this.props.showPaymentDeviceOverlay) {
      return null;
    }

    if (!this.isAwatingPaymentCompletion()) {
      return (
        <React.Fragment>

          <DefaultMenuButton
            className="contract-data__overlay__action-button"
            onClick={() => this.props.onCancelPaymentProcess()}
            text="Cancelar"
          />

          <DefaultMenuButton
            className="contract-data__overlay__action-button"
            onClick={() => this.onSendPaymentToDevice()}
            color="green"
            text="Confirmar"
            disabled={this.props.selectedPaymentDevice === null ||
                      this.state.overlayIsLoading}
          />

        </React.Fragment>
      );
    }

    return (
      <React.Fragment>

        <DefaultMenuButton
          className="contract-data__overlay__action-button"
          onClick={() => this.onCancelPaymentDeviceRequest()}
          color="red"
          text="Cancelar"
          disabled={this.state.overlayIsLoading}
        />

        <DefaultMenuButton
          className="contract-data__overlay__action-button"
          onClick={() => this.onCheckPaymentStatus()}
          text="Atualizar"
          disabled={this.state.overlayIsLoading}
        />

      </React.Fragment>
    );
  }

  getOverlayHeader() {
    if (!this.props.showPaymentDeviceOverlay) {
      return null;
    }

    if (!this.isAwatingPaymentCompletion()) {
      return 'Efetuar pagamento';
    }

    return 'Aguardando pagamento';
  }

  getOverlayContent() {
    if (!this.props.showPaymentDeviceOverlay) {
      return null;
    }

    if (!this.isAwatingPaymentCompletion()) {
      return (
        <React.Fragment>

          <p className='contract-data__payment-device-process__message'>

            Cadatro salvo com sucesso! Para prosseguir com a coleta do pagamento, por favor, selecione a maquininha de cartão desejada.

          </p>

          <DefaultInput
            name="selectedPaymentDevice"
            isHighlighted={false}
            label="Máquina de cartão para operação"
            type="select"
            handleInputChange={this.props.handleInputChange}
            value={this.props.selectedPaymentDevice || ''}
            options={this.getPaymentDeviceOptions()}
          />

          <DefaultInput
            name="savePaymentDeviceSelected"
            label="Lembrar maquina selecionada"
            type="checkbox"
            isHorizontal={true}
            invertLabel={true}
            handleInputChange={this.props.handleInputChange}
            value={this.props.savePaymentDeviceSelected}
          />

        </React.Fragment>
      );
    }

    return (
      <p className='contract-data__payment-device-process__message'>

        Aguardando pagamento na maquininha. Assim que o pagamento for efetuado, pressione "Atualizar"
        para verifiar se o pagamento foi concluído com sucesso. Para cancelar a cobrança, pressione "Cancelar".

      </p>
    );
  }

  getConfirmationWindowTitle() {
    if(this.state.confirmInProgress) {
      if(this.state.onCancelPaymentDeviceRequest) {
        return 'Cancelando cobrança';
      }

      return 'Indefinido';
    }
    else if(this.state.confirmFailed) {
      if(this.state.onCancelPaymentDeviceRequest) {
        return 'Falha ao cancelar cobrança';
      }

      return 'Indefinido';
    }
    else if(this.state.onCancelPaymentDeviceRequest) {
      return 'Cancelar cobrança';
    }

    return 'Indefinido';
  }

  getConfirmationWindowDescription() {
    if(this.state.confirmFailed) {
      return this.state.confirmFailDescription;
    }
    else if(this.state.onCancelPaymentDeviceRequest) {
      return 'Após confirmar o cancelamento, a cobrança deve desaparecer da ' +
             'maquininha de cartão dentro de até 1 minuto. Para agilizar este ' +
             'processo, basta pressionar o botão "X" vermelho da maquininha.';
    }

    return 'Indefinido';
  }

  getConfirmationWindowConfirmText() {
    if(this.state.onCancelPaymentDeviceRequest) {
      return 'Cancelar cobrança';
    }

    return 'Indefinido';
  }

  onCancelPaymentDeviceRequest() {
    this.setState({
      onCancelPaymentDeviceRequest: true,
      confirmInProgress: false,
      confirmFailed: false
    });
  }

  onCancelConfirmationWindow() {
    this.setState({
      onCancelPaymentDeviceRequest: false,
      confirmInProgress: false,
      confirmFailed: false
    });
  }

  async onConfirmConfirmationWindow() {
    if (this.state.onCancelPaymentDeviceRequest) {
      const paymentIndex = getContractPaymentIndex(this.props.contract.payments);

      const payment_device_requests = this.props.contract.payments[paymentIndex].payment_device_requests.filter((entry) => !entry.closed);

      if (payment_device_requests.length <= 0) {
        return;
      }

      this.setState({
        confirmInProgress: true
      });

      try{
        if(await patchModel(routes.CANCEL_PAYMENT_DEVICE_REQUEST_PATCH_API.replace('{id}', payment_device_requests[0].id), {})) {
          await this.props.updateContract();

          this.onCancelConfirmationWindow();

          return;
        }
      }
      catch(errors) {
        let errorDescription = DEFAULT_UNKNOWN_ERROR_MESSAGE;

        if(errors instanceof Array) {
          for(let error of errors) {
            switch (error.code) {
              // case 104:
              //   for(let parameter of error.parameters) {
              //     switch (parameter.name) {
              //       case 'contracts':
              //         errorDescription = 'Serviço vinculado à um contrato de aluno. Estes contratos devem ser excluídos antes de excluir este serviço.';
              //
              //         break;
              //       default:
              //     }
              //   }
              //
              //   break;
              case 209:
                errorDescription = 'Sessão do usuário expirada';

                break;
              default:
            }
          }
        }

        this.setState({
          confirmFailDescription: errorDescription,
          confirmFailed: true,
          confirmInProgress: false
        });

        return;
      }
    }
  }

  getPaymentIntegrationMessage() {
    if (this.props.paymentReceived) {
      const paymentIndex = getContractPaymentIndex(this.props.contract.payments);

      return (
        <p className="contract-data__alert-text">

          <i className="fas fa-exclamation contract-data__alert-text__icon"></i>

          <span className="contract-data__alert-text__text-wrapper">

            <span className="contract-data__alert-text__text">
              Pagamento efetuado
            </span>

            {(this.props.contract.payments[paymentIndex].credit_card_brand !== null || this.props.contract.payments[paymentIndex].last_credit_card_digits !== null) &&
              <span className="contract-data__alert-text__text">
                {this.props.contract.payments[paymentIndex].credit_card_brand !== null && this.props.contract.payments[paymentIndex].credit_card_brand}{this.props.contract.payments[paymentIndex].last_credit_card_digits !== null ? `(Terminado em ${this.props.contract.payments[paymentIndex].last_credit_card_digits})` : null}
              </span>
            }

            {this.props.paymentReceived.acquirer_transaction_key !== null &&
              <span className="contract-data__alert-text__text">
                ID: {this.props.paymentReceived.acquirer_transaction_key}
              </span>
            }

            <span className="contract-data__alert-text__text">
              {getAsLocalDatetime(this.props.paymentReceived.updated_at, false).toLocaleString()}
            </span>

          </span>

        </p>
      );
    }

    return null;
  }

  render() {
    return (
      <React.Fragment>

        <OverlayWindow
          className="contract-data__overlay"
          visible={this.props.showPaymentDeviceOverlay}
          loading={this.state.overlayIsLoading}
          actions={(
            <div className="contract-data__overlay__action-container">

              {this.getOverlayActions()}

            </div>
          )}
        >

          <header className="contract-data__overlay__header">

            <h3 className="contract-data__overlay__header__title">
              {this.getOverlayHeader()}
            </h3>

          </header>

          <hr className="contract-data__horizontal-rule" />

          <div className="contract-data__overlay__content">

            {this.getOverlayContent()}

          </div>

        </OverlayWindow>

        <ConfirmationWindow
          title={this.getConfirmationWindowTitle()}
          description={this.getConfirmationWindowDescription()}
          confirmText={this.getConfirmationWindowConfirmText()}
          cancelText={this.state.confirmFailed ? 'Ok' : 'Cancelar'}
          visible={this.state.onCancelPaymentDeviceRequest}
          onCancel={() => this.onCancelConfirmationWindow()}
          onConfirm={() => this.onConfirmConfirmationWindow()}
          loading={this.state.confirmInProgress}
          useErrorIcon={this.state.confirmFailed}
          hideConfirmButton={this.state.confirmFailed}
        />

        <div className="contract-data__warning-container">

          <WarningMessage
            message={this.props.warningMessage}
            onClose={this.props.onCloseWarning}
            visible={this.props.showWarningMessage}
          />

        </div>

        {this.getPaymentIntegrationMessage()}

        <div className="contract-data__input-container">

          <DefaultInput
            name="is_renew"
            isHighlighted={this.isHighlighted("is_renew")}
            label="É uma renovação"
            type="checkbox"
            isHorizontal={true}
            invertLabel={true}
            handleInputChange={this.props.handleInputChange}
            value={this.props.contract.is_renew}
            disabled={this.isEditMode() && !this.hasEditPermission()}
          />

          {((this.isEditMode() && !this.hasEditPermission()) || this.contractAccepted()) ?
            <DefaultInput
              name="service_plan_id"
              isHighlighted={this.isHighlighted("service_plan_id")}
              label="Serviço"
              type="text"
              value={this.props.contract.service_plan.name}
              autoComplete="off"
              disabled={true}
            />:
            <DefaultInput
              name="service_plan_id"
              isHighlighted={this.isHighlighted("service_plan_id")}
              label="Serviço"
              type="select"
              handleInputChange={this.props.handleInputChange}
              value={this.props.contract.service_plan_id || ''}
              options={this.getServiceOptions()}
            />
          }

          {this.getContractInputs()}

        </div>

        {((!this.isEditMode() || this.hasEditPermission()) && this.props.maySendToPaymentDevice()) &&
          <React.Fragment>

            <HorizontalRule />

            <DefaultInput
              name="sendToPaymentDevice"
              isHighlighted={false}
              label="Enviar para maquininha de cartão: "
              type="toggle"
              isHorizontal={true}
              activeText="Sim"
              inactiveText="Não"
              handleInputChange={this.props.handleInputChange}
              value={this.props.sendToPaymentDevice}
            />

          </React.Fragment>
        }

        {(this.isDefaultUnit() && this.hasEditPermission()) &&
          <React.Fragment>

            <HorizontalRule />

            <DefaultInput
              name="requires_nf"
              isHighlighted={false}
              label="Emitir nota fiscal: "
              type="toggle"
              isHorizontal={true}
              activeText="Sim"
              inactiveText="Não"
              handleInputChange={this.props.handleInputChange}
              value={this.props.requires_nf}
            />

          </React.Fragment>
        }

        <HorizontalRule />

        {(this.isDefaultUnit() && this.props.contract.service_plan_id) &&
          <React.Fragment>

            <DefaultInput
              name="allows_food_prescription"
              isHighlighted={this.isHighlighted("allows_food_prescription")}
              label="Permite acompanhamento nutricional"
              type="checkbox"
              isHorizontal={true}
              invertLabel={true}
              placeLabelMessageBellow={true}
              // handleInputChange={this.props.handleInputChange}
              disabled={true}
              labelMessage={(this.isEditMode() && !this.props.contract.accepted && this.props.contract.allows_food_prescription !== this.props.contract.service_plan.allows_food_prescription) ? 'Configuração difere do cadastro do serviço. Ao salvar novamente o contrato, este parâmetro será atualizado para refletir o cadastro do serviço.' : null}
              value={this.isEditMode() ? this.props.contract.allows_food_prescription : this.props.services.find((service) => service.id === parseInt(this.props.contract.service_plan_id)).allows_food_prescription}
            />

            <DefaultInput
              name="has_challenge_access"
              isHighlighted={this.isHighlighted("has_challenge_access")}
              label="Possui acesso ao desafio FYD"
              type="checkbox"
              isHorizontal={true}
              invertLabel={true}
              placeLabelMessageBellow={true}
              // handleInputChange={this.props.handleInputChange}
              disabled={true}
              labelMessage={(this.isEditMode() && !this.props.contract.accepted && this.props.contract.has_challenge_access !== this.props.contract.service_plan.has_challenge_access) ? 'Configuração difere do cadastro do serviço. Ao salvar novamente o contrato, este parâmetro será atualizado para refletir o cadastro do serviço.' : null}
              value={this.isEditMode() ? this.props.contract.has_challenge_access : this.props.services.find((service) => service.id === parseInt(this.props.contract.service_plan_id)).has_challenge_access}
            />

            <HorizontalRule />

          </React.Fragment>
        }

        {this.hasTimeRestriction() &&
          <section className="contract-data__time-restrictions">

            <header
              className="contract-data__time-restrictions__header"
              onClick={() => this.setState({statsSectionVisible: !this.state.statsSectionVisible})}
            >

              <h3 className="contract-data__time-restrictions__header__text">
                <i className="fas fa-clock contract-data__time-restrictions__header__text-icon"></i>
                Restrições de horário
              </h3>

              {this.state.statsSectionVisible ?
                <i className="fas fa-chevron-down contract-data__time-restrictions__header__visible-icon"></i>:
                <i className="fas fa-chevron-up contract-data__time-restrictions__header__visible-icon"></i>
              }

            </header>

            <VerticalAccordionContainer
              className="vertical-accordion-container contract-data__time-restrictions__content"
              pose={this.state.statsSectionVisible ? 'verticalOpen' : 'verticalClosed'}>

              <div className="vertical-accordion-container contract-data__time-restrictions__content-wrapper">

                <div className="contract-data__service-selector">

                  {this.getServiceSelectors()}

                </div>

                <HorizontalRule />

                {this.props.activeService &&
                  <React.Fragment>

                    <h3 className="contract-data__instructions">Os horários liberados estão assinalados abaixo:</h3>

                    <div className="contract-data__content-wrapper">

                      <div className="contract-data__days">

                        <h3 className="contract-data__days-title">Dias</h3>

                        <div className="contract-data__days__wrapper">

                          {this.getDays()}

                        </div>


                      </div>

                      <div className="contract-data__hour-manager">

                        <h3 className="contract-data__hour-manager-title">Horários</h3>

                        <div className="contract-data__hours">

                          <PoseGroup flipMove={false}>

                            {this.getHours()}

                          </PoseGroup>

                        </div>

                      </div>

                    </div>

                  </React.Fragment>
                }

              </div>

            </VerticalAccordionContainer>

          </section>
        }

        <HorizontalRule />

        <div className="contract-data__buttons-container">

          {(!this.isEditMode() || this.hasEditPermission()) &&
            <button
              className="contract-data__save-button"
              disabled={!this.props.enableSave}
              onClick={this.props.onSave}
            >

              Salvar

            </button>
          }

          <button
            className="contract-data__cancel-button"
            onClick={() => this.props.history.goBack()}
          >

            {(!this.isEditMode() || this.hasEditPermission()) ? 'Cancelar' : 'Voltar'}

          </button>

          {/* <Link
            className="contract-data__cancel-button"
            to={this.props.onCancelPath}
          >

            Cancelar

          </Link> */}

        </div>

      </React.Fragment>
    );
  }
}

export default ContractData;
