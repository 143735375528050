import React from 'react';
import { PoseGroup } from 'react-pose';
import {getModel,
        getModels,
        postModel,
        deleteModel,
        setUrlParameters} from '../../../utils/functions';
import * as routes from '../../../constants';
import {DEFAULT_UNKNOWN_ERROR_MESSAGE,
        ACCESS_CONTROL_METHOD_FACIAL_RECOGNITION_ID,
        ACCESS_CONTROL_METHOD_FINGER_PRINT_ID,
        ACCESS_CONTROL_RULE_ALWAYS,
        ACCESS_CONTROL_RULE_CHECKIN,
        ACCESS_CONTROL_RULE_BLOCKED} from '../../../constants';
import ConfirmationWindow from '../../confirmation_window';
import {DefaultSubSectionTitle, HorizontalRule} from '../../../utils/default_section';
import {FadeContainer} from '../../../utils/pose_containers';
import PreLoader from '../../../utils/preloader';
import DefaultMenuButton from '../../../components/default_menu_button';
import OverlayWindow from '../../../components/overlay_window';
import DefaultInput from '../../../utils/default_input';
import './student_access_control.scss';


class StudentAccessControl extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      access_rule: ACCESS_CONTROL_RULE_CHECKIN,
      access_control_devices: [],
      associations: [],
      onConfigureFacialRecognition: false,
      onConfigureFingerPrint: false,
      userImage: null,
      overlayIsLoading: false,
      selectedDevice: null,
      loadingData: true,
      mayRemoveFacialRecognition: false,
      mayRemoveFingerPrint: false,
      accessRuleToChange: null,
      access_status_description: "",
      current_access_status_description: "",
      confirmInProgress: false,
      confirmFailed: false,
      confirmFailDescription: "",
      screenWidth: window.innerWidth
    };
  }

  async getAccessControlRule() {
    return await getModels(routes.USER_ACCESS_CONTROL_ACCESS_RULE_GET_API.replace('{id}', this.props.student.id));
  }

  async getAccessControlDevices() {
    return await getModels(routes.ACCESS_CONTROL_DEVICES_GET_API);
  }

  async getAccessControlDeviceAssociations() {
    return await getModels(routes.USER_ACCESS_CONTROL_DEVICE_ASSOCIATIONS_GET_API.replace('{id}', this.props.student.id));
  }

  async componentDidMount() {
    this.setState({
      loadingData: true
    });

    let access_rule = this.getAccessControlRule();
    let access_control_devices = this.getAccessControlDevices();

    let update = this.reloadList(false);

    access_rule = await access_rule;
    access_control_devices = await access_control_devices;

    update = await update;

    if (access_rule) {
      update.access_rule = access_rule.access_rule;
      update.current_access_status_description = access_rule.access_status_description;
    }

    if (access_control_devices) {
      update.access_control_devices = access_control_devices;
      update.access_control_devices.sort((a, b) => a.name.localeCompare(b.name));

      if (update.access_control_devices.length > 0) {
        update.selectedDevice = update.access_control_devices[0];
      }
    }

    this.setState(update);

    this.resizeListener = () => this.updateSize();

    window.addEventListener("resize", this.resizeListener);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resizeListener);
  }

  updateSize() {
    this.setState({
      screenWidth: window.innerWidth
    });
  }

  async reloadList(updateState=true) {
    if (updateState) {
      this.setState({
        loadingData: true
      });
    }

    const update = {loadingData: false};

    let associations = this.getAccessControlDeviceAssociations();

    associations = await associations;

    if (associations) {
      update.associations = associations;
    }

    if (updateState) {
      this.setState(update);
    }

    return update;
  }

  handleInputChange(event) {
    const target = event.target;
    let value = target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  onCancelConfirmationWindow() {
    this.setState({
      onConfigureFacialRecognition: false,
      onConfigureFingerPrint: false,
      overlayIsLoading: false,
      mayRemoveFacialRecognition: false,
      mayRemoveFingerPrint: false,
      accessRuleToChange: null,
      confirmFailed: false,
      confirmInProgress: false,
    });
  }

  onRemoveFacialRecognition() {
    this.setState({
      mayRemoveFacialRecognition: true,
      mayRemoveFingerPrint: false,
      accessRuleToChange: null,
      confirmFailed: false,
      confirmInProgress: false,
    });
  }

  onRemoveFingerPrint() {
    this.setState({
      mayRemoveFingerPrint: true,
      mayRemoveFacialRecognition: false,
      accessRuleToChange: null,
      confirmFailed: false,
      confirmInProgress: false,
    });
  }

  onChangeAccessStatus(accessRuleToChange) {
    this.setState({
      accessRuleToChange,
      mayRemoveFingerPrint: false,
      mayRemoveFacialRecognition: false,
      confirmFailed: false,
      confirmInProgress: false,
    });
  }

  async onConfirmConfirmationWindow() {
    if (this.state.selectedDevice === null) {
      return null;
    }

    this.setState({
      confirmInProgress: true
    });

    if(this.state.accessRuleToChange !== null) {
      const data = {
        access_rule: this.state.accessRuleToChange,
        access_status_description: this.state.accessRuleToChange === ACCESS_CONTROL_RULE_BLOCKED ? this.state.access_status_description : null
      };

      try{
        if(await postModel(routes.USER_ACCESS_CONTROL_ACCESS_RULE_POST_API.replace('{id}', this.props.student.id.toString()), data)) {
          this.setState({
            mayRemoveFacialRecognition: false,
            mayRemoveFingerPrint: false,
            access_rule: data.access_rule,
            current_access_status_description: data.access_status_description,
            accessRuleToChange: null,
            confirmFailed: false,
            confirmInProgress: false
          });
        }
      }
      catch(errors) {
        let errorDescription = DEFAULT_UNKNOWN_ERROR_MESSAGE;

        if(errors instanceof Array) {
          for(let error of errors) {
            switch (error.code) {
              case 209:
                errorDescription = 'Sessão do usuário expirada.';

                break;
              default:
            }
          }
        }

        this.setState({
          confirmFailDescription: errorDescription,
          confirmFailed: true,
          confirmInProgress: false
        });

        return;
      }
    }
    else {
      const parameters = {
        access_control_device_id: this.state.selectedDevice.id
      };

      if (this.state.mayRemoveFacialRecognition) {
        parameters.access_control_method_id = ACCESS_CONTROL_METHOD_FACIAL_RECOGNITION_ID;
      }
      else if (this.state.mayRemoveFingerPrint) {
        parameters.access_control_method_id = ACCESS_CONTROL_METHOD_FINGER_PRINT_ID;
      }
      else {
        return null;
      }

      try{
        if (await deleteModel(setUrlParameters(routes.USER_ACCESS_CONTROL_DEVICE_ASSOCIATION_DELETE_API.replace('{id}', this.props.student.id), parameters))) {
          this.setState({
            mayRemoveFacialRecognition: false,
            mayRemoveFingerPrint: false,
            accessRuleToChange: null,
            confirmFailed: false,
            confirmInProgress: false,
          });

          this.reloadList();
        }
      }
      catch(errors) {
        let errorDescription = DEFAULT_UNKNOWN_ERROR_MESSAGE + '.';

        if(errors instanceof Array) {
          for(let error of errors) {
            switch (error.code) {
              // case 104:
              //   for(let parameter of error.parameters) {
              //     switch (parameter.name) {
              //       case 'contracts':
              //         errorDescription = 'Serviço vinculado à um contrato de aluno. Estes contratos devem ser excluídos antes de excluir este serviço.';
              //
              //         break;
              //       default:
              //     }
              //   }
              //
              //   break;
              case 209:
                errorDescription = 'Sessão do usuário expirada.';

                break;
              default:
            }
          }
        }

        this.setState({
          confirmFailDescription: errorDescription,
          confirmFailed: true,
          confirmInProgress: false
        });

        return;
      }
    }
  }

  async onConfigureFacialRecognition() {
    if (this.state.selectedDevice === null) {
      return null;
    }

    this.setState({
      onConfigureFacialRecognition: true,
      overlayIsLoading: true,
      userImage: null
    });

    try{
      const response = await getModel(routes.ACCESS_CONTROL_DEVICE_REQUEST_USER_PICTURE_GET_API.replace('{id}', this.state.selectedDevice.id));

      if(response) {
        this.setState({
          userImage: response.user_image,
          overlayIsLoading: false
        });
      }
    }
    catch(errors) {
      let errorDescription = DEFAULT_UNKNOWN_ERROR_MESSAGE + '.';

      if(errors instanceof Array) {
        for(let error of errors) {
          switch (error.code) {
            case 207:
              for(let parameter of error.parameters) {
                switch (parameter.name) {
                  case 'Timeout':
                    errorDescription = 'Não foi possível coletar foto do cliente pelo dispositivo. Verifique se ele está ligado ' +
                                       'corretamente e tente novamente mais tarde.';

                    break;
                  default:
                }
              }

              break;
            case 208:
              if (error.message.includes('Took to long to capture picture')) {
                errorDescription = 'Tempo limite de espera para captura de ' +
                                   'foto alcançada. Tente novamente.';
              }

              break;
            case 209:
              errorDescription = 'Sessão do usuário expirada.';

              break;
            default:
          }
        }
      }

      this.setState({
        confirmFailDescription: errorDescription,
        confirmFailed: true,
        confirmInProgress: false
      });

      return;
    }
  }

  async onConfigureFingerPrint() {
    if (this.state.selectedDevice === null) {
      return null;
    }

    this.setState({
      onConfigureFingerPrint: true,
      confirmInProgress: true
    });

    const data = {
      access_control_device_id: this.state.selectedDevice.id,
      access_control_method_id: ACCESS_CONTROL_METHOD_FINGER_PRINT_ID
    }

    try{
      if (await postModel(routes.USER_ACCESS_CONTROL_DEVICE_ASSOCIATION_POST_API.replace('{id}', this.props.student.id), data)) {
        this.setState({
          onConfigureFingerPrint: false,
          confirmInProgress: false
        });

        this.reloadList();
      }
    }
    catch(errors) {
      let errorDescription = DEFAULT_UNKNOWN_ERROR_MESSAGE + '.';

      if(errors instanceof Array) {
        for(let error of errors) {
          switch (error.code) {
            case 207:
              for(let parameter of error.parameters) {
                switch (parameter.name) {
                  case 'Timeout':
                    errorDescription = 'Não foi possível coletar foto do cliente pelo dispositivo. Verifique se ele está ligado ' +
                                       'corretamente e tente novamente mais tarde.';

                    break;
                  default:
                }
              }

              break;
            case 208:
              if (error.message.includes('Took to long to capture finger print')) {
                errorDescription = 'Tempo limite de espera para captura de ' +
                                   'impressão digital alcançada. Tente novamente.';
              }

              break;
            case 209:
              errorDescription = 'Sessão do usuário expirada.';

              break;
            default:
          }
        }
      }

      this.setState({
        confirmFailDescription: errorDescription,
        confirmFailed: true,
        confirmInProgress: false
      });

      return;
    }
  }

  async onConfirmCredentialRegistration() {
    if (this.state.selectedDevice === null) {
      return null;
    }

    if (this.state.onConfigureFacialRecognition && this.state.userImage !== null) {
      this.setState({
        overlayIsLoading: true
      });

      const data = {
        access_control_device_id: this.state.selectedDevice.id,
        access_control_method_id: ACCESS_CONTROL_METHOD_FACIAL_RECOGNITION_ID,
        user_picture: this.state.userImage
      }

      try{
        if (await postModel(routes.USER_ACCESS_CONTROL_DEVICE_ASSOCIATION_POST_API.replace('{id}', this.props.student.id), data)) {
          this.setState({
            overlayIsLoading: false,
            onConfigureFacialRecognition: false
          });

          this.reloadList();
        }
      }
      catch(errors) {
        let errorDescription = DEFAULT_UNKNOWN_ERROR_MESSAGE + '.';

        if(errors instanceof Array) {
          for(let error of errors) {
            switch (error.code) {
              case 207:
                for(let parameter of error.parameters) {
                  switch (parameter.name) {
                    case 'Timeout':
                      errorDescription = 'Não foi possível se conectar com o dispositivo de reconhecimento facial. Verifique se ele está ligado ' +
                                         'corretamente e tente novamente mais tarde.';

                      break;
                    default:
                  }
                }

                break;
              case 209:
                errorDescription = 'Sessão do usuário expirada.';

                break;
              default:
            }
          }
        }

        this.setState({
          confirmFailDescription: errorDescription,
          confirmFailed: true,
          confirmInProgress: false
        });

        return;
      }

    }
  }

  getConfirmationWindowTitle() {
    if(this.state.confirmInProgress) {
      if (this.state.mayRemoveFacialRecognition || this.state.mayRemoveFingerPrint) {
        return 'Removendo cadastro';
      }
      else if (this.state.onConfigureFingerPrint) {
        return 'Configurando impressão digital';
      }
      else if(this.state.accessRuleToChange !== null) {
        if (this.state.accessRuleToChange === ACCESS_CONTROL_RULE_ALWAYS) {
          return 'Liberando acesso';
        }
        else if (this.state.accessRuleToChange === ACCESS_CONTROL_RULE_CHECKIN) {
          return 'Restringindo acesso ao check-in';
        }
        else if (this.state.accessRuleToChange === ACCESS_CONTROL_RULE_BLOCKED) {
          return 'Bloqueando acesso';
        }

        return 'INDEFINIDO';
      }

      return 'Indefinido';
    }
    else if(this.state.confirmFailed) {
      if (this.state.mayRemoveFacialRecognition) {
        return 'Falha ao remover face';
      }
      else if (this.state.mayRemoveFingerPrint) {
        return 'Falha ao remover impressão digital';
      }
      else if(this.state.onConfigureFacialRecognition || this.state.onConfigureFingerPrint) {
        return 'Falha durante configuração';
      }
      else if(this.state.accessRuleToChange !== null) {
        return 'Falha ao atualizar regra de acesso';
      }
    }

    if (this.state.mayRemoveFacialRecognition) {
      return 'Remover cadastro de face';
    }
    else if (this.state.mayRemoveFingerPrint) {
      return 'Remover cadastro de impressão digital';
    }
    else if(this.state.accessRuleToChange !== null) {
      if (this.state.accessRuleToChange === ACCESS_CONTROL_RULE_ALWAYS) {
        return 'Liberar acesso';
      }
      else if (this.state.accessRuleToChange === ACCESS_CONTROL_RULE_CHECKIN) {
        return 'Restringir acesso ao check-in';
      }
      else if (this.state.accessRuleToChange === ACCESS_CONTROL_RULE_BLOCKED) {
        return 'Bloquear acesso';
      }

      return 'Indefinido';
    }

    return 'Não implementado';
  }

  getConfirmationWindowDescription() {
    if(this.state.confirmFailed) {
      return this.state.confirmFailDescription;
    }

    if (this.state.mayRemoveFacialRecognition) {
      return 'A autenticação por reconhecimento facial será desabilitada para o usuário selecionado. Para habilitar novamente será necessário uma nova coleta de foto.';
    }
    else if (this.state.mayRemoveFingerPrint) {
      return 'A autenticação por impressão digital será desabilitada para o usuário selecionado. Para habilitar novamente será necessário uma nova coleta de impressão digital.';
    }
    else if (this.state.onConfigureFingerPrint) {
      return 'Processo de coleta e cadastro da impressão digital em andamento.';
    }
    else if(this.state.accessRuleToChange !== null) {
      if (this.state.accessRuleToChange === ACCESS_CONTROL_RULE_ALWAYS) {
        return 'O aluno terá acesso liberado pela catraca em qualquer horário contanto que este possua um contrato ativo.';
      }
      else if (this.state.accessRuleToChange === ACCESS_CONTROL_RULE_CHECKIN) {
        return 'O acesso do aluno estará restrito de acordo com o horário de check-in realizado.';
      }
      else if (this.state.accessRuleToChange === ACCESS_CONTROL_RULE_BLOCKED) {
        return (
          <div className="student-access-control__popup-window-content-wrapper">

            <p>O aluno terá seu acesso pela catraca bloqueado.</p>

            <hr className="student-access-control__horizontal-rule--light" />

            <DefaultInput
              className="student-access-control__input"
              name="access_status_description"
              // label="Motivo do bloqueio"
              type="textarea"
              placeholder="Motivo do bloqueio"
              rows="3"
              handleInputChange={(event) => this.handleInputChange(event)}
              value={this.state.access_status_description || ''}
            />

          </div>
        );
      }

      return 'INDEFINIDO';
    }

    return 'Não implementado';
  }

  getConfirmationWindowConfirmText() {
    if (this.state.mayRemoveFacialRecognition || this.state.mayRemoveFingerPrint) {
      return 'Remover';
    }
    else if(this.state.accessRuleToChange !== null) {
      if (this.state.accessRuleToChange === ACCESS_CONTROL_RULE_ALWAYS) {
        return 'Liberar';
      }
      else if (this.state.accessRuleToChange === ACCESS_CONTROL_RULE_CHECKIN) {
        return 'Restringir';
      }
      else if (this.state.accessRuleToChange === ACCESS_CONTROL_RULE_BLOCKED) {
        return 'Bloquear';
      }
    }

    return 'Indefinido';
  }

  getOverlayActions() {
    if (this.state.onConfigureFacialRecognition) {
      let confirmText = 'Confirmar';

      if (this.state.overlayIsLoading) {
        if (this.state.userImage !== null) {
          confirmText = 'Cadastrando foto...';
        }
        else {
          confirmText = 'Aguardando captura de foto...';
        }
      }

      return (
        <React.Fragment>

          {!this.state.overlayIsLoading &&
            <DefaultMenuButton
              className="student-access-control__overlay__action-button"
              onClick={() => this.setState({onConfigureFacialRecognition: false})}
              text="Cancelar"
            />
          }

          <DefaultMenuButton
            className="student-access-control__overlay__action-button"
            onClick={() => this.onConfirmCredentialRegistration()}
            color="purple"
            text={confirmText}
            disabled={this.state.overlayIsLoading}
          />

        </React.Fragment>
      );
    }

    return null;
  }

  getOverlayHeader() {
    if (this.state.onConfigureFacialRecognition) {
      return 'Configuração: Reconhecimento facial';
    }

    return null;
  }

  getOverlayContent() {
    if (this.state.onConfigureFacialRecognition && this.state.userImage !== null) {
      return (
        <React.Fragment>

            <p className='student-access-control__overlay__message'>

              Para confirmar, verifique se a imagem não está borrada e está bem enquadrada.

            </p>

          <div
            className="student-access-control__user-picture"
          >

            <img
              className="student-access-control__user-picture__img"
              src={`data:image/jpeg;base64,${this.state.userImage}`}
              alt="Foto do usuário"
            />

          </div>

        </React.Fragment>
      );
    }

    return null;
  }

  getDeviceSelectors() {
    if (this.state.access_control_devices.length <= 0) {
      return (
        <p className="student-access-control__alert-text">

          <i className="ffas fa-exclamation student-access-control__alert-text__icon"></i>
          <span className="student-access-control__alert-text__text">
            Nenhum controle de acesso encontrado
          </span>

        </p>
      );
    }

    return this.state.access_control_devices.map((device) => {
      const deviceSelected = this.state.selectedDevice !== null && device.id === this.state.selectedDevice.id;

      return (
        <button
          key={`student_access_control:device:${device.id}`}
          className={`student-access-control__device-button`}
          disabled={deviceSelected}
          onClick={() => this.setState({
            selectedDevice: device
          })}
        >

          {device.name}

        </button>
      );
    });
  }

  getSelectedDeviceConfiguration() {
    if (this.state.selectedDevice === null) {
      return null;
    }

    const facialRecognitionAssociation = this.state.associations.find((entry) => entry.access_control_method_id === ACCESS_CONTROL_METHOD_FACIAL_RECOGNITION_ID &&
                                                                                 entry.access_control_device_id === this.state.selectedDevice.id);

    let facialRecognitionElement;

    if (facialRecognitionAssociation) {
      facialRecognitionElement = (
        <React.Fragment>

          <div className="student-access-control__action-buttons">

          <button
            className="student-access-control__reconfig-button"
            onClick={() => this.onConfigureFacialRecognition()}
          >

            <i className="fa-solid fa-gear student-access-control__add-button__icon"></i>
            Reconfigurar

          </button>

          <button
            className="student-access-control__remove-button"
            onClick={() => this.onRemoveFacialRecognition()}
          >

            <i className="fa-solid fa-trash-can student-access-control__add-button__icon"></i>
            Remover

          </button>

          </div>
        </React.Fragment>
      );
    }
    else {
      facialRecognitionElement = (
        <div className="student-access-control__action-buttons">

          <button
            className="student-access-control__add-button"
            onClick={() => this.onConfigureFacialRecognition()}
          >

            <i className="fa-solid fa-gear student-access-control__add-button__icon"></i>
            Iniciar configuração

          </button>

        </div>
      );
    }

    const fingerPrintAssociation = this.state.associations.find((entry) => entry.access_control_method_id === ACCESS_CONTROL_METHOD_FINGER_PRINT_ID &&
                                                                           entry.access_control_device_id === this.state.selectedDevice.id);

    let fingerPrintElement;

    if (fingerPrintAssociation) {
      fingerPrintElement = (
        <React.Fragment>

          <div className="student-access-control__action-buttons">

          <button
            className="student-access-control__reconfig-button"
            onClick={() => this.onConfigureFingerPrint()}
          >

            <i className="fa-solid fa-gear student-access-control__add-button__icon"></i>
            Reconfigurar

          </button>

          <button
            className="student-access-control__remove-button"
            onClick={() => this.onRemoveFingerPrint()}
          >

            <i className="fa-solid fa-trash-can student-access-control__add-button__icon"></i>
            Remover

          </button>

          </div>
        </React.Fragment>
      );
    }
    else {
      fingerPrintElement = (
        <div className="student-access-control__action-buttons">

          <button
            className="student-access-control__add-button"
            onClick={() => this.onConfigureFingerPrint()}
          >

            <i className="fa-solid fa-gear student-access-control__add-button__icon"></i>
            Iniciar configuração

          </button>

        </div>
      );
    }

    return (
      <React.Fragment>

        <HorizontalRule />

        <DefaultSubSectionTitle
          icon={<i className="fa-solid fa-image-portrait"></i>}
          text="Reconhecimento facial"
        />

        <p className='student-access-control__overlay__message'>

          Antes de iniciar a configuração com a coleta da foto, posicione a pessoa em frente ao dispositivo de reconhecimento facial e peça para ela olhar
          para a lente do aparelho. Peça também para que a pessoa remova os óculos,
          chapéu, boné, etc, para que ajeite o cabelo de forma que
          não obstrua o rosto (testa inclusa) e mantenha uma expressão neutra.

        </p>

        {facialRecognitionElement}

        <DefaultSubSectionTitle
          icon={<i className="fa-solid fa-fingerprint"></i>}
          text="Impressão digital"
        />

        <p className='student-access-control__overlay__message'>

          Antes de iniciar a configuração da impressão digital, posicione a pessoa em frente ao dispositivo de acesso.
          A pessoa deve escolher o dedo que deseja utilizar para o cadastro (normalmente o indicador) e ela deverá pressionar o
          mesmo dedo 3 vezes seguindo a orientação do aparelho. Para cada vez que o aparelho requisitar,
          a pessoa deve desencostar o dedo do sensor e colocá-lo novamente como faria ao acessar a academia.

        </p>

        {fingerPrintElement}

      </React.Fragment>
    );
  }

  getAccessStatus() {
    if(this.state.access_rule === ACCESS_CONTROL_RULE_ALWAYS) {
      return (
        <h2 className="student-access-control__access-status">Acesso liberado</h2>
      );
    }
    else if(this.state.access_rule === ACCESS_CONTROL_RULE_BLOCKED) {
      return (
        <h2 className="student-access-control__access-status--red">Acesso bloqueado</h2>
      );
    }

    return (
      <h2 className="student-access-control__access-status">Acesso restrito ao checkin</h2>
    );
  }

  render() {
    return this.state.loadingData ? (
      <PoseGroup>
        <FadeContainer className="content-frame__loading-container" key="preloader">
          <PreLoader local={true} />
        </FadeContainer>
      </PoseGroup>
    ):
    (
      <React.Fragment>

        <OverlayWindow
          className="student-access-control__overlay"
          visible={this.state.onConfigureFacialRecognition}
          loading={this.state.overlayIsLoading}
          actions={(
            <div className="student-access-control__overlay__action-container">

              {this.getOverlayActions()}

            </div>
          )}
        >

          <header className="student-access-control__overlay__header">

            <h3 className="student-access-control__overlay__header__title">
              {this.getOverlayHeader()}
            </h3>

          </header>

          <hr className="student-access-control__horizontal-rule" />

          <div className="student-access-control__overlay__content">

            {this.getOverlayContent()}

          </div>

        </OverlayWindow>

        <ConfirmationWindow
          title={this.getConfirmationWindowTitle()}
          description={this.getConfirmationWindowDescription()}
          descriptionAsElement={this.state.accessRuleToChange === ACCESS_CONTROL_RULE_BLOCKED}
          confirmText={this.getConfirmationWindowConfirmText()}
          cancelText={this.state.confirmFailed ? 'Ok' : 'Cancelar'}
          visible={this.state.confirmFailed ||
                   this.state.mayRemoveFacialRecognition ||
                   this.state.mayRemoveFingerPrint ||
                   this.state.onConfigureFingerPrint ||
                   this.state.accessRuleToChange !== null}
          onCancel={() => this.onCancelConfirmationWindow()}
          onConfirm={() => this.onConfirmConfirmationWindow()}
          loading={this.state.confirmInProgress}
          useErrorIcon={this.state.confirmFailed}
          // useSuccessIcon={}
          hideConfirmButton={this.state.confirmFailed}
        />

        <div className="student-access-control__access-status-wrapper">

          {this.getAccessStatus()}

          <div className="student-access-control__status-buttons-wrapper">

            {this.state.access_rule !== ACCESS_CONTROL_RULE_BLOCKED &&
              <button
                className="model-table__default-delete-button student-access-control__access-status__toggle-button"
                onClick={(event) => this.onChangeAccessStatus(ACCESS_CONTROL_RULE_BLOCKED)}
              >

                <i className="fa-solid fa-ban student-access-control__access-status__toggle-button__icon"></i>Bloquear aceso

              </button>
            }

            {this.state.access_rule !== ACCESS_CONTROL_RULE_ALWAYS &&
              <button
                className="model-table__default-button student-access-control__access-status__toggle-button"
                onClick={(event) => this.onChangeAccessStatus(ACCESS_CONTROL_RULE_ALWAYS)}
              >

                <i className="fas fa-toggle-on student-access-control__access-status__toggle-button__icon"></i>Liberar acesso

              </button>
            }

            {(this.state.access_rule !== ACCESS_CONTROL_RULE_CHECKIN && this.state.access_rule !== null) &&
              <button
                className="model-table__default-button student-access-control__access-status__toggle-button"
                onClick={(event) => this.onChangeAccessStatus(ACCESS_CONTROL_RULE_CHECKIN)}
              >

                <i className="fas fa-toggle-on student-access-control__access-status__toggle-button__icon"></i>Restringir por check-in

              </button>
            }

          </div>

        </div>

        {(this.state.current_access_status_description !== null && this.state.current_access_status_description.length > 0) &&
          <p className="student-access-control__alert-text">

            <i className="fas fa-exclamation student-access-control__alert-text__icon"></i>
            <span className="student-access-control__alert-text__text">
              {this.state.current_access_status_description}
            </span>

          </p>
        }

        <HorizontalRule />

        <DefaultSubSectionTitle
          className="student-access-control__device-selector-header"
          icon={<i className="fas fa-list"></i>}
          text="Selecione o acesso a ser configurado"
        />

        <div className="student-access-control__device-selector">

          {this.getDeviceSelectors()}

        </div>

        {this.getSelectedDeviceConfiguration()}

      </React.Fragment>

    );
  }
}

export default StudentAccessControl;
