import React from 'react';
import ContentFrame from '../content_frame';
import * as routes from '../../constants';
import {getModel, patchModel, getModels} from '../../utils/functions';
import ServiceData from './service_data';

class ServiceEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      service: {
        name: "",
        code: "",
        period: "0",
        value: "0.00",
        allowed_discount_percentage: 0,
        penalty_value: "0.00",
        color: null,
        allows_food_prescription: false,
        has_challenge_access: false,
        requires_personal_period: false,
        show_in_website: false,
        send_expiration_notification: true,
        description: "",
        service_group_id: "",
        is_active: true
      },
      restriction_map: {},
      services: [],
      service_groups: [],
      training_times: [],
      activeService: "",
      highlights: [],
      warningMessage: "",
      showWarningMessage: false,
      loading: true,
    };
  }

  async componentDidMount() {
    const update = {loading: false};

    let service = getModel(`${routes.SERVICE_GET_API}${this.props.match.params.serviceId}`);
    let available_calendar = getModels(routes.TRAINING_TIMES_GET_API);
    let service_groups = getModels(routes.SERVICE_GROUPS_GET_API);

    service = await service;

    if(service) {
      update.service = {...this.state.service, ...service};

      if(service.restriction_map) {
        update.restriction_map = service.restriction_map;
      }

      available_calendar = await available_calendar;

      if(available_calendar) {
        if(available_calendar.services.length > 0) {
          update.activeService = available_calendar.services[0];
        }

        update.services = available_calendar.services;
        update.services.sort((a, b) => a.localeCompare(b));
        update.training_times = available_calendar.training_times;
      }

      service_groups = await service_groups;

      if(service_groups) {
        update.service_groups = service_groups;
      }

      this.setState(update);
    }
    else {
      this.props.history.replace(routes.SERVICE_LIST_PATH);
    }
  }

  handleInputChange(event) {
    const target = event.target;
    let value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    if(name === 'enable_all') {
      const activeService = this.state.activeService;
      const restriction_map = {...this.state.restriction_map};

      if(restriction_map[activeService]) {
        restriction_map[activeService] = {
          enable_all: value,
          time_ids: [...restriction_map[activeService].time_ids],
        };
      }
      else {
        restriction_map[activeService] = {
          enable_all: value,
          time_ids: [],
        };
      }

      this.setState({restriction_map});
    }
    else {
      if(name === 'allowed_discount_percentage') {
        if(value > 100) {
          value = 100;
        }
        else if(value < 0) {
          value = 0;
        }

        value = value / 100;
      }

      const newData = {...this.state.service, [name]: value};

      this.setState({
        service: newData
      });
    }
  }

  inputsAreValid() {
    return this.state.service.name.length > 0 &&
           this.state.service.period > 0 &&
           this.state.service.value >= 0;
  }

  async saveData() {
    this.setState({
      highlights: [],
      showWarningMessage: false,
      loading: true
    });

    const data = {...this.state.service}
    data.period = parseInt(data.period);
    data.value = parseFloat(data.value);
    data.penalty_value = parseFloat(data.penalty_value);
    data.restriction_map = this.state.restriction_map;

    try {
      await patchModel(`${routes.SERVICE_PATCH_API}${this.props.match.params.serviceId}`, data);
    }
    catch(errors) {
      let warningMessages = [];
      let highlights = [];

      if(errors instanceof Array) {
        for(let error of errors) {
          switch (error.code) {
            case 102:
              for(let parameter of error.parameters) {
                switch (parameter.name) {
                  case 'period':
                    warningMessages.push('Período deve ser maior que 0');
                    highlights.push('period');

                    break;
                  case 'value':
                    warningMessages.push('Valor deve ser positivo');
                    highlights.push('value');

                    break;
                  case 'penalty_value':
                    warningMessages.push('Valor deve ser positivo');
                    highlights.push('penalty_value');

                    break;
                  default:
                }
              }

              break;
            case 103:
              for(let parameter of error.parameters) {
                switch (parameter.name) {
                  case 'name':
                    warningMessages.push('Nome já cadastrado');
                    highlights.push('name');

                    break;
                  default:
                }
              }

              break;
            case 209:
              warningMessages.push('Sessão do usuário expirada');

              break;
            default:
          }
        }
      }

      this.setState({
        highlights: highlights,
        showWarningMessage: true,
        warningMessage: `${warningMessages.join('; ')}.`,
        loading: false
      });

      return;
    }

    this.props.history.replace(routes.SERVICE_LIST_PATH);
  }

  onClickTime(training_time_id) {
    if(!this.state.activeService) {
      return;
    }

    const activeService = this.state.activeService;
    const restriction_map = {...this.state.restriction_map};

    if(restriction_map[activeService]) {
      const time_ids = [...restriction_map[activeService].time_ids];

      if(time_ids.includes(training_time_id)) {
        time_ids.splice(time_ids.indexOf(training_time_id), 1);
      }
      else {
        time_ids.push(training_time_id);
      }

      restriction_map[activeService] = {
        enable_all: restriction_map[activeService].enable_all,
        time_ids,
      };
    }
    else {
      restriction_map[activeService] = {
        enable_all: false,
        time_ids: [training_time_id],
      };
    }

    this.setState({
      restriction_map
    });
  }

  render() {
    return (
      <ContentFrame
        location={this.props.location}
        headerHistory={[
          {
            path: routes.DESKTOP_PATH,
            text: "Área de trabalho"
          },
          {
            path: routes.SERVICE_LIST_PATH,
            text: "Listar serviços"
          },
          {
            path: `${routes.SERVICE_EDIT_PATH}${this.props.match.params.serviceId}`,
            text: "Editar serviço"
          },
        ]}
        titleIcon={<i className="fas fa-edit"></i>}
        title="Editar serviço"
        loading={this.state.loading}
      >

        <ServiceData
          warningMessage={this.state.warningMessage}
          showWarningMessage={this.state.showWarningMessage}
          service={this.state.service}
          onSave={() => this.saveData()}
          onCloseWarning={() => {this.setState({highlights: [], showWarningMessage: false})}}
          enableSave={this.inputsAreValid()}
          handleInputChange={(event) => this.handleInputChange(event)}
          highlights={this.state.highlights}
          onCancelPath={routes.SERVICE_LIST_PATH}
          training_times={this.state.training_times}
          restriction_map={this.state.restriction_map}
          service_groups={this.state.service_groups}
          services={this.state.services}
          activeService={this.state.activeService}
          onSelectService={(service) => this.setState({activeService: service})}
          onClickTime={(training_time_id) => this.onClickTime(training_time_id)}
          unit_type_id={this.props.unit_type_id}
        />

      </ContentFrame>
    );
  }
}

export default ServiceEdit;
