import React from 'react';
import { Link } from 'react-router-dom';
import * as routes from '../../constants';
import {INCOME_TYPE_NAME,
        DEFAULT_UNKNOWN_ERROR_MESSAGE} from '../../constants';
import ContentFrame from '../content_frame';
import {VerticalAccordionContainer} from '../../utils/pose_containers';
import {HorizontalRule} from '../../utils/default_section';
import {getModels, postModel, setUrlParameters, getAsLocalDate, getCurrencyText, getLocalDateIsoString} from '../../utils/functions';
import DefaultInput, {HalfWrapper, SelectOption} from '../../utils/default_input';
import ModelTable, {Property} from '../../utils/model_table';
import ConfirmationWindow from '../confirmation_window';
import OverlayWindow from '../../components/overlay_window';
import DefaultMenuButton from '../../components/default_menu_button';
import ContextPopup from '../../components/context_popup';
import './nf_coupon_report.scss';


class NfCouponReport extends React.Component {
  constructor(props) {
    super(props);

    let queryParameters = (new URLSearchParams(props.location.search));

    let initialDueDate = queryParameters.get('initial_due_date');
    let finalDueDate = queryParameters.get('final_due_date');

    let createdAtInitialDate = queryParameters.get('created_at_initial_date');
    let createdAtFinalDate = queryParameters.get('created_at_final_date');

    let nfGenerated = queryParameters.get('nf_generated');

    let searchTagIds = queryParameters.get('search_tag_ids');

    let paymentMethod = queryParameters.get('payment_method_id');

    if(!initialDueDate && !finalDueDate) {
      initialDueDate = new Date();
      initialDueDate.setDate(1);
      initialDueDate.setMonth(initialDueDate.getMonth() - 1);
      initialDueDate = getLocalDateIsoString(initialDueDate);

      finalDueDate = new Date();
      finalDueDate.setDate(0);
      finalDueDate = getLocalDateIsoString(finalDueDate);
    }
    if(!initialDueDate) {
      initialDueDate = ''
    }
    if(!finalDueDate) {
      finalDueDate = '';
    }


    if(!createdAtInitialDate) {
      createdAtInitialDate = '';
    }
    if(!createdAtFinalDate) {
      createdAtFinalDate = '';
    }

    if(!nfGenerated) {
      nfGenerated = 'false';
    }

    if(!paymentMethod) {
      paymentMethod = '';
    }

    if(!searchTagIds) {
      searchTagIds = [];
    }
    else {
      searchTagIds = JSON.parse(searchTagIds);
    }

    this.state = {
      initialDueDateInput: initialDueDate,
      finalDueDateInput: finalDueDate,
      initialDueDate: initialDueDate,
      finalDueDate: finalDueDate,
      createdAtInitialDate,
      createdAtInitialDateInput: createdAtInitialDate,
      createdAtFinalDate,
      createdAtFinalDateInput: createdAtFinalDate,
      nfGenerated: nfGenerated,
      nfGeneratedInput: nfGenerated,
      paymentMethod,
      paymentMethodInput: paymentMethod,
      transactionsLoaded: false,
      financial_transactions: [],
      financial_search_tags: [],
      payment_methods: [],
      failed_coupons: [],
      transactionSelectionSet: new Set(),
      overlayIsLoading: false,
      search_tag_ids_input: searchTagIds,
      active_search_tag_ids: searchTagIds,
      onGenerateCoupons: false,
      searchTagFilter: "",
      searchTagsSectionVisible: false,
      loadingData: true,
      popupContent: null,
      popupTarget: null,
      confirmInProgress: false,
      confirmFailed: false,
      confirmFailDescription: "",
      screenWidth: window.innerWidth,
      clipboardWritePermission: false,
      nfCouponTextCopied: false
    };
  }

  async getFinancialSearchTags() {
    return await getModels(routes.FINANCIAL_SEARCH_TAGS_GET_API);
  }

  async getPaymentMethods() {
    return await getModels(routes.PAYMENT_METHODS_GET_API);
  }

  async getFinancialTransactions() {
    const parameters = {
      initial_date: this.state.initialDueDate,
      final_date: this.state.finalDueDate,
      created_at_initial_date: this.state.createdAtInitialDate,
      created_at_final_date: this.state.createdAtFinalDate,
      pending: false,
      nf_generated: this.state.nfGenerated,
      only_contracts: true,
      requires_nf_coupon: true,
      is_expense: false,
      payment_method_id: this.state.paymentMethod,
      search_tag_ids: JSON.stringify(this.state.active_search_tag_ids),
      load_additional_info: true,
      load_description: true,
      load_search_tags: true,
      load_payment_method: true,
      load_installments_paid_count: true,
      load_nf_coupon: true
    };

    if(parameters.nf_generated === 'all') {
      parameters.nf_generated = null;
    }

    return await getModels(setUrlParameters(routes.FINANCIAL_TRANSACTIONS_GET_API, parameters));
  }

  async refreshData(setLoading=true) {
    this.setState({loadingData: true});

    let financial_transactions = this.getFinancialTransactions();

    const update = {
      transactionsLoaded: true,
      transactionSelectionSet: new Set()
    }

    if(setLoading) {
      update.loadingData = false;
    }

    financial_transactions = await financial_transactions;

    if(financial_transactions) {
      update.financial_transactions = financial_transactions;
    }

    this.setState(update);
  }

  async componentDidMount() {
    let financial_search_tags = this.getFinancialSearchTags();
    let payment_methods = this.getPaymentMethods();

    await this.refreshData(false);

    const update = {
      loadingData: false
    }

    financial_search_tags = await financial_search_tags;

    if(financial_search_tags) {
      update.financial_search_tags = financial_search_tags;
      update.financial_search_tags.sort((a, b) => a.name.localeCompare(b.name));
    }

    payment_methods = await payment_methods;

    if(payment_methods) {
      update.payment_methods = payment_methods;
      update.payment_methods.sort((a, b) => a.name.localeCompare(b.name));
    }

    this.setState(update);

    this.resizeListener = () => this.updateSize();

    window.addEventListener("resize", this.resizeListener);

    const permissionStatus = await navigator.permissions.query({name: 'clipboard-write'});

    if(permissionStatus.state === 'granted') {
      this.setState({
        clipboardWritePermission: true
      })
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resizeListener);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.initialDueDate !== this.state.initialDueDate ||
        prevState.finalDueDate !== this.state.finalDueDate ||
        prevState.createdAtInitialDate !== this.state.createdAtInitialDate ||
        prevState.createdAtFinalDate !== this.state.createdAtFinalDate ||
        prevState.nfGenerated !== this.state.nfGenerated ||
        prevState.paymentMethod !== this.state.paymentMethod ||
        prevState.active_search_tag_ids !== this.state.active_search_tag_ids) {
      this.refreshData();
    }
  }

  updateSize() {
    this.setState({
      screenWidth: window.innerWidth
    });
  }

  handleInputChange(event) {
    const target = event.target;
    let value = target.type === 'checkbox' ? target.checked : target.value;
    let name = target.name;

    const update = {[name]: value};

    this.setState(update);
  }

  searchTagInputHasChanged() {
    let difference = this.state.search_tag_ids_input.filter(x => !this.state.active_search_tag_ids.includes(x))

    difference = difference.concat(this.state.active_search_tag_ids.filter(x => !this.state.search_tag_ids_input.includes(x)));

    return difference.length > 0;
  }

  mayResetFilterInputs() {
    if(this.state.initialDueDateInput.length > 0 ||
       this.state.finalDueDateInput.length > 0 ||
       this.state.createdAtInitialDateInput.length > 0 ||
       this.state.createdAtFinalDateInput.length > 0 ||
       this.state.search_tag_ids_input.length > 0 ||
      this.state.paymentMethodInput.length > 0) {
      return true;
    }

    return false;
  }

  resetFilterInputs() {
    this.setState({
      initialDueDateInput: '',
      finalDueDateInput: '',
      createdAtInitialDateInput: '',
      createdAtFinalDateInput: '',
      search_tag_ids_input: [],
      paymentMethodInput: '',
    });
  }

  mayUpdateDateInputs() {
    if(this.state.initialDueDateInput !== this.state.initialDueDate ||
       this.state.finalDueDateInput !== this.state.finalDueDate ||
       this.state.createdAtInitialDateInput !== this.state.createdAtInitialDate ||
       this.state.createdAtFinalDateInput !== this.state.createdAtFinalDate ||
       this.state.nfGeneratedInput !== this.state.nfGenerated ||
       this.state.paymentMethodInput !== this.state.paymentMethod ||
       this.searchTagInputHasChanged()) {
      return true;
    }

    return false;
  }

  applyDateInputChanges() {
    if(this.mayUpdateDateInputs()) {
      this.props.history.replace(setUrlParameters(routes.NF_COUPON_REPORT_PATH, {
        initial_due_date: this.state.initialDueDateInput,
        final_due_date: this.state.finalDueDateInput,
        created_at_initial_date: this.state.createdAtInitialDateInput,
        created_at_final_date: this.state.createdAtFinalDateInput,
        nf_generated: this.state.nfGeneratedInput,
        payment_method_id: this.state.paymentMethodInput,
        search_tag_ids: JSON.stringify(this.state.search_tag_ids_input)
      }));

      this.setState({
        initialDueDate: this.state.initialDueDateInput,
        finalDueDate: this.state.finalDueDateInput,
        createdAtInitialDate: this.state.createdAtInitialDateInput,
        createdAtFinalDate: this.state.createdAtFinalDateInput,
        nfGenerated: this.state.nfGeneratedInput,
        paymentMethod: this.state.paymentMethodInput,
        active_search_tag_ids: [...this.state.search_tag_ids_input]
      });
    }
  }

  getNfGeneratedOptions() {
    return [
      SelectOption('false', 'Não emitido'),
      SelectOption('true', 'Emitido'),
      SelectOption('all', 'Todos'),
    ];
  }

  getPaymentMethodOptions() {
    return [
      SelectOption('', 'Todos'),
      ...this.state.payment_methods.map((method) => SelectOption(method.id, method.name))
    ];
  }

  handleKeyDown(event) {
    if(event.keyCode === 13) {
      this.applyDateInputChanges();
    }
  }

  onConfirmNfGeneration() {
    this.setState({onGenerateCoupons: true});
  }

  onToggleSearchTag(tag_id) {
    const tag_ids = [...this.state.search_tag_ids_input];

    if(tag_ids.includes(tag_id)) {
      tag_ids.splice(tag_ids.indexOf(tag_id), 1);
    }
    else {
      tag_ids.push(tag_id);
    }

    this.setState({search_tag_ids_input: tag_ids});
  }

  getSelectedSearchTags() {
    const filteredTags = this.state.financial_search_tags.filter((tag) => this.state.search_tag_ids_input.includes(tag.id));

    return filteredTags.map((tag) => (
      <div
        key={`nf_coupon_report:active_search_tag:${tag.id}`}
        className="nf-coupon-report__active-search-tag"
      >
        {tag.name}

        <button
          className="nf-coupon-report__active-search-tag__button"
          onClick={(event) => {
            this.onToggleSearchTag(tag.id);
            event.stopPropagation();
          }}
        >

          <i className="fa-solid fa-xmark"></i>

        </button>
      </div>
    ));
  }

  getSearchTagOptions() {
    if(!this.state.financial_search_tags) {
      return null;
    }

    const filteredTags = this.state.financial_search_tags.filter((tag) => {
      return tag.type === INCOME_TYPE_NAME && tag.name.toLocaleLowerCase().includes(this.state.searchTagFilter.toLocaleLowerCase());
    });

    if(filteredTags.length <= 0) {
      return null;
    }

    return filteredTags.map((tag) => {
      const selected = this.state.search_tag_ids_input.includes(tag.id);

      return (
        <div
          key={`nf_coupon_report:search_tag:${tag.id}`}
          className={`nf-coupon-report__search-tag-option${!selected ? '--disabled': ''}`}
        >

          <button
            className="nf-coupon-report__search-tag-option__check-button"
            onClick={() => this.onToggleSearchTag(tag.id)}
          >

            {selected &&
              <i className="fas fa-check"></i>
            }

          </button>

          <p className="nf-coupon-report__search-tag-option__text">

            {tag.name}

          </p>

        </div>
      );
    });
  }

  getEffectiveDateFilter(entry) {
    const today = getAsLocalDate((new Date()).toISOString().slice(0, 10));

    const date = getAsLocalDate(entry.effective_date);
    const timeDiff = Math.abs(today.getTime() - date.getTime());
    let daysPassed = (today > date ? -1 : 1)*Math.ceil(timeDiff / (1000 * 3600 * 24));

    let daysPassedText;

    if (!entry.is_canceled) {
      if (daysPassed !== 0) {
        daysPassedText = `${daysPassed} ${Math.abs(daysPassed) > 1 ? 'dias' : 'dia'}`;
      }
      else {
        daysPassedText = 'Hoje';
      }
    }
    else {
      daysPassedText = 'Cancelado';
    }

    return `${date.toLocaleDateString()} (${daysPassedText})`;
  }

  getEffectiveDateText(entry) {
    const today = getAsLocalDate((new Date()).toISOString().slice(0, 10));

    const date = getAsLocalDate(entry.effective_date);
    const timeDiff = Math.abs(today.getTime() - date.getTime());
    let daysPassed = (today > date ? -1 : 1)*Math.ceil(timeDiff / (1000 * 3600 * 24));

    let cellStyle ='';

    let additionalInfo;

    if (entry.nf_coupon_id !== null) {
      cellStyle = '--completed';
    }
    else if (daysPassed < 0) {
      cellStyle = '--past';
    }
    else if (daysPassed >= 0 && daysPassed < 5) {
      cellStyle = '--alert';
    }

    if (!entry.is_canceled) {
      if (daysPassed !== 0) {
        additionalInfo = `${daysPassed} ${Math.abs(daysPassed) > 1 ? 'dias' : 'dia'}`;
      }
      else {
        additionalInfo = 'Hoje';
      }
    }
    else {
      additionalInfo = 'Cancelado';
    }

    return (
      <div className="nf-coupon-report__cell-wrapper">

        <p className={`nf-coupon-report__date-text${cellStyle}`}>

          {`${date.toLocaleDateString()}`}
          <br/>
          {`(${additionalInfo})`}

        </p>

      </div>
    );
  }

  getValueText(entry) {
    return (
      <p className="nf-coupon-report__property-content">

        {getCurrencyText(entry.value)}

        {(entry.entry_description || entry.search_tags) && (
          <i
            className="fa-solid fa-circle-info nf-coupon-report__property-content__info-icon"
            onMouseEnter={(event) => this.onShowHoverdata(event.target, this.getDescriptoinText(entry))}
            onMouseLeave={(event) => this.onHideHoverdata()}
          >
          </i>
        )}

      </p>
    );
  }

  getValueFilter(entry) {
    return `${getCurrencyText(entry.value)} ${this.getDescriptoinFilter(entry)}`;
  }

  getInstallmentsPaidText(entry) {
    return `${entry.installments_paid} / ${entry.total_installments}`;
  }

  getDescriptoinText(entry) {
    return (
      <div className="nf-coupon-report__cell-container">

        <p className="nf-coupon-report__description">

          {entry.entry_description}

        </p>

        <p className="nf-coupon-report__search-tags">
          {entry.search_tags.map((tag) => (
            <span
              className="nf-coupon-report__search-tag"
              key={`financial_transaction:search_tag:${tag.id}`}
            >
              {tag.name}
            </span>
          ))}
        </p>

      </div>
    );
  }

  getDescriptoinFilter(entry) {
    return entry.entry_description + ` ${entry.search_tags.map((tag) => tag.name).join(' ')}`;
  }

  getClassificationText(entry) {
    return (
      <div className="nf-coupon-report__cell-container">

        <p><i className="fas fa-box"></i>{' '}{entry.cost_center_name}</p>
        <p><i className="fas fa-tags"></i>{' '}{entry.financial_category_name}</p>

      </div>
    );
  }

  getClassificationFilter(entry) {
    return `${entry.cost_center_name} ${entry.financial_category_name}`;
  }

  onSelectTransaction(entry) {
    const transactionSelectionSet = new Set(this.state.transactionSelectionSet);

    if (this.state.transactionSelectionSet.has(entry.id)) {
      transactionSelectionSet.delete(entry.id);
    }
    else {
      transactionSelectionSet.add(entry.id);
    }

    this.setState({transactionSelectionSet});
  }

  onToggleAllTransactionSelection() {
    let transactionSelectionSet;

    if (this.state.transactionSelectionSet.size > 0) {
      transactionSelectionSet = new Set();
    }
    else {
      transactionSelectionSet = new Set([...this.state.financial_transactions.filter((entry) => entry.nf_coupon_id === null).map((entry) => entry.id)]);
    }

    this.setState({transactionSelectionSet});
  }

  getSelectedInput(entry) {
    if (entry.nf_coupon_id !== null) {
      return null;
    }

    const isSelected = this.state.transactionSelectionSet.has(entry.id);

    return (
      <div className='nf-coupon-report__selected-input-container'>

        <button
          className="nf-coupon-report__select-checkbox"
          onClick={() => this.onSelectTransaction(entry)}
        >

          {isSelected &&
            <i className="fas fa-check"></i>
          }

        </button>

      </div>
    );
  }

  getSelectedFilter(entry) {
    return this.state.transactionSelectionSet.has(entry.id) ? 'Selecionado' : 'Não selecionado';
  }

  getTransactionProperties() {
    let properties = [
      Property('id', 'ID', <i className="fa-solid fa-tag"></i>),
      Property('selected',
      (
        <div className='nf-coupon-report__selected-input-container'>

          <button
            className="nf-coupon-report__select-checkbox"
            onClick={() => this.onToggleAllTransactionSelection()}
          >

            {this.state.transactionSelectionSet.size > 0 &&
              <i className="fas fa-check"></i>
            }

          </button>

        </div>
      ),
      null,
      {
        getDataText: (entry) => this.getSelectedInput(entry),
        getFilterText: (entry) => this.getSelectedFilter(entry),
        sortable: false,
        headerClassName: 'nf-coupon-report__selected-input-header'
      }),
      Property('effective_date', 'Data do pagamento', <i className="fas fa-calendar-day"></i>, {
        getDataText: (entry) => this.getEffectiveDateText(entry),
        getFilterText: (entry) => this.getEffectiveDateFilter(entry),
      }),
      Property('value', 'Valor', <i className="fa-solid fa-dollar-sign"></i>, {
        getDataText: (entry) => this.getValueText(entry),
        getFilterText: (entry) => this.getValueFilter(entry),
        getSortCallback: (a, b) => {
          if (a.value === b.value) {
            return a.id - b.id;
          }

          return a.value - b.value;
        }
      }),
      Property('installments_paid', 'Parcelas pagas', <i className="fa-solid fa-dollar-sign"></i>, {
        getDataText: (entry) => this.getInstallmentsPaidText(entry),
        getFilterText: (entry) => this.getInstallmentsPaidText(entry),
        getSortCallback: (a, b) => {
          const aInstallmentsRemaining = a.total_installments - a.installments_paid;
          const bInstallmentsRemaining = b.total_installments - b.installments_paid;

          if (aInstallmentsRemaining === bInstallmentsRemaining) {
            if (a.effective_date === b.effective_date) {
              return a.id - b.id;
            }

            return a.effective_date.localeCompare(b.effective_date);
          }

          return aInstallmentsRemaining - bInstallmentsRemaining;
        }
      }),
      // Property('entry_description', 'Descrição / Tags', <i className="fas fa-info-circle"></i>, {
      //   getDataText: (entry) => this.getDescriptoinText(entry),
      //   getFilterText: (entry) => this.getDescriptoinFilter(entry),
      // }),
      Property('payment_method_name', 'Método de pagamento', <i className="fa-regular fa-credit-card"></i>),
      Property('tags', 'Centro / Categoria', <i className="fas fa-tag"></i>, {
        getDataText: (entry) => this.getClassificationText(entry),
        getFilterText: (entry) => this.getClassificationFilter(entry),
        sortable: false
      })
    ];

    return properties;
  }

  async copyNfCouponLink(entry) {
    const message = `Olá, ${entry.user_name}\n\n` +
                    `Segue o link para baixar a nota fiscal referente ao pagamento da parcela do plano "${entry.service_plan_name}":\n\n` +
                    `${entry.nf_coupon.link}\n\n` +
                    'Um grande abraço e para qualquer dúvida estamos à disposição!';

    try {
      await navigator.clipboard.writeText(message);

      this.setState({nfCouponTextCopied: true});
    }
    catch(error) {
      window.alert(error);
    }
  }

  getNfCouponLinkButton(entry) {
    if (entry.nf_coupon === null || entry.nf_coupon.link === null) {
      return null;
    }

    if(this.state.clipboardWritePermission && entry.service_plan_name !== null && entry.user_name !== null) {
      return (
        <button
          className="model-table__default-link-button"
          onClick={(event) => this.copyNfCouponLink(entry)}
        >

          <i className="fas fa-link"></i>

        </button>
      );
    }

    return (
      <a
        className="model-table__default-link-button"
        href={entry.nf_coupon.link}
        target="_blank"
        rel="noopener noreferrer"
      >

        <i className="fas fa-link"></i>

      </a>
    );
  }

  getTransactionActions(entry) {
    return (
      <div className="model-table__model-actions-container">

        {this.getNfCouponLinkButton(entry)}

        <Link
          className="model-table__default-edit-button"
          to={`${routes.INCOME_EDIT_PATH}${entry.financial_entry_id}`}
        >

            <i className="fas fa-edit"></i>

        </Link>

      </div>
    );
  }

  getTransactionsContent() {
    if (!this.state.transactionsLoaded) {
      return null;
    }

    const totalValue = this.state.financial_transactions.reduce((sum, transaction) => sum + transaction.value, 0);
    const totalSelectedValue = this.state.financial_transactions.filter((entry) => this.state.transactionSelectionSet.has(entry.id)).reduce((sum, transaction) => sum + transaction.value, 0);
    const totalNfValueGenerated = this.state.financial_transactions.filter((entry) => entry.nf_coupon_id !== null).reduce((sum, transaction) => sum + transaction.value, 0);

    return (
      <React.Fragment>

      {this.state.financial_transactions.length > 0 &&
        <div className="nf-coupon-report__indicator-container--spaced">

          <div className="nf-coupon-report__indicator">

            <h2 className="nf-coupon-report__indicator__label">Valor total:</h2>
            <p className="nf-coupon-report__indicator__value">{getCurrencyText(totalValue)}</p>

          </div>

          <div className="nf-coupon-report__indicator">

            <h2 className="nf-coupon-report__indicator__label">Valor total selecionado:</h2>
            <p className="nf-coupon-report__indicator__value">{getCurrencyText(totalSelectedValue)} ({Math.round(10000*totalSelectedValue/totalValue)/100}%)</p>

          </div>

          <div className="nf-coupon-report__indicator">

            <h2 className="nf-coupon-report__indicator__label">Valor total emitido:</h2>
            <p className="nf-coupon-report__indicator__value">{getCurrencyText(totalNfValueGenerated)} ({Math.round(10000*totalNfValueGenerated/totalValue)/100}%)</p>

          </div>

        </div>
      }

        <ModelTable
          storageKey="manage_financial_transactions"
          properties={this.getTransactionProperties()}
          getActions={(entry) => this.getTransactionActions(entry)}
          data={this.state.financial_transactions}
          initialOrderBy="effective_date"
        >

          <button
            className="nf-coupon-report__action-button"
            disabled={this.state.transactionSelectionSet.size <= 0}
            onClick={(event) => this.onConfirmNfGeneration()}
          >

            <i className="fa-solid fa-check"></i> Emitir nota fiscal

          </button>
        </ModelTable>

      </React.Fragment>
    );
  }

  getConfirmationWindowTitle() {
    if(this.state.confirmInProgress) {
      return 'Emitindo nota fiscal';
    }
    else if(this.state.confirmFailed) {
      return 'Falha ao emitir nota fiscal';
    }
    else if(this.state.nfCouponTextCopied) {
      return 'Texto com link copiado';
    }

    return 'Emitir nota fiscal';
  }

  getConfirmationWindowDescription() {
    if(this.state.confirmFailed) {
      return this.state.confirmFailDescription;
    }
    else if(this.state.nfCouponTextCopied) {
      return "Um texto contendo o link para baixar o PDF da nota fiscal foi copiado.";
    }

    return 'Uma nota fiscal será gerada para cada receita selecionada.';
  }

  getConfirmartionWindowConfirmText() {
    return 'Confirmar';
  }

  onCancelConfirmation() {
    this.setState({
      onGenerateCoupons: false,
      confirmFailed: false,
      nfCouponTextCopied: false
    });
  }

  async onAcceptConfirmation() {
    this.setState({
      confirmInProgress: true,
      overlayIsLoading: true
    });

    if(this.state.onGenerateCoupons) {
      const data = {
        financial_transaction_ids: [...this.state.transactionSelectionSet]
      };

      let response;

      try{
        response = await postModel(routes.FINANCIAL_TRANSACTIONS_GENERATE_NF_COUPONS_POST_API, data, true);

        if (response) {
          this.refreshData();
          this.setState({failed_coupons: response.failed_entries});
        }
      }
      catch(errors) {
        let errorDescription = DEFAULT_UNKNOWN_ERROR_MESSAGE;

        if(errors instanceof Array) {
          for(let error of errors) {
            switch (error.code) {
              case 209:
                errorDescription = 'Sessão do usuário expirada.';

                break;
              default:
            }
          }
        }

        this.setState({
          confirmFailDescription: errorDescription,
          confirmFailed: true,
          confirmInProgress: false,
          overlayIsLoading: false
        });

        return;
      }
    }

    this.setState({
      onGenerateCoupons: false,
      confirmFailed: false,
      confirmInProgress: false,
      overlayIsLoading: false
    });
  }

  getOverlayActions() {
    return (
      <React.Fragment>

        <DefaultMenuButton
          className="nf-coupon-report__overlay__action-button"
          onClick={() => this.setState({failed_coupons: []})}
          text="Fechar"
        />

        {/* <DefaultMenuButton
          className="nf-coupon-report__overlay__action-button"
          onClick={() => this.onAcceptConfirmation()}
          color="purple"
          text={this.state.overlayIsLoading ? 'Emitindo nota fiscal...' : 'Confirmar'}
          disabled={this.state.overlayIsLoading}
        /> */}

      </React.Fragment>
    );
  }

  getOverlayHeader() {
    return 'Falha ao emitir um ou mais cupons';
  }

  getFailedCouponProperties() {
    let properties = [
      Property('financial_transaction_id', 'ID', <i className="fa-solid fa-tag"></i>),
      Property('message', 'Valor', <i className="fa-solid fa-triangle-exclamation"></i>),
    ];

    return properties;
  }

  getFailedCouponActions(entry) {
    return (
      <div className="model-table__model-actions-container">

        <Link
          className="model-table__default-edit-button"
          to={`${routes.INCOME_EDIT_PATH}${entry.financial_entry_id}`}
          target="_blank"
          rel="noopener noreferrer"
        >

            <i className="fas fa-edit"></i>

        </Link>

      </div>
    );
  }

  getOverlayContent() {
    return (
      <React.Fragment>

        <ModelTable
          storageKey="failed_coupons"
          properties={this.getFailedCouponProperties()}
          getActions={(entry) => this.getFailedCouponActions(entry)}
          data={this.state.failed_coupons}
          initialOrderBy="financial_transaction_id"
        >
        </ModelTable>

      </React.Fragment>
    );
  }

  onShowHoverdata(target, text) {
    this.setState({
      popupContent: text,
      popupTarget: target,
    });
  }

  onHideHoverdata() {
    this.setState({
      popupContent: null,
      popupTarget: null,
    });
  }

  render() {
    return (
      <React.Fragment>

        <ContextPopup
          targetElement={this.state.popupTarget}
          content={this.state.popupContent}
        />

        <OverlayWindow
          className="nf-coupon-report__overlay"
          visible={this.state.failed_coupons.length > 0}
          // loading={this.state.overlayIsLoading}
          actions={(
            <div className="nf-coupon-report__overlay__action-container">

              {this.getOverlayActions()}

            </div>
          )}
        >

          <header className="nf-coupon-report__overlay__header">

            <h3 className="nf-coupon-report__overlay__header__title">
              {this.getOverlayHeader()}
            </h3>

          </header>

          <hr className="nf-coupon-report__horizontal-rule" />

          <div className="nf-coupon-report__overlay__content">

            {this.getOverlayContent()}

          </div>

        </OverlayWindow>

        <ConfirmationWindow
          title={this.getConfirmationWindowTitle()}
          description={this.getConfirmationWindowDescription()}
          confirmText={this.getConfirmartionWindowConfirmText()}
          cancelText={(this.state.confirmFailed || this.state.nfCouponTextCopied) ? 'Ok' : 'Cancelar'}
          visible={this.state.onGenerateCoupons || this.state.confirmFailed || this.state.nfCouponTextCopied}
          onCancel={() => this.onCancelConfirmation()}
          onConfirm={() => this.onAcceptConfirmation()}
          loading={this.state.confirmInProgress}
          useErrorIcon={this.state.confirmFailed}
          hideConfirmButton={this.state.confirmFailed || this.state.nfCouponTextCopied}
          useSuccessIcon={this.state.nfCouponTextCopied}
        />

        <ContentFrame
          location={this.props.location}
          headerHistory={[
            {
              path: routes.DESKTOP_PATH,
              text: "Área de trabalho"
            },
            {
              path: routes.NF_COUPON_REPORT_PATH,
              text: "Emissão de nota fiscal"
            },
          ]}
          titleIcon={<i className="fa-solid fa-receipt"></i>}
          title="Emissão de nota fiscal"
          loading={this.state.loadingData}
        >

          <div className="nf-coupon-report__wrapper">

            <div className="nf-coupon-report__filters">

              <h3 className="nf-coupon-report__filters__title">Filtros</h3>

              <div className="nf-coupon-report__filters__inputs-container">

                <div className="nf-coupon-report__filters__inputs-wrapper">

                  <HalfWrapper>

                    <DefaultInput
                      name="nfGeneratedInput"
                      label="Nota fiscal"
                      type="select"
                      handleInputChange={(event) => this.handleInputChange(event)}
                      value={this.state.nfGeneratedInput || ''}
                      options={this.getNfGeneratedOptions()}
                    />

                    <DefaultInput
                      name="paymentMethodInput"
                      label="Método de pagamento"
                      type="select"
                      handleInputChange={(event) => this.handleInputChange(event)}
                      value={this.state.paymentMethodInput || ''}
                      options={this.getPaymentMethodOptions()}
                    />

                  </HalfWrapper>

                  <HalfWrapper>

                    <DefaultInput
                      name="initialDueDateInput"
                      // isHighlighted={this.state.initialDueDateInput > this.state.finalDueDateInput}
                      label="Data inicial do pagamento"
                      type="date"
                      placeholder="Data inicial"
                      max={this.state.finalDueDateInput}
                      handleInputChange={(event) => this.handleInputChange(event)}
                      value={this.state.initialDueDateInput}
                      onKeyDown={(event) => this.handleKeyDown(event)}
                    />

                    <DefaultInput
                      name="finalDueDateInput"
                      // isHighlighted={this.state.initialDueDateInput > this.state.finalDueDateInput}
                      label="Data final do pagamento"
                      type="date"
                      placeholder="Data final"
                      min={this.state.initialDueDateInput}
                      handleInputChange={(event) => this.handleInputChange(event)}
                      value={this.state.finalDueDateInput}
                      onKeyDown={(event) => this.handleKeyDown(event)}
                    />

                  </HalfWrapper>

                  <HalfWrapper>

                    <DefaultInput
                      name="createdAtInitialDateInput"
                      // isHighlighted={this.state.createdAtInitialDateInput > this.state.createdAtFinalDateInput}
                      label="Data inicial do cadastro"
                      type="date"
                      placeholder="Data inicial"
                      max={this.state.createdAtFinalDateInput}
                      handleInputChange={(event) => this.handleInputChange(event)}
                      value={this.state.createdAtInitialDateInput}
                    />

                    <DefaultInput
                      name="createdAtFinalDateInput"
                      // isHighlighted={this.state.createdAtInitialDateInput > this.state.createdAtFinalDateInput}
                      label="Data final do cadastro"
                      type="date"
                      placeholder="Data final"
                      min={this.state.createdAtInitialDateInput}
                      handleInputChange={(event) => this.handleInputChange(event)}
                      value={this.state.createdAtFinalDateInput}
                    />

                  </HalfWrapper>

                  <section className="nf-coupon-report__default-section">

                    <header
                      className="nf-coupon-report__default-section__header"
                      onClick={() => this.setState({searchTagsSectionVisible: !this.state.searchTagsSectionVisible})}
                    >

                      <h3 className="nf-coupon-report__default-section__header__text">

                        <div className="nf-coupon-report__default-section__header__text-wrapper">
                          <i className="fa-solid fa-tags nf-coupon-report__default-section__header__text-icon"></i>
                          Palavras-chave:
                        </div>
                        <div className="nf-coupon-report__active-search-tag__wrapper">
                          {this.getSelectedSearchTags()}
                        </div>

                      </h3>

                      {this.state.searchTagsSectionVisible ?
                        <i className="fas fa-chevron-down nf-coupon-report__default-section__header__visible-icon"></i>:
                        <i className="fas fa-chevron-up nf-coupon-report__default-section__header__visible-icon"></i>
                      }

                    </header>

                    <VerticalAccordionContainer
                      className="vertical-accordion-container nf-coupon-report__default-section__content"
                      pose={this.state.searchTagsSectionVisible ? 'verticalOpen' : 'verticalClosed'}
                    >

                      <div className="vertical-accordion-container nf-coupon-report__default-section__content-wrapper">

                        <div className="nf-coupon-report__default-section-container">

                          <DefaultInput
                            name="searchTagFilter"
                            label="Busca rápida:"
                            type="text"
                            handleInputChange={(event) => this.handleInputChange(event)}
                            value={this.state.searchTagFilter}
                            autoComplete="off"
                            disabled={this.state.confirmInProgress}
                          />

                          <div className="nf-coupon-report__search-tag-options-container">

                            {this.getSearchTagOptions()}

                          </div>

                        </div>

                      </div>

                    </VerticalAccordionContainer>

                  </section>

                </div>

                {this.mayResetFilterInputs() &&
                  <button
                    className="nf-coupon-report__filters__reset-button"
                    onClick={() => this.resetFilterInputs()}
                  >

                    <i className="fas fa-times"></i>

                  </button>
                }

                <button
                  className="nf-coupon-report__filters__refresh-button"
                  onClick={() => this.applyDateInputChanges()}
                  disabled={!this.mayUpdateDateInputs()}
                >

                  <i className="fas fa-sync"></i>

                </button>
              </div>

            </div>

            <HorizontalRule />

            {this.getTransactionsContent()}

          </div>

        </ContentFrame>

      </React.Fragment>
    );
  }
}

export default NfCouponReport;
