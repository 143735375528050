import React from 'react';
import { Link } from 'react-router-dom';
import './service_list.scss';
import ContentFrame from '../content_frame';
import DefaultInput, {HalfWrapper, SelectOption} from '../../utils/default_input';
import * as routes from '../../constants';
import ModelTable, {Property} from '../../utils/model_table';
import ConfirmationWindow from '../confirmation_window';
import DefaultSection from '../../utils/default_section';
import {getModels, deleteModel, getCurrencyText, setUrlParameters} from '../../utils/functions';
import {DEFAULT_UNKNOWN_ERROR_MESSAGE} from '../../constants';
import * as permissions from '../../permissions';

class ServiceList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingData: true,
      isActiveInput: "true",
      isActiveFilter: "true",
      codeInput: "",
      codeFilter: "",
      services: [],
      deleteId: null,
      deleteInProgress: false,
      deleteFailed: false,
      deleteFailDescription: "",
      screenWidth: window.innerWidth
    };
  }

  async getServices() {
    const parameters = {load_service_group: true};

    if(this.state.isActiveFilter.length > 0) {
      parameters.is_active = this.state.isActiveFilter;
    }

    if(this.state.codeFilter.length > 0) {
      parameters.code_filter = this.state.codeFilter;
    }

    return await getModels(setUrlParameters(routes.SERVICES_GET_API, parameters));
  }

  async componentDidMount() {
    this.reloadList();

    this.resizeListener = () => this.updateSize();

    window.addEventListener("resize", this.resizeListener);
  }

  async componentDidUpdate(prevProps, prevState) {
    if (prevState.codeFilter !== this.state.codeFilter || prevState.isActiveFilter !== this.state.isActiveFilter) {
      this.setState({
        loadingData: true
      });

      this.setState({
        services: await this.getServices(),
        loadingData: false
      });
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resizeListener);
  }

  updateSize() {
    this.setState({
      screenWidth: window.innerWidth
    });
  }

  async reloadList() {
    this.setState({
      loadingData: true
    });

    const services = await this.getServices();

    this.setState({
      loadingData: false,
      services: services
    });
  }

  onDeleteEntry(entryId) {
    this.setState({
      deleteId: entryId,
      deleteInProgress: false,
      deleteFailed: false
    });
  }

  onCancelDelete() {
    this.setState({
      deleteId: null
    });
  }

  async onConfirmDelete() {
    this.setState({
      deleteInProgress: true
    });

    try{
      if(await deleteModel(`${routes.SERVICE_DELETE_API}${this.state.deleteId}`)) {
        this.reloadList();
      }
    }
    catch(errors) {
      let errorDescription = DEFAULT_UNKNOWN_ERROR_MESSAGE;

      if(errors instanceof Array) {
        for(let error of errors) {
          switch (error.code) {
            case 104:
              for(let parameter of error.parameters) {
                switch (parameter.name) {
                  case 'contracts':
                    errorDescription = 'Serviço vinculado à um contrato de aluno. Estes contratos devem ser excluídos antes de excluir este serviço.';

                    break;
                  default:
                }
              }

              break;
            case 209:
              errorDescription = 'Sessão do usuário expirada.';

              break;
            default:
          }
        }
      }

      this.setState({
        deleteFailDescription: errorDescription,
        deleteFailed: true,
        deleteInProgress: false
      });

      return;
    }

    this.setState({
      deleteId: null,
    });
  }

  listHasActions() {
    return this.props.userPermissionIds.includes(permissions.EDIT_SERVICE_PLAN_PERMISSION_ID) || this.props.userPermissionIds.includes(permissions.DELETE_SERVICE_PLAN_PERMISSION_ID);
  }

  getActions(entry) {
    return (
      <div className="model-table__model-actions-container">

        {this.props.userPermissionIds.includes(permissions.EDIT_SERVICE_PLAN_PERMISSION_ID) &&
          <Link
            className="model-table__default-edit-button"
            to={`${routes.SERVICE_EDIT_PATH}${entry.id}`}
          >

              <i className="fas fa-edit"></i>

          </Link>
        }

        {this.props.userPermissionIds.includes(permissions.DELETE_SERVICE_PLAN_PERMISSION_ID) &&
          <button
            className="model-table__default-delete-button"
            onClick={() => this.onDeleteEntry(entry.id)}
          >

            <i className="far fa-trash-alt"></i>

          </button>
        }

      </div>
    );
  }

  getPeriodText(entry) {
    return (
      <div className="service-list__cell-wrapper">

        <p className="service-list__period-text">{`${entry.period} dia${entry.period > 1 ? 's' : ''}`}</p>

      </div>
    );
  }

  getIsActiveFilterText(entry) {
    if (entry.is_active) {
      if (entry.service_group !== null && !entry.service_group.is_active) {
        return 'Agrupamento\nInativo';
      }

      return 'Ativo';
    }

    return 'Inativo';
  }

  getIsActiveText(entry) {
    let activeText = 'Inativo';
    let style = 'inativo';

    if (entry.is_active) {
      if (entry.service_group !== null && !entry.service_group.is_active) {
        activeText = (<span>Agrupamento<br />inativo</span>);
      }
      else {
        activeText = 'Ativo';
        style = 'ativo';
      }
    }

    return (
      <p className={`service-list__is-active-text__${style.toLowerCase()}`}>{activeText}</p>
    );
  }

  getProperties() {
    let properties = [];

    if(this.state.screenWidth > 430) {
      properties.push(
        Property('code', 'Cód.', <i className="fas fa-tag"></i>),
      );
    }

    properties.push(
      Property('name', 'Serviço', <i className="fas fa-tag"></i>)
    );

    if(this.state.screenWidth > 700) {
      properties.push(
        Property('description', 'Descrição', <i className="fas fa-info-circle"></i>, {cellClassName: "service-list__description-cell"}),
      );
    }

    // if(this.state.screenWidth > 430) {
    //   properties.push(
    //     Property('period', 'Período', <i className="fas fa-calendar-week"></i>, {getDataText: this.getPeriodText}),
    //   );
    // }

    properties = [
      ...properties,
      Property('value', 'Valor', <i className="fas fa-dollar-sign"></i>, {
        getDataText: (entry) => getCurrencyText(entry.value),
        getSortCallback: (a, b) => {
          return a.value - b.value;
        }
      }),
      Property('is_active', 'Situação', <i className="fas fa-thermometer-half"></i>, {getDataText: this.getIsActiveText, getFilterText: this.getIsActiveFilterText})
    ];

    return properties;
  }

  getConfirmationWindowTitle() {
    if(this.state.deleteInProgress) {
      return 'Deletando serviço';
    }
    else if(this.state.deleteFailed) {
      return 'Falha ao deletar';
    }

    return 'Deletar serviço';
  }

  getActiveOptions() {
    return [
      SelectOption('true', 'Ativo'),
      SelectOption('false', 'Inativo'),
      SelectOption('', 'Todos'),
    ];
  }

  mayRefreshList() {
    if(this.state.codeInput !== this.state.codeFilter || this.state.isActiveInput !== this.state.isActiveFilter) {
      return true;
    }

    return false;
  }

  async refreshList() {
    if(this.mayRefreshList()) {
      this.setState({
        codeFilter: this.state.codeInput,
        isActiveFilter: this.state.isActiveInput,
      });
    }
  }

  handleInputChange(event) {
    const target = event.target;
    let value = target.value;
    let name = target.name;

    const update = {[name]: value};

    this.setState(update);
  }

  render() {
    return (
      <React.Fragment>

        <ConfirmationWindow
          title={this.getConfirmationWindowTitle()}
          description={this.state.deleteFailed ? this.state.deleteFailDescription : 'Todos os dados relacionados ao serviço serão removidos'}
          confirmText="Deletar serviço"
          cancelText={this.state.deleteFailed ? 'Ok' : 'Cancelar'}
          visible={this.state.deleteId !== null}
          onCancel={() => this.onCancelDelete()}
          onConfirm={() => this.onConfirmDelete()}
          loading={this.state.deleteInProgress}
          useErrorIcon={this.state.deleteFailed}
          hideConfirmButton={this.state.deleteFailed}
        />

        <ContentFrame
          location={this.props.location}
          headerHistory={[
            {
              path: routes.DESKTOP_PATH,
              text: "Área de trabalho"
            },
            {
              path: routes.SERVICE_LIST_PATH,
              text: "Listar serviços"
            },
          ]}
          titleIcon={<i className="fas fa-clipboard-list"></i>}
          title="Listar serviços"
          loading={this.state.loadingData}
        >

          <DefaultSection
            className="service-list"
            title="Lista de serviços"
          >

            <div className="service-list__list-actions">

              {this.props.userPermissionIds.includes(permissions.ADD_SERVICE_PLAN_PERMISSION_ID) &&
                <Link
                  className="model-table__default-button"
                  to={routes.SERVICE_ADD_PATH}
                >

                  <i className="fas fa-plus"></i> Adicionar novo serviço

                </Link>
              }

            </div>

            <div className="service-list__filters">

              <header className="service-list__filters__header">

                <h4 className="service-list__filters__header__text">Filtros</h4>

              </header>

              <div className="service-list__filters__inputs">

                <div className="service-list__filters__inputs-wrapper">

                  <HalfWrapper>

                    <DefaultInput
                      name="codeInput"
                      label="Código do serviço"
                      type="text"
                      placeholder="Código do serviço"
                      maxLength="32"
                      handleInputChange={(event) => this.handleInputChange(event)}
                      value={this.state.codeInput || ''}
                      autoComplete="off"
                    />

                    <DefaultInput
                      name="isActiveInput"
                      label="Situação do cadastro"
                      type="select"
                      handleInputChange={(event) => this.handleInputChange(event)}
                      value={this.state.isActiveInput || ''}
                      options={this.getActiveOptions()}
                    />

                  </HalfWrapper>

                </div>

                <button
                  className="service-list__filters__refresh-button"
                  onClick={() => this.refreshList()}
                  disabled={!this.mayRefreshList()}
                >

                  <i className="fas fa-sync"></i>

                </button>

              </div>

            </div>

            <ModelTable
              properties={this.getProperties()}
              getActions={this.listHasActions() ? (entry) => this.getActions(entry) : null}
              data={this.state.services}
              initialOrderBy="name"
            >

            </ModelTable>

          </DefaultSection>

        </ContentFrame>

      </React.Fragment>
    );
  }
}

export default ServiceList;
